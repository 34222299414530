<template>
  <div class="pixi-stage__wrapper">
    <div ref="pixiStage" class="pixi-stage"></div>
    <!--<div class="pixi-stage__debug">
      <button @click="toggleTransition">toggle transition</button>
      <button @click="changeChapter()">show chapters</button>
      <button @click="changeChapter(0)">chapter 0</button>
      <button @click="changeChapter(1)">chapter 1</button>
    </div>-->

    <div class="pixi-stage__interaction" v-if="activeInteraction">
      <PixiInteraction v-if="activeInteraction == 'kaleidoscope' && introHidden" />

      <DataInteraction v-if="activeInteraction == 'lifetime-calculator' && introHidden" />

      <DecorInteraction v-if="activeInteraction == 'mood-mesher' && introHidden" />

      <LightsInteraction v-if="activeInteraction == 'impressions-of-a-moment' && introHidden" @exit="hideInteraction" />

      <BucketlistInteraction v-if="activeInteraction == 'bucketlist' && introHidden" @exit="hideInteraction" />

      <MindgameInteraction @exit="hideInteraction" v-if="activeInteraction == 'mindgame' && introHidden" />

      <MemoInteraction @exit="hideInteraction" v-else-if="activeInteraction == 'memo' && introHidden" />

      <FashionInteraction @exit="hideInteraction" v-else-if="activeInteraction == 'fashion' && introHidden" :interactionData="interactionData"/>

      <InventionsInteraction @exit="hideInteraction" v-else-if="activeInteraction == 'magazine' && introHidden" :interactionData="interactionData" />

      <MeditationInteraction @exit="hideInteraction" v-else-if="activeInteraction == 'relax' && introHidden" />

      <InteractionIntro :content="introContent" @hiding="onIntroHiding" @skipping="hideInteraction" />

      <div class="pixi-stage__interaction-back" @click="hideInteraction">
        BACK TO JOURNEY
      </div>
    </div>

    <ScrollHint :visible="isActive && loaded" :hidden="isScrolled" />
    <PixiLoader v-if="!loaded" :loading="loadingStarted" />
  </div>
</template>

<script>
const debounce = require("debounce");
import * as PIXI from "pixi.js";
//import { TiltShiftFilter } from "@pixi/filter-tilt-shift";
import { RadialBlurFilter } from "@pixi/filter-radial-blur";
import { BulgePinchFilter } from "@pixi/filter-bulge-pinch";
import normalizeWheel from "normalize-wheel";
import axios from "axios";
import gsap from "gsap";

import GradientBackground from "./background/GradientBackground.js";
import ZoomStage from "./zoom/ZoomStage.js";
import ScrollHint from "./ScrollHint";

import AnimalInteraction from "./interactions/AnimalInteraction.js";
import PixiLoader from "./loader/PixiLoader";

//start interaction import

const InteractionIntro = () =>
  import(
    /* webpackChunkName: "interaction-intro" */ "@/components/pixi/interactions/InteractionIntro.vue"
  );

const DataInteraction = () =>
  import(
    /* webpackChunkName: "data-interaction" */ "@/components/pixi/interactions/DataInteraction.vue"
  );
const PixiInteraction = () =>
  import(
    /* webpackChunkName: "pixi-interaction" */ "@/components/pixi/interactions/PixiInteraction.vue"
  );
const DecorInteraction = () =>
  import(
    /* webpackChunkName: "decor-interaction" */ "@/components/pixi/interactions/DecorInteraction.vue"
  );

const BucketlistInteraction = () =>
  import(
    /* webpackChunkName: "buckletlist-interaction" */ "@/components/pixi/interactions/BucketlistInteraction.vue"
  );

const LightsInteraction = () =>
  import(
    /* webpackChunkName: "lights-interaction" */ "@/components/pixi/interactions/LightsInteraction.vue"
  );

const MindgameInteraction = () =>
  import(
    /* webpackChunkName: "mindgame-interaction" */ "@/components/pixi/interactions/MindgameInteraction.vue"
  );

const MemoInteraction = () =>
  import(
    /* webpackChunkName: "memo-interaction" */ "@/components/pixi/interactions/MemoInteraction.vue"
  );

const FashionInteraction = () =>
  import(
    /* webpackChunkName: "fashion-interaction" */ "@/components/pixi/interactions/FashionInteraction.vue"
  );

const InventionsInteraction = () =>
  import(
    /* webpackChunkName: "inventions-interaction" */ "@/components/pixi/interactions/InventionsInteraction.vue"
  );

const MeditationInteraction = () =>
  import(
    /* webpackChunkName: "meditation-interaction" */ "@/components/pixi/interactions/MeditationInteraction.vue"
  );

//end interaction import

export default {
  name: "PixiStage",
  components: {
    PixiInteraction,
    DataInteraction,
    DecorInteraction,
    InteractionIntro,
    PixiLoader,
    ScrollHint,
    MindgameInteraction,
    LightsInteraction,
    BucketlistInteraction,
    MemoInteraction,
    FashionInteraction,
    InventionsInteraction,
    MeditationInteraction
  },
  data() {
    return {
      interactionData:undefined,
      app: undefined,
      background: undefined,
      uniforms: { delta: 0, alpha: 0 },
      uniforms2: { delta: 0, alpha: 0 },
      delta: 0,
      particles: undefined,
      zoomStage: undefined,
      mouse: { x: 0, y: 0, radius: 100 },
      animationFrame: undefined,
      oldTouch: undefined,
      currentTouch: undefined,
      /*godrayFilter: undefined,*/
      rotation: { x: 0, y: 0 },
      firstFrameRendered: false,
      interactionOverlay: true,
      rendering: false,
      activeInteraction: undefined,
      introHidden: false,
      loaded: false,
      loadingStarted: false,
      loadingProgress: 0,
      flightData: undefined,
      introContent: undefined,
      isScrolled: false,
      overlayBottom: undefined,
      overlayTop: undefined,
      radialBlur: undefined,
      tiltShift: undefined,
      bulgePinch: undefined,
      container: undefined,
      transitionToggled: false,
      filters: [],
      timePassed: 0,
      timer: undefined
    };
  },
  methods: {
    onTimer() {
      if (!this.activeInteraction) this.timePassed += 1;
      const time = this.timePassed;
      let mins = ~~((time % 3600) / 60);
      let secs = ~~time % 60;

      if (mins <= 9) mins = "0" + mins;
      if (secs <= 9) secs = "0" + secs;

      this.zoomStage.updateTime(mins, secs);
    },
    changeChapter(index) {
      if (index === undefined) {
        //overview
        this.$store.commit("set_active_chapter", undefined);
        this.$store.commit("set_chapters_visible", true);
      } else {
        this.$store.commit("set_active_chapter", index);
      }
    },
    initBaseScene() {
      //if (!this.tiltShift) this.tiltShift = new TiltShiftFilter(20, 900);

      this.container = new PIXI.Container();
      //this.background = new GradientBackgroundVideo();

      this.background = new GradientBackground();

      this.app.stage.addChild(this.container);

      this.container.addChild(this.background.pixi);
      this.background.setBounds(1000, 1000);

      this.resize();
      this.animate();
    },

    loadJson() {
      this.isScrolled = false;

      const file = "chapter" + this.activeChapter + ".json";
      axios
        .get("/json/" + file)
        .then(res => this.initScene(res.data))
        .catch(err => console.log(err));
    },
    updateOverlays() {
      const speed = Number(this.zoomStage.currentSpeed.toFixed(4));
      //console.log(speed);
      if (speed !== 0) {
        //
        this.overlayBottomItems.forEach(item => {
          const p = item.timeline.progress();
          let targetP = p + speed * 0.05;

          if (targetP < 0) targetP = 1;
          if (targetP > 1) targetP = 0;

          item.timeline.progress(targetP);
        });
      }
    },
    stopDurationTimer() {
      if (this.timer) clearInterval(this.timer);
      this.timer = null;
    },
    startDurationTimer() {
      this.stopDurationTimer();
      this.timer = setInterval(this.onTimer, 1000);
    },
    onFlightLoaded() {
      this.loaded = true;
      this.loadingProgress = undefined;
      this.startDurationTimer();
      this.resize();

      setTimeout(this.stopTransition, 250);
    },
    onFlightLoading(e) {
      this.loadingProgress = e.detail;
      //console.log(this.loadingProgress);
    },

    animate() {
      if (!this.rendering && this.firstFrameRendered) return;
      //this.godrayFilter.time += 0.01;
      this.firstFrameRendered = true;
      if (this.zoomStage) this.zoomStage.render();

      this.app.renderer.render(this.app.stage);
      this.animationFrame = requestAnimationFrame(this.animate);
    },
    hideInteraction() {
      if (this.$route.path !== "/") return;
      this.activeInteraction = undefined;

      this.$store.commit("set_journey_active", true);

      this.zoomStage.targetZ -= 20;
      this.introHidden = false;
      this.zoomStage.clearForceOffset();

      /*
      eventCategory	text	yes	Typically the object that was interacted with (e.g. 'Video')
eventAction	text	yes	The type of interaction (e.g. 'play')
eventLabel	text	no	Useful for categorizing events (e.g. 'Fall Campaign')*/

      this.$gtag.event("Back to journey", {
        event_category: "Journey"
      });
    },
    onJourneyEnd() {
      if (!this.journeyEnded) {
        this.journeyEnded = true;
        //TRACK!
        this.$gtag.event("Journey ended", {
          event_category: "Journey"
        });
      }
    },
    onIntroHiding() {
      this.introHidden = true;

      this.$gtag.event("Interaction started", {
        event_category:
          "Interaction: " +
          this.activeInteraction.charAt(0).toUpperCase() +
          this.activeInteraction.slice(1)
      });
    },
    onInteractionActivate(e) {
      if (e.detail.indexOf("next") === -1) {
        this.activeInteraction = e.detail;
        this.introContent = this.flightData.items.find(
          x => x.id === e.detail
        ).intro;

        this.interactionData = this.flightData.items.find(
          x => x.id === e.detail
        ).interaction_data;

        this.introHidden = false;
        this.$store.commit("set_journey_active", false);
      } else {
        setTimeout(() => {
          const current = this.$store.getters.activeChapter;
          const chapterNames = this.$store.getters.chapters;
          this.$store.commit("set_hash", chapterNames[current + 1]);
        }, 150);
      }
    },
    initScene(data) {
      this.flightData = data;
      this.loaded = false;

      if (!this.zoomStage) {
        this.zoomStage = new ZoomStage(data);
        this.container.addChild(this.zoomStage.pixi);
      } else {
        this.zoomStage.setData(data);

        this.loadingStarted = true;
        this.zoomStage.startLoading();
      }
      //MIT JSON ABGLEICHEN!
      this.background.setColors(data);

      if (data.interactions) {
        const animalsData = data.interactions.find(x => x.id === "animals");

        if (!this.animalInteraction && animalsData) {
          this.animalInteraction = new AnimalInteraction(animalsData.items);
        }

        if (animalsData) this.container.addChild(this.animalInteraction.pixi);

        this.resize();
      }

      this.filters = [];
      this.container.filters = this.filters;

      this.startTransition();
    },
    toggleTransition() {
      this.transitionToggled = !this.transitionToggled;
      if (this.transitionToggled) this.startTransition();
      if (!this.transitionToggled) this.stopTransition();
    },
    forceTransitionHide() {
      if (this.container.filters) this.stopTransition();
    },
    startTransition() {
      if (!this.radialBlur)
        this.radialBlur = new RadialBlurFilter(
          0,
          [window.innerWidth / 2, window.innerHeight / 2],
          15,
          0
        );

      if (!this.bulgePinch) {
        this.bulgePinch = new BulgePinchFilter({
          center: [0.5, 0.5],
          radius: 0,
          strength: 0
        });
      }

      this.container.filters.push(this.radialBlur);
      if (this.activeChapter === 2)
        this.container.filters.push(this.bulgePinch);

      gsap.to(this.bulgePinch, {
        ease: "Expo.easeOut",
        duration: 1,
        strength: -1,
        radius: 1000,
        overwrite: true
      });

      gsap.to(this.radialBlur, {
        ease: "Expo.easeOut",
        duration: 1,
        angle: 150,
        radius: 1000,
        overwrite: true
      });
    },

    stopTransition() {
      gsap.to(this.bulgePinch, {
        ease: "Sine.easeOut",
        duration: 1,
        delay: 0.25,
        strength: -1,
        radius: 0,
        overwrite: true
      });

      gsap
        .to(this.radialBlur, {
          ease: "Expo.easeOut",
          duration: 1,
          delay: 0.25,
          angle: 0,
          radius: 1,
          overwrite: true
        })
        .then(() => {
          this.container.filters = [];
        });
    },

    onTouchStart(e) {
      this.currentTouch = {
        x: e.changedTouches[0].pageX,
        y: e.changedTouches[0].pageY
      };
    },
    onTouchEnd() {
      this.currentTouch = undefined;
      this.oldTouch = undefined;
    },
    onTouchMove(e) {
      if (!this.isActive || !this.loaded) return;

      this.isScrolled = true;

      const oldTouch = this.oldTouch;
      this.currentTouch = {
        x: e.changedTouches[0].pageX,
        y: e.changedTouches[0].pageY
      };

      if (oldTouch) {
        this.zoomStage.targetZ -= (this.currentTouch.y - oldTouch.y) * 0.2;
        this.zoomStage.clearForceOffset();
      }

      this.oldTouch = this.currentTouch;
      //this.updateMouse(e,this.currentTouch);  //debatable...maybe i should use gyro
    },
    onMouseWheel(e) {
      if (!this.isActive || !this.loaded) return;
      this.isScrolled = true;

      const normalized = normalizeWheel(e);
      this.zoomStage.targetZ -= normalized.spinY;

      this.zoomStage.clearForceOffset();
    },
    resize() {
      const w = this.$screen.width;
      const h = this.$screen.height;

      this.background.setBounds(w, h);
      this.app.renderer.resize(w, h);

      if (this.zoomStage) this.zoomStage.setBounds(w, h);

      if (this.animalInteraction) {
        this.animalInteraction.pixi.x = w * 0.5;
        this.animalInteraction.pixi.y = h * 0.5;
      }

      let scale = w / 1920;
      if (this.overlayBottomContainer) {
        this.overlayBottomContainer.x = w / 2;
        this.overlayBottomContainer.y = h - 100;
      }

      if (this.overlayTop) {
        this.overlayTop.scale.set(scale);
        this.overlayTop.y = this.overlayTop.height * 0.5;
        this.overlayTop.x = w * 0.5;
      }

      if (!this.isActive) {
        //resize bugfix when not active rendering
        if (this.zoomStage) this.zoomStage.render();
      }
    },
    onInteractionProgress(e) {
      if (e.detail.id === "animals") this.animalInteraction.progress(e.detail);
    },
    onInteractionHide(e) {
      if (e.detail.id === "animals") this.animalInteraction.hide();
    },

    updateMouse() {
      let position = this.mouse;

      if (this.zoomStage) {
        if (this.rotation.x != 0) {
          position.xPercent = this.rotation.x / 6;
          position.yPercent = 1 - this.rotation.y / 6;
        }

        this.zoomStage.setMouse(position);
      }
      if (this.particles) this.particles.setMouse(position);
      if (this.animalInteraction) this.animalInteraction.setMouse(position);

      if (!this.$store.getters.hasTouch) {
        //todo: store to paralaxOffset
        const paralaxOffset = {
          x: -1 + position.xPercent * 2,
          y: -1 + position.yPercent * 2
        };
        this.$store.commit("set_paralax_offset", paralaxOffset);
      }
    },
    firstClick() {
      this.requestDeviceMotion(err => {
        if (err == null) {
          window.removeEventListener("click", this.firstClick);
          window.removeEventListener("touchend", this.firstClick);

          window.addEventListener("devicemotion", this.onDeviceMotion);
        } else {
          // failed; a JS error object is stored in `err`
          window.removeEventListener("click", this.firstClick);
          window.removeEventListener("touchend", this.firstClick);
        }
      });
    },
    onDeviceMotion(e) {
      const rotation = {};
      rotation.x = e.accelerationIncludingGravity.x;
      rotation.y = e.accelerationIncludingGravity.y + 6;

      this.rotation.x -= (this.rotation.x - rotation.x) * 0.1;
      this.rotation.y -= (this.rotation.y - rotation.y) * 0.1;

      //todo: store to paralaxOffset
      /*const paralaxOffset = {
        x: -1 + position.xPercent * 2,
        y: -1 + position.yPercent * 2
      };
      this.$store.commit("set_paralax_offset", paralaxOffset);*/

      this.updateMouse();
    },
    requestDeviceMotion(callback) {
      if (window.DeviceMotionEvent == null) {
        callback(new Error("DeviceMotion is not supported."));
      } else if (DeviceMotionEvent.requestPermission) {
        DeviceMotionEvent.requestPermission().then(
          function (state) {
            if (state == "granted") {
              callback(null);
            } else callback(new Error("Permission denied by user"));
          },
          function (err) {
            callback(err);
          }
        );
      } else {
        // no need for permission
        callback(null);
      }
    }
  },
  computed: {
    teasedColor() {
      return this.$store.getters.teasedBackgroundColor;
    },
    activeChapter() {
      return this.$store.getters.activeChapter;
    },
    restartPending() {
      return this.$store.getters.restartJourney;
    },
    isStarted() {
      return this.$store.getters.journeyStarted;
    },
    isActive() {
      return this.$store.getters.journeyActive;
    },
    stageWidth() {
      return this.$screen.width;
    },
    stageHeight() {
      return this.$screen.height;
    },
    mouseUpdate() {
      return this.$mousePos.lastChange;
    }
  },

  watch: {
    isScrolled() {
      if (this.isScrolled) {
        this.forceTransitionHide();
      }
    },

    $route() {
      if (!this.$route.query.interaction) {
        this.activeInteraction = undefined;
        this.introHidden = false;

        if (this.isStarted) {
          const options = this.$store.getters.chapters;
          const index = options.indexOf(this.$route.hash);

          if (index >= 0) {
            //comes back from interaction
            //this.$store.commit("set_journey_active", true);
            this.hideInteraction();
          }
        }
      } else {
        this.activeInteraction = this.$route.query.interaction;

        this.introContent = this.flightData.items.find(
          x => x.id === this.activeInteraction
        ).intro;

        //this.introHidden = false;
        this.$store.commit("set_journey_active", false);
      }
    },
    teasedColor() {
      if (this.activeChapter !== undefined) return;

      if (this.teasedColor) {
        this.background.setColors(this.teasedColor);
      } else {
        this.background.setColors();
      }
    },
    activeChapter() {
      if (this.activeChapter !== undefined) {
        this.loadingStarted = false;
        this.loadingProgress = 0;
        this.loaded = false;
        this.timePassed = 0;

        this.loadJson();
      } else {
        this.background.setColors();
      }
    },
    activeInteraction() {
      if (this.activeInteraction) {
        //console.log("INTERACTION: ", this.activeInteraction, this.$route);
        if (!this.$route.query.interaction) {
          this.$router.push({
            path: this.$route.path,
            hash: this.$route.hash,
            query: { interaction: this.activeInteraction }
          });
        }

        this.$gtag.event("Interaction opened", {
          event_category:
            "Interaction: " +
            this.activeInteraction.charAt(0).toUpperCase() +
            this.activeInteraction.slice(1)
        });
      } else {
        if (this.$route.hash) {
          this.$router.push({
            path: this.$route.path,
            hash: this.$route.hash,
            query: {}
          });
        }
      }
    },
    restartPending() {
      if (this.loaded && this.restartPending) {
        this.journeyEnded = false;

        this.zoomStage.z = -100;
        this.zoomStage.targetZ = 25;
        this.$store.commit("set_restart_journey", false);
      }
    },
    isStarted() {
      if (this.zoomStage) {
        this.zoomStage.z = -100;
        this.zoomStage.targetZ = 25;
      }

      this.journeyEnded = false;

      if (!this.isStarted && this.activeInteraction) {
        this.activeInteraction = undefined;
      }

      if (this.isStarted) {
        setTimeout(this.stopTransition, 100);
      }
    },
    isActive() {
      if (this.isActive) {
        this.activeInteraction = undefined;
        this.startDurationTimer();

        //TODO: INTERACTIONS HIDEN
        gsap.to(this.zoomStage.pixi, {
          overwrite: true,
          duration: 0.5,
          alpha: 1
        });

        if (this.animalInteraction) {
          gsap.to(this.animalInteraction.pixi, {
            overwrite: true,
            duration: 0.5,
            alpha: 1
          });
        }

        if (!this.loadingStarted) {
          this.loadingStarted = true;
          this.zoomStage.startLoading();
        }

        this.rendering = true;
        window.cancelAnimationFrame(this.animationFrame);
        this.animationFrame = null;

        this.animate();
        this.app.start();
      } else {
        this.stopDurationTimer();

        if (this.animalInteraction) {
          gsap.to(this.animalInteraction.pixi, {
            overwrite: true,
            duration: 0.5,
            alpha: 0
          });
        }
        if (!this.zoomStage) return;

        gsap
          .to(this.zoomStage.pixi, { overwrite: true, duration: 0.5, alpha: 0 })
          .then(() => {
            window.cancelAnimationFrame(this.animationFrame);
            this.animationFrame = null;
            this.rendering = false;
          });
      }
    },
    mouseUpdate() {
      this.mouse.xPercent = this.$mousePos.x / window.innerWidth;
      this.mouse.yPercent = this.$mousePos.y / window.innerHeight;
      this.updateMouse();
    }
  },
  beforeDestroy() {
    this.app.destroy();
    window.onresize = null;
    document.ontouchstart = null;
    document.ontouchmove = null;

    //window.removeEventListener("mousewheel", this.onMouseWheel);
    window.removeEventListener("wheel", this.onMouseWheel);
    window.removeEventListener("DOMMouseScroll", this.onMouseWheel);
    window.removeEventListener("intro-done", this.onIntroDone);
    window.removeEventListener("interaction", this.onInteractionProgress);
    window.removeEventListener("interaction-hide", this.onInteractionHide);
    window.removeEventListener("click", this.firstClick);
    window.removeEventListener("touchend", this.firstClick);
    window.removeEventListener("devicemotion", this.onDeviceMotion);

    window.removeEventListener(
      "interaction-activate",
      this.onInteractionActivate
    );

    window.removeEventListener("flight-loaded", this.onFlightLoaded);
    window.removeEventListener("flight-loading", this.onFlightLoading);

    window.removeEventListener("journey-end", this.onJourneyEnd);

    window.cancelAnimationFrame(this.animationFrame);
  },
  mounted() {
    PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

    //const dpr = window.devicePixelRatio || 1;

    const app = new PIXI.Application({
      width: Math.round(window.innerWidth),
      height: Math.round(window.innerHeight),
      backgroundColor: 0xffffff,
      backgroundAlpha: 1
    });

    this.app = app;
    this.$refs.pixiStage.appendChild(app.view);

    window.onresize = debounce(this.resize, 200);
    window.addEventListener("interaction", this.onInteractionProgress);
    window.addEventListener("interaction-hide", this.onInteractionHide);
    window.addEventListener("intro-done", this.onIntroDone);
    window.addEventListener("interaction-activate", this.onInteractionActivate);
    window.addEventListener("flight-loaded", this.onFlightLoaded);
    window.addEventListener("flight-loading", this.onFlightLoading);
    window.addEventListener("journey-end", this.onJourneyEnd);

    const hasTouch = this.$store.getters.hasTouch;

    this.$store.commit("set_has_touch", hasTouch);

    if (hasTouch) {
      document.ontouchstart = this.onTouchStart;
      document.ontouchend = this.onTouchEnd;
      document.ontouchmove = this.onTouchMove;

      //DEVICE MOTION
      window.addEventListener("click", this.firstClick);
      window.addEventListener("touchend", this.firstClick);
    } else {
      //window.addEventListener("mousewheel", this.onMouseWheel);
      window.addEventListener("wheel", this.onMouseWheel);
      window.addEventListener("DOMMouseScroll", this.onMouseWheel);
    }

    this.initBaseScene();

    //if(this.$route.path === "/") this.isActive = true;
  }
};
</script>

<template>
  <div class="decors-slider">
    <div class="decors-slider__prev">
      <inline-svg
        @click="swipeLeft"
        :src="require('../../assets/svg/swiper_pager.svg')"
      />
    </div>
    <swiper
      ref="swiper"
      class="decors-slider__swiper"
      :auto-update="true"
      :auto-destroy="true"
      :options="swiperOptions"
      @slideChange="onSwiperChange"
    >
      <swiper-slide v-for="(item, index) in items" :key="index">
        <div class="decors-slider__item">
          <div class="decors-slider__item-flyout">
            {{ item.name }}
          </div>
          <div class="decors-slider__item-img" @click="openDecor(item.slug)">
            <img :src="item.img" alt="" />
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <div class="decors-slider__next">
      <inline-svg
        @click="swipeRight"
        :src="require('../../assets/svg/swiper_pager.svg')"
      />
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "DecorsSlider",
  data() {
    return {
      swiperOptions: {
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        loop: true,
        slidesPerView: 10,
        spaceBetween: 10,
        parallax: true,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 6,
            spaceBetween: 10
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 6,
            spaceBetween: 10
          }, 
          768: {
            slidesPerView: 10,
            spaceBetween: 10
          }
        }
      }
    };
  },
  props: {
    items: undefined,
    active: undefined
  },
  methods: {
    swipeLeft() {
      this.$refs.swiper.swiperInstance.slidePrev();
    },
    swipeRight() {
      this.$refs.swiper.swiperInstance.slideNext();
    },
    openDecor(decor) {
      gsap.to(document.body, { scrollTop: 0, duration: 0.5 });

      this.$router.push("/decors/" + decor);
    },
    onSwiperChange() {}
  }
};
</script>


<template>
  <div class="view trends">
    <TrendsDetail :trend="trendContent" v-if="trend" />
    <FloatCluster @click="openTrend" :contentJson="filteredContentJson" v-if="!trend" pointStyle="1" />
    <div class="view__background" v-if="trend"></div>
  </div>
</template>
<script>
import AppearMixin from "@/mixins/AppearMixin.js";
import DataMixin from "@/mixins/DataMixin.js";
import TrendsDetail from "@/components/trends/TrendsDetail";
import FloatCluster from "@/components/floatcluster/FloatCluster.vue";

export default {
  name: "Trends",
  mixins: [DataMixin, AppearMixin],
  components: { TrendsDetail, FloatCluster },
  data() {
    return {
      items: [],
      trendName: undefined,
      trendContent: undefined,
      chapter: undefined,
      filteredContentJson: undefined
    };
  },
  props: {
    trend: undefined //validator
  },
  methods: {
    openTrend(slug) {
      this.$router.push({ path: "/trends/" + slug });
    },
    checkSubPage() {
      if (this.trend) {
        const isValid = this.validateSubpage(this.trend);

        if (!isValid) {
          this.$router.push({ path: "/trends" });
          return;
        }

        const trendContent = this.$store.getters
          .getCachedData("trends")
          .trends.filter(trend => trend.slug === this.trend)[0];

        if (trendContent) {
          this.trendName = trendContent.name;
          this.trendContent = trendContent;
        } else {
          this.trendName = undefined;
          this.trendContent = undefined;
        }
      } else {
        this.trendName = undefined;
        this.trendContent = undefined;
      }
    }
  },
  watch: {
    trend() {
      this.checkSubPage();
    },
    content() {
      this.checkSubPage();
      this.items = this.content;

      let chapter = this.$store.getters.activeChapter;

      if(location.hash) {
        const active = this.$store.getters.chapters.find(el => el === location.hash);
        if(active) {
          const c = this.$store.getters.chapters.indexOf(active);
          chapter = c;
        }
      }

      if(chapter === undefined) chapter = 0;  

      this.setColors(chapter);

      if (this.trend) {
        this.checkSubPage();
      }

      this.chapter = chapter;

      if (!this.$store.getters.filteredChapterContents[chapter].trends) {
        //CHAPTER 1 & 2
        this.filteredContentJson = this.contentJson;
      } else {
        const items = [];

        this.$store.getters.filteredChapterContents[chapter].trends.forEach(
          trend => {
            const filtered = this.content.filter(item => item.name == trend.title);
            if (filtered[0]) {
              items.push(filtered[0]);
            } else {
              console.log("NOT FOUND: ", trend.title);
            }
          }
        );

        const raw = this.contentJson;
        raw.trends = items;

        this.filteredContentJson = raw;
      }

    }
  },
  computed: {},
  mounted() {
    this.fetchData("trends");
  }
};
</script>

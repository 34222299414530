import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueGtag from "vue-gtag";
import VueMeta from 'vue-meta'

import PixiPlugin from "gsap/PixiPlugin";
import Draggable from "gsap/Draggable";
import gsap from "gsap";

import VueSocialSharing from 'vue-social-sharing'
import * as PIXI from "pixi.js";
Vue.use(VueSocialSharing);

PixiPlugin.registerPIXI(PIXI);

gsap.registerPlugin(PixiPlugin)
gsap.registerPlugin(Draggable)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

import checkView from "vue-check-view";
Vue.use(checkView);
Vue.use(require('vue-moment'));


import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
Vue.use(LottieAnimation); // add lottie-animation to your global scope

import SwiperClass, { Navigation, Pagination, /* add some modules if you need */ } from "swiper";
SwiperClass.use([Navigation, Pagination]);

import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'
// styles required for Pagination component
import 'swiper/components/pagination/pagination.min.css';

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

//GRIDDLE
/* Example in project setup file */
import { VueGriddle } from "@braid/griddle";
Vue.component("Griddle", VueGriddle);

// as a plugin
import { InlineSvgPlugin } from "vue-inline-svg";
Vue.use(InlineSvgPlugin);

import Vue2TouchEvents from "vue2-touch-events";
Vue.use(Vue2TouchEvents);

//SCREEN SIZE OBSERVER
Vue.prototype.$screen = Vue.observable({
  width: window.innerWidth,
  height: window.innerHeight,
  lastChange: 0
});

window.addEventListener("resize", () => {
  Vue.prototype.$screen.lastChange = new Date().getTime();
  Vue.prototype.$screen.width = Math.round(window.innerWidth);
  Vue.prototype.$screen.height = Math.round(window.innerHeight);
});

//SCREEN SIZE OBSERVER
Vue.prototype.$mousePos = Vue.observable({
  x: 0,
  y: 0,
  lastChange: 0
});

window.addEventListener("mousemove", e => {
  Vue.prototype.$mousePos.lastChange = new Date().getTime();
  Vue.prototype.$mousePos.x = e.pageX;
  Vue.prototype.$mousePos.y = e.pageY;
});

//PARALAX OBSERVER - TODO: gyro
Vue.prototype.$paralax = Vue.observable({
  x: 0,
  y: 0,
  lastChange: 0
});

window.addEventListener("mousemove", e => {
  Vue.prototype.$paralax.lastChange = new Date().getTime();
  Vue.prototype.$paralax.x = -1 + (e.pageX / window.innerWidth) * 2;
  Vue.prototype.$paralax.y = -1 + (e.pageY / window.innerHeight) * 2;
});

//Tracking

Vue.use(VueGtag, {
  config: { id: "UA-202896465-1" }
},router);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

<template>
  <div class="video-slider">
    <!--<img :src="content.gradient_img" alt="" />-->
    <div class="trends-detail__template-text-container">
      <h1>{{content.headline}}</h1>
    </div>

    <div ref="swiper_container" class="trends-detail__template-video-container">
      <div
        class="trends-detail__template-video-container-bg"
        :style="{ height: videoContainerBgHeight }"
      ></div>
      <swiper
        ref="swiper"
        :auto-update="true"
        :auto-destroy="true"
        :options="swiperOptions"
        :class="{ centered: videosCentered }"
        @slideChange="onSwiperChange"
        @reachEnd="onReachEnd"
        @reachBeginning="onReachBeginning"
      >
        <swiper-slide v-for="(item, index) in content.videos" :key="index">
          <div class="trends-detail__video-teaser" @click="onVideoClick(item)">
            <img
              :src="item.preview_image.sizes['medium_large']"
              alt=""
              @load="calculateSameVideoTeaserHeight"
            />
            <div class="trends-detail__video-teaser-icon">
              <inline-svg :src="require('../../assets/svg/icon_play.svg')" />
            </div>
          </div>
          <div class="trends-detail__video-subline">
            <h1>{{ item.video_headline }}</h1>
            <h2>{{ item.video_subline }}</h2>
          </div>
        </swiper-slide>
      </swiper>

      <div class="swiper-navigation" :class="{ centered: videosCentered }">
        <inline-svg
          @click="swipeLeft"
          :class="{ disabled: !swipeLeftEnabled }"
          :src="require('../../assets/svg/swiper_pager.svg')"
        />

        <inline-svg
          @click="swipeRight"
          :class="{ disabled: !swipeRightEnabled }"
          :src="require('../../assets/svg/swiper_pager.svg')"
        />
      </div>

      <div
        class="swiper-pagination swiper-pagination-bullets"
        slot="pagination"
        :class="{ ['swiper-pagination--'+index]:true,centered: videosCentered }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoSlider",
  props: {
    content: undefined,
    index: undefined
  },
  data() {
    return {
      videosCentered: false,
      videoContainerBgHeight: 0,
      swipeRightEnabled: true,
      swipeLeftEnabled: false,
      
      swiperOptions: {
        watchSlidesVisibility: true,
        spaceBetween: 50,
        pagination: {
          el: `.swiper-pagination--${this.index}`,
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      }
    };
  },
  computed: {
    screenWidth() {
      return this.$screen.width;
    }
  },

  watch: {
    screenWidth() {
      setTimeout(this.calculateSameVideoTeaserHeight, 50);
      //this.calculateSameVideoTeaserHeight();
    }
  },
  methods: {
      onReachEnd(){

          this.swipeRightEnabled = false;
      },
      onReachBeginning(){
          this.swipeLeftEnabled = false;

      },
    onSwiperChange() {
      this.swipeRightEnabled = !this.$refs.swiper.swiperInstance.isEnd;
      this.swipeLeftEnabled = !this.$refs.swiper.swiperInstance.isBeginning;
    },
    swipeRight() {
      this.$refs.swiper.swiperInstance.slideNext();
    },
    swipeLeft() {
      this.$refs.swiper.swiperInstance.slidePrev();
    },

    onVideoClick(item) {
        console.log(item);
        
      this.$store.commit("set_lightbox_content", {
        headline: item.video_headline,
        subline: item.video_subline,
        vimeo: item.vimeo_id || '542101473'
      });
    },
    calculateSameVideoTeaserHeight() {
      this.videosCentered = false;

      this.$nextTick(() => {
        const visibleItems = this.$refs.swiper_container.getElementsByClassName(
          "swiper-slide-visible"
        ).length;
        const allItems = this.$refs.swiper_container.getElementsByClassName(
          "swiper-slide"
        ).length;
        this.videosCentered = visibleItems === allItems;
      });

      if (window.innerWidth < 768) {
        this.videoContainerBgHeight = "100%";
        return;
      }

      let height = 0;
      const array = Array.from(
        this.$el.getElementsByClassName("trends-detail__video-teaser")
      );

      array.forEach(item => {
        const testHeight = item.getBoundingClientRect().height - 50;
        if (testHeight > height) height = testHeight;
      });

      this.videoContainerBgHeight = height + "px";
    }
  },
  mounted() {
      setTimeout(this.calculateSameVideoTeaserHeight, 50);
  }
};
</script>





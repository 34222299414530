
<template>
  <div class="view decors">
    <DecorsDetail :decor="decorContent" :allItems="items" v-if="decor" />
    <DecorsNavigation
      :items="items"
      :headline="headline"
      :subline="subline"
      :active="!decor"
    />
    <div class="view__background" v-if="decor"></div>
  </div>
</template>
<script>
import DataMixin from "@/mixins/DataMixin.js";
import DecorsNavigation from "@/components/decors/DecorsNavigation";
import DecorsDetail from "@/components/decors/DecorsDetail";

export default {
  name: "Decors",
  mixins: [DataMixin],
  components: { DecorsNavigation, DecorsDetail },
  data() {
    return {
      items: [],
      decorName: undefined,
      decorContent: undefined,
      headline: undefined,
      subline: undefined,
      chapter: undefined,
    };
  },
  props: {
    decor: undefined //validator
  },
  methods: {
    checkSubPage() {
      if (this.decor) {
        const isValid = true; //this.validateSubpage(this.decor);
        if (!isValid) {
          this.$router.push({ path: "/decors" });
          return;
        }

        const decorContent = this.$store.getters
          .getCachedData("decors")
          .decors.filter(decor => decor.slug === this.decor)[0];

        if (decorContent) {
          this.decorName = decorContent.name;
          this.decorContent = decorContent;
        } else {
          this.decorName = undefined;
          this.decorContent = undefined;
        }
      } else {
        this.decorName = undefined;
        this.decorContent = undefined;
      }
    }
  },
  watch: {
    contentJson() {
      this.headline = this.contentJson.headline;
      this.subline = this.contentJson.subline;
    },
    decor() {
      this.checkSubPage();
    },
    content() {
      this.checkSubPage();

      //filter!
      const items = [];
      let chapter = this.$store.getters.activeChapter;
      if(location.hash === "#zeitgeist") chapter = 2;

      if(chapter === undefined) chapter = 0;

      this.chapter = chapter;
      this.setColors(chapter);

      this.$store.getters.filteredChapterContents[chapter].decors.forEach(
        decor => {
          const cleanedTitle = decor.title.replace("_III","");
          const filtered = this.content.filter(item => item.slug == cleanedTitle);
          
          if (filtered[0]) items.push(filtered[0]);
        }
      );

      this.items = items;

      if (this.decor) {
        this.checkSubPage();
      }
    }
  },
  computed: {},
  mounted() {
    this.fetchData("decors");
  }
};
</script>

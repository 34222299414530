<template>
  <div
    class="float-cluster__item"
    v-view="viewHandler"
    v-if="position"
    :style="{
      left: position.x + '%',
      top: position.y + '%',
      width: position.width + '%',
      height: teaserHeight
    }"
    :class="{
      'float-cluster__item--active': isActive,
      ['float-cluster__item--' + content.teaser_style]: true
    }"
  >
    <div
      class="float-cluster__item-container"
      v-for="(item, index) in content.teaser_images"
      :key="index"
      :class="{ ['float-cluster__item-container--' + item.type]: true }"
      @mouseenter="onMouseOver"
      @mouseleave="onMouseOut"
    >
      <img :src="item.file" @load="onImageLoaded" />
      
    </div>
    <!--div class="debug">{{content.teaser_style}}</div>-->
    <div class="float-cluster__item-text">
      <h1>{{ content.name }}</h1>
      <h2>{{ content.subline }}</h2>

      <!--{{ content.teaser_style }}-->
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "FloatClusterItem",
  props: {
    clusterStyle: undefined,
    content: undefined,
    position: undefined
  },
  data() {
    return {
      teaserHeight: 0,
      isActive: false
    };
  },

  methods: {
    onImageLoaded() {
      this.onMouseOut();
    },
    viewHandler(e) {
      if (window.innerWidth > 770) return;

      if (e.percentCenter > 0.2 && e.percentCenter <= 1 && !this.isActive) {
        this.onMouseOver();
      } else if (
        e.percentCenter < 0.2 ||
        (e.percentCenter > 1 && this.isActive)
      ) {
        this.onMouseOut();
      }
    },
    onMouseOver() {
      this.isActive = true;

      gsap.to(this.$el, {
        overwrite: true,
        ease: "Expo.easeOut",
        scale: 1.05,
        duration: 0.5
      });

      gsap.to(this.$el.getElementsByClassName("float-cluster__item-text"), {
        overwrite: true,
        ease: "Expo.easeOut",
        autoAlpha: 1,
        duration: 1
      });

      gsap.to(this.$el.getElementsByClassName("float-cluster__item-text"), {
        ease: "Expo.easeOut",
        x: "5%",
        duration: 2
      });

      
      
      //random style
      const imageDecor = this.$el.getElementsByClassName(
        "float-cluster__item-container--img-decor"
      );
      const imageMain = this.$el.getElementsByClassName(
        "float-cluster__item-container--img-main"
      );
      const imageExtra = this.$el.getElementsByClassName(
        "float-cluster__item-container--img-extra"
      );
      const imageTexture = this.$el.getElementsByClassName(
        "float-cluster__item-container--img-texture"
      );

      if (imageDecor) {
        let targetWidth = "100%";
        let targetScale = 1;
        let targetOffsetY = "5%";

        if (this.content.teaser_style.indexOf("portrait") !== -1) {
          targetWidth = "100%";
          targetScale = 1.3;
          targetOffsetY = "-6%";
        }

        gsap.to(imageDecor, {
          duration: 0.5 + Math.random() * 0.5,
          scale: targetScale,
          x: 0,
          width: targetWidth,
          y: targetOffsetY,
          autoAlpha: 1,
          ease: "Expo.easeOut",
          overwrite: true
        });
      }

      if (imageMain) {
        gsap.to(imageMain, {
          duration: 0.5 + Math.random() * 0.5,
          scale: 1.2,
          x: 0,
          y: "-5%",
          autoAlpha: 1,
          ease: "Expo.easeOut",
          overwrite: true,
          xPercent:-50,
          yPercent:-50
        });
      }

      if (imageTexture)
        gsap.to(imageTexture, {
          duration: 0.5 + Math.random() * 0.5,
          scale: 1.2,
          y: "10%",
          x: "-30%",
          autoAlpha: 1,
          ease: "Expo.easeOut",
          overwrite: true
        });

      if (imageExtra)
        gsap.to(imageExtra, {
          duration: 0.5 + Math.random() * 0.5,
          x: "-30%",
          autoAlpha: 1,
          ease: "Expo.easeOut",
          overwrite: true
        });
    },
    onMouseOut() {
      this.isActive = false;

      gsap.to(this.$el, { scale: 1, duration: 1 });

      gsap.to(this.$el.getElementsByClassName("float-cluster__item-text"), {
        overwrite: true,
        ease: "Expo.easeOut",
        autoAlpha: 0,
        duration: 0.5
      });

      gsap.to(this.$el.getElementsByClassName("float-cluster__item-text"), {
        ease: "Expo.easeOut",
        x: "-10%",
        duration: 1
      });

      const containers = this.$el.getElementsByClassName(
        "float-cluster__item-container"
      );
      /*
      const imageDecor = this.$el.getElementsByClassName(
        "float-cluster__item-container--img-decor"
      );
      const imageMain = this.$el.getElementsByClassName(
        "float-cluster__item-container--img-main"
      );
      const imageExtra = this.$el.getElementsByClassName(
        "float-cluster__item-container--img-extra"
      );
      const imageTexture = this.$el.getElementsByClassName(
        "float-cluster__item-container--img-texture"
      );
*/

      Array.prototype.slice.call(containers).forEach(element => {
        let xPos = 0;
        let yPos = 0;

        let targetAlpha = 1;

        if (
          element.classList.contains(
            "float-cluster__item-container--img-extra"
          ) ||
          element.classList.contains(
            "float-cluster__item-container--img-texture"
          )
        ) {
          targetAlpha = 0;
        }

        gsap.to(element, {
          duration: 0.7,
          scale: 1,
          x: xPos,
          y: yPos,
          autoAlpha: targetAlpha,
          overwrite: true,
          yPercent:-50,
          xPercent:-50
        });
      });
    },
    startFloat() {
      /*
      let rm = 1;
      if (Math.random() > 0.5) rm = -rm;

      const offset = (this.position.height / 100) * 30 * rm;
      gsap
        .to(this.$el, {
          ease: "Sine.easeInOut",
          y: offset,
          duration: 3 + Math.random() * 2
        })
        .then(() => {
          this.startFloat();
        });*/
    },
    stopFloat() {
      gsap.killTweensOf(this.$el);
    },
    setTeaserHeight() {
      this.$nextTick(() => {
        if(!this.$el.getBoundingClientRect) return;
        const teaserHeight = this.$el.getBoundingClientRect().width * 0.6;
        this.teaserHeight = teaserHeight + "px";
      });
    }
  },
  watch: {
    position() {
      this.setTeaserHeight();
    },
    stageScale() {
      this.setTeaserHeight();
    }
  },
  computed: {
    teaserItems(){
      return this.content.teaser_images;
    },
    stageScale() {
      return this.$screen.lastChange;
    }
  },
  beforeDestroy() {
    this.stopFloat();
  },
  mounted() {
    this.startFloat();
    this.setTeaserHeight();
  }
};
</script>

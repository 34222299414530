<template>
  <div class="trends-detail" v-if="contentJson && currentTrend">
    <div class="trends-detail__header">
      <h1>{{ currentTrend.name }}</h1>

      <img :src="contentJson.modules.gradient_img" alt="" />
      <div class="trends-detail__header-container">
        <div class="trends-detail__header-images">
          <FloatImage :offset="0" :src="contentJson.modules.header_image1" />
          <FloatImage :offset="10" :src="contentJson.modules.header_image2" />
        </div>

        <div class="trends-detail__header-text">
          <h1>{{ contentJson.modules.headline }}</h1>
          <p>{{ contentJson.modules.copy }}</p>
          <a :href="contentJson.modules.moodboard_url"
            ><inline-svg
              :src="require('../../assets/svg/icon_download.svg')"
            />{{ contentJson.modules.moodboard_link_label }}</a
          >
        </div>
      </div>
    </div>
    <div class="trends-detail__container">
      <div
        v-for="item in contentJson.modules.contents"
        :key="item.id"
        class="trends-detail__module"
      >
        <div
          class="trends-detail__template"
          v-if="item.template === 'info'"
          :class="{ ['trends-detail__template--' + item.template]: true }"
        >
          <div class="trends-detail__template-text-container">
            <h1>{{ item.headline }}</h1>
            <p>{{ item.copy }}</p>

            <h2>{{ item.cluster_headline }}</h2>
            <ul>
              <li v-for="(item, index) in item.cluster" :key="index">
                {{ item.title }}
              </li>
            </ul>
          </div>

          <div class="trends-detail__template-image-container">
            <FloatImage :offset="0" :src="item.img_1" alt="" />
            <FloatImage :offset="20" :src="item.img_2" alt="" />
            <FloatImage :offset="20" :src="item.img_3" alt="" />
            <FloatImage
              class="no-shadow"
              :offset="5"
              :src="item.img_4"
              alt=""
            />
          </div>
        </div>

        <div
          class="trends-detail__template"
          v-if="item.template === 'videos'"
          :class="{ ['trends-detail__template--' + item.template]: true }"
        >
          <img :src="item.gradient_img" alt="" />
          <div class="trends-detail__template-text-container">
            <h1>{{ item.headline }}</h1>
          </div>

          <div
            ref="swiper_container"
            class="trends-detail__template-video-container"
          >
            <div
              class="trends-detail__template-video-container-bg"
              :style="{ height: videoContainerBgHeight }"
            ></div>
            <!--<ul>
              <li v-for="(item, index) in item.videos" :key="index">
                <div
                  class="trends-detail__video-teaser"
                  @click="onVideoClick(item)"
                >
                  <img
                    :src="item.poster"
                    alt=""
                    @load="calculateSameVideoTeaserHeight"
                  />
                  <div class="trends-detail__video-teaser-icon">
                    <inline-svg
                      :src="require('../../assets/svg/icon_play.svg')"
                    />
                  </div>
                </div>
                <div class="trends-detail__video-subline">
                  <h1>{{ item.headline }}</h1>
                  <h2>{{ item.subline }}</h2>
                </div>
              </li>
            </ul>-->

            <swiper
              ref="swiper"
              :auto-update="true"
              :auto-destroy="true"
              :options="swiperOptions"
              :class="{ centered: videosCentered }"
              @slideChange="onSwiperChange"
            >
              <swiper-slide v-for="(item, index) in item.videos" :key="index">
                <div
                  class="trends-detail__video-teaser"
                  @click="onVideoClick(item)"
                >
                  <img
                    :src="item.poster"
                    alt=""
                    @load="calculateSameVideoTeaserHeight"
                  />
                  <div class="trends-detail__video-teaser-icon">
                    <inline-svg
                      :src="require('../../assets/svg/icon_play.svg')"
                    />
                  </div>
                </div>
                <div class="trends-detail__video-subline">
                  <h1>{{ item.headline }}</h1>
                  <h2>{{ item.subline }}</h2>
                </div>
              </swiper-slide>
            </swiper>

            <div
              class="swiper-navigation"
              :class="{ centered: videosCentered }"
            >
              <inline-svg
                @click="swipeLeft"
                :class="{ disabled: !swipeLeftEnabled }"
                :src="require('../../assets/svg/swiper_pager.svg')"
              />

              <inline-svg
                @click="swipeRight"
                :class="{ disabled: !swipeRightEnabled }"
                :src="require('../../assets/svg/swiper_pager.svg')"
              />
            </div>

            <div
              class="swiper-pagination swiper-pagination-bullets"
              slot="pagination"
              :class="{ centered: videosCentered }"
            ></div>
          </div>
        </div>

        <div
          class="trends-detail__template"
          v-if="item.template === 'colors'"
          :class="{ ['trends-detail__template--' + item.template]: true }"
        >
          <div class="trends-detail__template-image-container">
            <FloatImage :offset="0" :src="item.img_1" />
            <FloatImage :offset="10" :src="item.img_2" />
            <FloatImage :offset="10" :src="item.img_3" />
            <FloatImage
              class="no-shadow"
              :offset="10"
              :src="item.img_4"
              alt=""
            />
          </div>

          <div class="trends-detail__template-text-container">
            <h1>{{ item.subline_1 }}</h1>
            <p>{{ item.copy_1 }}</p>

            <ColorModule
              headline="Inspiration for this trend"
              v-if="item.decors"
              :patterns="item.decors"
            />

            <ColorModule
              headline="Color Inspiration for this trend"
              class="color-module--reverse"
              v-if="item.colors[0].name"
              :colors="item.colors"
            />
          </div>

          <div
            class="trends-detail__template-text-container trends-detail__template-text-container--second"
          >
            <h2>{{ item.subline_2 }}</h2>
            <p>{{ item.copy_2 }}</p>
          </div>
        </div>

        <div
          class="trends-detail__template"
          v-if="item.template === 'end'"
          :class="{ ['trends-detail__template--' + item.template]: true }"
        >
          <div class="trends-detail__template-image-container">
            <FloatImage :offset="0" :src="item.img_1" />
            <FloatImage :offset="5" :src="item.img_2" />
            <FloatImage :offset="10" class="no-shadow" :src="item.img_3" />
            <FloatImage :offset="2" class="no-shadow" :src="item.img_4" />
            <FloatImage :offset="7" :src="item.img_5" />
          </div>
          <div class="trends-detail__template-text-container">
            <h1>{{ item.headline }}</h1>
            <p>{{ item.copy_1 }}</p>
            <p>{{ item.copy_2 }}</p>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorModule from "@/components/ColorModule";
import FloatImage from "@/components/FloatImage";
import DataMixin from "@/mixins/DataMixin.js";

export default {
  name: "TrendsDetail",
  mixins: [DataMixin],
  components: {
    ColorModule,
    FloatImage
  },
  props: {
    trend: undefined
  },
  data() {
    return {
      active: 0,
      currentTrend: undefined,
      videoContainerBgHeight: "0",
      patterns: undefined,
      videosCentered: false,
      swipeRightEnabled: true,
      swipeLeftEnabled: false,
      swiperOptions: {
        watchSlidesVisibility: true,
        spaceBetween: 50,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      }
    };
  },
  methods: {
    onSwiperChange() {
      this.swipeRightEnabled = !this.$refs.swiper[0].swiperInstance.isEnd;
      this.swipeLeftEnabled = !this.$refs.swiper[0].swiperInstance.isBeginning;
    },
    swipeRight() {
      this.$refs.swiper[0].swiperInstance.slideNext();
    },
    swipeLeft() {
      this.$refs.swiper[0].swiperInstance.slidePrev();
    },

    onVideoClick(item) {
      this.$store.commit("set_lightbox_content", {
        headline: item.headline,
        subline: item.subline,
        vimeo: item.id
      });
    },
    calculateSameVideoTeaserHeight() {
      this.videosCentered = false;

      this.$nextTick(() => {
        const visibleItems = this.$refs.swiper_container[0].getElementsByClassName(
          "swiper-slide-visible"
        ).length;
        const allItems = this.$refs.swiper_container[0].getElementsByClassName(
          "swiper-slide"
        ).length;
        this.videosCentered = visibleItems === allItems;
      });

      if (window.innerWidth < 768) {
        this.videoContainerBgHeight = "100%";
        return;
      }

      let height = 0;
      const array = Array.from(
        this.$el.getElementsByClassName("trends-detail__video-teaser")
      );

      array.forEach(item => {
        const testHeight = item.getBoundingClientRect().height + 50;
        if (testHeight > height) height = testHeight;
      });

      this.videoContainerBgHeight = height + "px";
    }
  },
  computed: {
    /*swiper() {
      return this.$refs.swiper[0].swiperInstance;
    },*/
    screenWidth() {
      return this.$screen.width;
    }
  },
  watch: {
    screenWidth() {
      setTimeout(this.calculateSameVideoTeaserHeight, 50);
      //this.calculateSameVideoTeaserHeight();
    },
    trend() {
      this.currentTrend = this.trend;
      if (this.currentTrend) this.fetchData("trends/" + this.currentTrend.slug);
    },
    contentJson() {
      if (!this.trend) {
        this.currentTrend = this.contentJson.slug;
      }

      setTimeout(this.calculateSameVideoTeaserHeight, 50);

      //todo: title management
      //document.title = "UCHRONIA - Trends - "+this.currentTrend.name;
    }
  },
  mounted() {
    if (this.trend) this.currentTrend = this.trend;
    //
    if (this.currentTrend) this.fetchData("trends/" + this.currentTrend.slug);
  }
};
</script>

import gsap from "gsap/gsap-core";
import * as PIXI from "pixi.js";

import AssetHelper from "../helper/assets";
import ZoomObject from "./ZoomObject";

export default class ZoomStage {
  constructor(data) {
    this.data = data;
    this.pixi = new PIXI.Container();
    this.assets = new AssetHelper();
    this.items = [];

    //console.log("ZOOM STAGE CREATED.")
    if (this.loader) {
      //UNLOAD -> LOAD
      this.cancelLoading();
    } else {
      this.setData(data);
    }
  }

  setData(data) {
    this.cancelLoading();

    this.z = -100;
    this.targetZ = 0;
    this.offset = { xPercent: 0, yPercent: 0 };
    this.easedOffset = { xPercent: 0, yPercent: 0 };
    this.loaded = false;
    this.globalAlpha = 1;
    this.currentSpeed = 0;
    this.data = data;
    this.items = [];
    this.liveAsset = null;
    //
    this.init();
    //alles neu initialisieren
  }

  init() {
    this.createRandomFillup();
    this.createSceneAssets();

    const allAssets = this.fillAssets
      .concat(this.spaceAssets)
      .concat(this.bufferAssets);

    if (this.data['overlay']) allAssets.push('/assets/textures/' + this.data['overlay']);

    const data = this.data;

    if(this.loader) this.loader.destroy();
    const loader = new PIXI.Loader();//PIXI.Loader.shared;

    const loaderCreated = false;//this.loader !== undefined;
    this.loader = loader;

    if (
      !loader.resources[
      allAssets[0]
        .split("/")
        .pop()
        .split(".")[0]
      ]
    ) {
      if (!loaderCreated) {
        loader.onProgress.add((e) => {
          window.dispatchEvent(
            new CustomEvent("flight-loading", { detail: e.progress })
          );
        });

        loader.onComplete.add(() => {
          this.loaded = true;
          this.buildScene();
          this.render();

          window.dispatchEvent(new CustomEvent("flight-loaded"));
        });

      }

      this.bufferObjects = [...new Set(allAssets)];

      this.bufferObjects.forEach((el) => {
        loader.add(
          el
            .split("/")
            .pop()
            .split(".")[0],
          el
        );
      });
      //loader.load();

    } else {
      this.loaded = true;
      this.buildScene(data);
      this.render();
    }
  }

  startLoading() {
    this.cancelLoading();
    this.loader.load();
    window.dispatchEvent(new CustomEvent("flight-loading", { detail: 0 }));
  }

  updateTime(minutes, seconds) {
    if (this.minutesField) {
      this.minutesField.text = minutes;
      this.secondsField.text = seconds;
    }
  }

  cancelLoading() {
    this.activeSceneID = undefined;

    const itemLength = this.items.length;
    for (let i = 0; i < itemLength; i++) {
      const el = this.items.pop();

      el.item.destroy();
      el.g.destroy();

      el.g = null;
      el.item = null;
    }

    for (var i = this.pixi.children.length - 1; i >= 0; i--) {
      const child = this.pixi.children[i];
      this.pixi.removeChild(child);
      child.destroy();
    }

    this.items = [];
    this.interactions = [];

    PIXI.Loader.shared.reset();
  }

  createSceneAssets() {
    this.spaceAssets = [];
    this.bufferAssets = [];

    this.data.items.forEach((element) => {
      //BASE ITEM CREATION
      if (element.space_assets) {
        element.space_assets.forEach((img) => {
          this.spaceAssets.push("/assets/textures/" + img.url);
        });
      }
    });

    if (this.data.buffer) {
      this.data.buffer.forEach((element) => {
        //BUFFER ITEM CREATION
        this.bufferAssets.push("/assets/textures/" + element);
      });
    }
  }

  hideItems() {
    this.globalAlpha = 0;
  }

  showItems() {
    this.globalAlpha = 0;
  }

  createRandomFillup() {
    this.fillAssets = [];
    const density = this.data.fill_density || 1;
    let numItems = 172 * density; //TODO: dynamic num = length
    if (window.innerWidth < window.innerHeight) numItems *= 0.5;

    if (this.data.fill_items) {
      for (let i = 0; i < numItems; i++) {
        const rIndex = Math.floor(this.data.fill_items.length * Math.random());
        const path = "/assets/textures/" + this.data.fill_items[rIndex];
        this.fillAssets.push(path);
      }
    } else {
      for (let i = 0; i < numItems; i++) {
        const path = this.assets.getRandomAsset();
        this.fillAssets.push(path);
      }
    }
  }

  setBounds(w, h) {
    
    if (this.overlayLeft) {
      this.overlayLeft.height = h;
      this.overlayRight.height = h;
      this.overlayRight.x = w;

      if (w < 400) {
        this.overlayLeft.visible = false;
        this.overlayRight.visible = false;
      } else {
        this.overlayLeft.visible = true;
        this.overlayRight.visible = true;
      }

    }
  }

  buildScene(data) {
    let sceneID;
    if (data) {
      sceneID = data.id;
    } else if (this.data) {
      sceneID = this.data.id;
    }

    if (sceneID === this.activeSceneID) {
      //console.log("SCENE ALREADY BUILT!!");
      return;
    }

    this.activeSceneID = sceneID;

    //fillup items
    let i = 0;
    let currentDepth = 0;
    this.fullDepth = currentDepth;
    this.flightPercent = 0;

    this.interactions = [];

    if (this.secondsField) {
      this.secondsField.destroy();
      this.minutesField.destroy();
    }

    this.secondsField = undefined;
    this.minutesField = undefined;


    if (!data) data = this.data;


    if (this.data['overlay']) {
      this.overlayRight = PIXI.Sprite.from('/assets/textures/' + this.data['overlay']);
      this.overlayLeft = PIXI.Sprite.from('/assets/textures/' + this.data['overlay']);

      this.overlayRight.scale.set(-1, 1);

      this.pixi.addChild(this.overlayRight);
      this.pixi.addChild(this.overlayLeft);
    } else {
      if (this.overlayRight) {
        this.pixi.removeChild(this.overlayRight);
        this.overlayRight = null;
      }

      if (this.overlayLeft) {
        this.pixi.removeChild(this.overlayLeft);
        this.overlayLeft = null;
      }
    }

    data.items.forEach((element) => {
      //BASE ITEM CREATION

      if (!element.hotspot_only) {


        const item = new PIXI.Sprite();
        const baseObj = new ZoomObject(element);
        const baseGraphics = baseObj.pixi;
        item.addChild(baseGraphics);

        item.interactive = true;
        item.buttonMode = true;

        const x = element.position.x;
        const y = element.position.y;

        i++;

        const vo = {
          index: i,
          xPercent: x,
          yPercent: y,
          z: currentDepth + element.depth * element.position.z,
          start_z: currentDepth,
          item: item,
          g: baseGraphics,
          obj: baseObj,
          skipTransform: true,
          data: element,
          sine: -1
        };

        const hasTouch = document.body.classList.contains("has-touch");
        if (!hasTouch) {
          item.on("mousedown", () => {
            this.onMouseDown(vo.index);
          });
        } else {
          item.on("touchstart", () => {
            this.onMouseDown(vo.index);
          });
        }

        let assetPercent = 0;
        let assetIndex = 0;

        //needed for z sorting
        let subItems = [vo];

        element.space_assets.forEach((asset) => {
          const item = new PIXI.Sprite();

          i++;
          assetIndex++;
          assetPercent = assetIndex / element.space_assets.length;
          //fill up
          const path = "/assets/textures/" + asset.url;

          let graphics = PIXI.Sprite.from(path);
          item.addChild(graphics);

          graphics.anchor.set(0.5);
          let scale = asset.scale;
          if (!scale) scale = 1;

          if (asset.data) {
            this.liveAsset = item;
            //ADDING FONTS
            const style = new PIXI.TextStyle({
              fontFamily: "Nunito Sans",
              /*fill: "#3c4856",*/
              fill: "#3c4856",
              fontSize: 56,
              fontStyle: 'italic',
              padding: 5,
              fontWeight: "bold"
            });

            const text1 = new PIXI.Text("xx mins");
            text1.style = style;

            item.addChild(text1);

            text1.x = 5;
            text1.y = -73;

            const text2 = new PIXI.Text("xx secs");
            text2.style = style;

            item.addChild(text2);

            //text2.x = 440;
            //text2.y = -73;

            text2.x = -541;
            text2.y = -10;

            this.secondsField = text2;
            this.minutesField = text1;
          } else if (asset.clicktarget) {
            item.interactive = true;
            item.buttonMode = true;

            this._debugItem = item;


            graphics.hitArea = new PIXI.Rectangle(20, 20, 100, 200);




            /*         const hasTouch = document.body.classList.contains("has-touch");
                     if (!hasTouch) {
                       item.on("mousedown", () => {
                         this.onClickTarget(asset.clicktarget);
                       });
                     } else {
                       item.on("touchstart", () => {
                         this.onClickTarget(asset.clicktarget);
                       });
                     }*/
          }


          let alpha = asset.alpha;
          if (!alpha) alpha = 1;

          graphics.scale.set(scale);
          graphics.alpha = alpha;



          let targetZ = currentDepth + element.depth * assetPercent;
          if (asset.position.z)
            targetZ = currentDepth + element.depth * asset.position.z;

          const vo = {
            xPercent: asset.position.x,
            yPercent: asset.position.y,
            z: targetZ,
            item: item,
            g: graphics,
            skipTransform: true,
            data: asset,
            sine: -1
          };

          if (!asset.link) {
            graphics.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
            item.hitArea = new PIXI.Rectangle(0, 0, 0, 0);

          } else {
            item.interactive = true;
            item.buttonMode = true;

            vo.data.id = asset.link

            if (!hasTouch) {
              item.on("mousedown", () => {
                this.onMouseDown(-1, vo);
              });
            } else {
              item.on("touchstart", () => {
                this.onMouseDown(-1, vo);
              });
            }
          }


          subItems.push(vo);

          //this.items.push(vo);
          if (assetPercent === 1) currentDepth += element.depth;
        });

        subItems = subItems.sort(function (a, b) {
          return a.z - b.z;
        });

        subItems.forEach((vo) => {
          this.items.push(vo);
        });

        //possible interaction
        // console.log(element.interaction)
        if (element.interaction) {
          const inZ = vo.start_z + element.interaction.in * element.depth;
          const outZ = vo.start_z + element.interaction.out * element.depth;

          const interaction = {
            in: inZ,
            out: outZ,
            id: element.interaction.id,
          };

          this.interactions.push(interaction);
        }
      }
    });

    //let numItems = Math.floor(currentDepth*(window.innerWidth*0.0001));
    //if(numItems > 200) numItems = 200;
    const numItems = this.fillAssets.length;
    //FILL UP ITEMS
    for (let i = 0; i < numItems; i++) {
      const item = new PIXI.Sprite();

      let graphics;
      let obj;

      //const rIndex = 1 + Math.floor(Math.random() * 7);
      const path = this.fillAssets[i]; //"assets/textures/" + "clouds-" + rIndex + ".png";
      graphics = PIXI.Sprite.from(path);
      graphics.anchor.set(0.5);
      
      graphics.rotation = Math.random() * 360;
      
      graphics.scale.set(0.6 + Math.random() * 0.4);
      graphics.alpha = 0.5 + Math.random() * 0.2;

      //CHAPTER 2
      if (this.data.id === 1) {
        graphics.scale.set(15 + Math.random() * 1);
        graphics.alpha = 0.5 + Math.random() * 0.2;
      }
      
      if (this.data.id === 2) {
        graphics.scale.set(1 + Math.random() * .5);
        graphics.rotation = 0;
      }

      graphics.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
      item.hitArea = new PIXI.Rectangle(0, 0, 0, 0);

      item.addChild(graphics);

      const percent = i / (numItems - 1);

      let spreadPercent = 1;
      if (!item.interactive) {
        spreadPercent = 0.05;
      }

      let x = Math.random() * spreadPercent;
      let y = Math.random() * spreadPercent;

      if (item.interactive) {
        x = 0.4 + Math.random() * 0.2;
        y = 0.4 + Math.random() * 0.2;
      } else {
        if (Math.random() > 0.5) x = 1 - x;
        if (Math.random() > 0.5) y = 1 - y;
      }

      if (this.data.id === 1) {
        //NEW BOOK!

        x = -1 + Math.random() * 2;
        y = -Math.random() * .5;

        graphics.rotation = 0;
        graphics.alpha = 1;
        graphics.scale.set(1 + Math.random() * 0.4);

        if (Math.random() > 0.5) y = 1 + Math.random() * .5;
      }

      const vo = {
        xPercent: x,
        yPercent: y,
        z: currentDepth * percent,
        item: item,
        g: graphics,
        obj: obj,
        fillUp: true,
        sine: -1
      };

      if (this.data.id === 1) {
        vo.skipTransform = true;
      }

      if (this.data.id === 2) {
        
        if (path.indexOf("feder") > 0) {
          vo.skipTransform = false;
          vo.sine = 1;
        }else{
          //letters
          vo.skipTransform = true;
        }
      }

      this.items.push(vo);
      this.pixi.addChild(item);
    }

    const zArray = this.items.reverse();

    zArray.forEach((element) => {
      if (!element.fillUp) this.pixi.addChild(element.item);
    });

    this.fullDepth = currentDepth;
  }

  onZoomComplete() {
    window.dispatchEvent(
      new CustomEvent("interaction-activate", { detail: this.clicked.data.id })
    );
  }

  /* custom click events */
  onClickTarget(target) {
    if (target === "next_chapter") {
      /*const chapterNames = this.$store.getters.chapters;
      this.$store.commit("set_hash", chapterNames[1]);*/
    }
  }

  onMouseDown(i, item) {
    let clicked = this.items.find((x) => x.index === i);
    if (item) clicked = item;


    if (this.clicked === clicked) return;
    if (this.clicked) this.clearForceOffset();

    clicked.interactive = false;


    this.clicked = clicked;

    let isNext = false;
    if (this.clicked.data) isNext = this.clicked.data.id === "next" || this.clicked.data.id === "next-03";

    if (isNext) {
      window.dispatchEvent(
        new CustomEvent("interaction-activate", { detail: this.clicked.data.id })
      );

      this.clicked = undefined;
    } else {
      this.zoomTween = gsap.to(this, {
        ease: "Sine.easeOut",
        duration: 0.5,
        targetZ: clicked.z + 10,
        onComplete: () => {
          this.onZoomComplete();
        },
      });

      gsap.to(this.clicked.g.scale, {
        ease: "Sine.easeOut",
        duration: 0.3,
        x: 3,
        y: 3,
      });

      const x = 1 - clicked.xPercent * 2;
      const y = 1 - clicked.yPercent * 2;

      this.forceOffset = { xPercent: x, yPercent: y };

      gsap.to(this.easedOffset, {
        ease: "Sine.easeOut",
        duration: 1.5,
        xPercent: x,
        yPercent: y,
      });
    }
  }

  clearForceOffset() {
    if (this.clicked) {
      if (this.clicked.g) {

        gsap.to(this.clicked.g.scale, {
          ease: "Expo.easeOut",
          duration: 0.7,
          x: 1,
          y: 1,
        });

        this.clicked.g.tint = 0xffffff;
      }
      this.clicked.interactive = true;
      this.clicked = null;

      this.zoomTween.kill();
      this.zoomTween = null;
    }
    this.forceOffset = null;
  }

  setMouse(mouse) {
    if (!this.offset) return;
    this.offset.xPercent = (1 - 2 * mouse.xPercent) * 0.25;
    this.offset.yPercent = (1 - 2 * mouse.yPercent) * 0.25;
  }

  render() {
    if (!this.loaded) return;

    if (this.targetZ < 0) this.targetZ = 0;
    if (this.targetZ > this.fullDepth) {
      this.targetZ = this.fullDepth;

      window.dispatchEvent(
        new CustomEvent("journey-end")
      );
    }

    this.flightPercent = this.targetZ / this.fullDepth;
    //calulacting active id
    let activeInteraction;
    for (let i = 0; i < this.interactions.length; i++) {
      const interaction = this.interactions[i];
      if (this.targetZ >= interaction.in && this.targetZ <= interaction.out) {
        activeInteraction = interaction;
        break;
      }
    }

    if (activeInteraction) {
      if (
        this.lastInteraction !== activeInteraction &&
        this.lastInteraction !== undefined
      ) {
        window.dispatchEvent(
          new CustomEvent("interaction-hide", { detail: this.lastInteraction })
        );
        this.lastInteraction = undefined;
      }

      this.lastInteraction = activeInteraction;

      this.interactionProgress =
        (this.targetZ - activeInteraction.in) /
        (activeInteraction.out - activeInteraction.in);

      let detail = {
        detail: {
          progress: this.interactionProgress,
          id: activeInteraction.id,
        },
      };

      window.dispatchEvent(new CustomEvent("interaction", detail));
    } else if (this.lastInteraction) {
      //hide last interactvion
      window.dispatchEvent(
        new CustomEvent("interaction-hide", { detail: this.lastInteraction })
      );
      this.lastInteraction = undefined;
    }
    //
    this.oldZ = this.z;


    this.z -= (this.z - this.targetZ) * 0.05;
    this.currentSpeed = this.z - this.oldZ;


    const centerX = window.innerWidth * 0.5;
    const centerY = window.innerHeight * 0.5;

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const maxLife = 100;

    if (this.forceOffset) {
      //item clicked
      //this.easedOffset.xPercent -= (this.easedOffset.xPercent-(this.forceOffset.xPercent))*.25;
      //this.easedOffset.yPercent -= (this.easedOffset.yPercent-(this.forceOffset.yPercent))*.25;
    } else {
      this.easedOffset.xPercent -=
        (this.easedOffset.xPercent - this.offset.xPercent) * 0.01;
      this.easedOffset.yPercent -=
        (this.easedOffset.yPercent - this.offset.yPercent) * 0.01;
    }

    let maxCamOffset = { x: window.innerWidth, y: window.innerHeight };
    if (!this.forceOffset) {
      //maxCamOffset.x = 1000;
      //maxCamOffset.y = 500;
    }



    this.items.forEach((b) => {
      //visible
      let scale = (b.z - this.z) / maxLife;
      scale = 1 - scale;

      if (scale > 0) {
        const expoScale = Math.pow(scale, 5);
        b.item.scale.set(expoScale);

        const widthInSpace = viewportWidth * expoScale;
        const heightInSpace = viewportHeight * expoScale;
        const XOffsetInSpace = widthInSpace * 2 * b.xPercent;
        const YOffsetInSpace = heightInSpace * 2 * b.yPercent;

        b.item.x =
          centerX -
          (widthInSpace - XOffsetInSpace) +
          this.easedOffset.xPercent * maxCamOffset.x * expoScale;
        b.item.y =
          centerY -
          (heightInSpace - YOffsetInSpace) +
          this.easedOffset.yPercent * maxCamOffset.y * expoScale;

        let alpha = 1;

        if (scale > 1) {
          alpha = 1 - (scale - 1) / 0.1;
        } else if (scale < 0.75) {
          alpha = scale / 0.75;
        }

        /*if (!b.interactive && !b.skipTransform)
          b.item.rotation += (-1 + 2 * b.xPercent) * 0.001 * scale;

          */


          if(b.sine !== -1) {
            const sin = Math.sin(b.sine)*0.01;
            b.sine += 0.01;

            b.item.rotation = -30+60*sin;
          }else if (!b.interactive && !b.skipTransform) {
            b.item.rotation += (-1 + 2 * b.xPercent) * 0.001 * scale;
          }

        if (alpha > 0) {
          if (b.obj) b.obj.render(alpha, expoScale);
          b.item.alpha = alpha;
          b.item.interactive = true;
          //b.item.renderable = true;
        } else {
          b.item.interactive = false;
          //b.item.renderable = false;
          b.item.alpha = 0;
        }
      } else {
        b.item.scale.set(0);
        //b.item.renderable = false;
        b.item.interactive = false;
      }
    });

    if (this._debugItem) {
      //console.log(this._debugItem.interactive);
      //console.log(this._debugItem.buttonMode);
    }
  }
}

<template>
  <div class="room">
    <div id="container" class="room__component">
      <iframe
        ref="iframe"
        @load="onFrameLoad"
        :src="getSource()"
        frameBorder="0"
        scrolling="no"
        style="border: 0"
      ></iframe>
    </div>

    <div class="room__overlays">
      <h1>{{ roomName }}</h1>
    </div>
  </div>
</template>

<script>
import DataMixin from "@/mixins/DataMixin";
export default {
  name: "RoomComponent",
  components: {},
  mixins: [DataMixin],
  data() {
    return {
      panoInstance: undefined,
      interval: undefined,
      roomLabel: undefined
    };
  },

  props: {
    room: undefined,
    roomName: undefined
  },
  methods: {
    getSource() {
      const host = window.location.protocol + "//" + window.location.host;
      return host + "/layama/" + this.room + "/";
    },
    checkLocation() {
      if(!this.room) {
        clearInterval(this.interval);
        this.interval = undefined;

        return;
      }

      //LINKS IN SCENE.JS CHECKEN!
      if (this.$refs.iframe.contentWindow.location.href) {
        const correctLocation = this.getSource();
        if (correctLocation !== this.$refs.iframe.contentWindow.location.href) {
          const p = this.$refs.iframe.contentWindow.location.href.split("/");
          const contentSlug = p.pop();
          const pageSlug = p.pop();
          //new path
          const target = "/" + pageSlug.split("#").join("") + "/" + contentSlug;
          
          clearInterval(this.interval);
          this.interval = undefined;
          
          this.$router.push(target);
        }
      }
    },
    onFrameLoad() {
      clearInterval(this.interval);
      
      this.interval = setInterval(() => {
        this.checkLocation();
      }, 250);
    }
  },
  computed: {
    stageScale() {
      return this.$screen.lastChange;
    }
  },
  watch: {
    roomLabel() {},
    stageScale() {},

    contentJson() {},
    room() {
      this.activeRoom = this.room;
      this.roomLabel = this.room.name;
      //this.fetchData("rooms/" + this.room, true);
    }
  },
  beforeDestroy() {
    document.documentElement.classList.remove("no-overflow");

    if (this.panoInstance) {
      this.panoInstance = null;
    }
  },
  destroyed() {
    clearInterval(this.interval);
    this.interval = undefined;
  },
  mounted() {
    if (this.room) {
      //this.fetchData("rooms/" + this.room, true);
      //this.roomLabel = this.room.name;
      //console.log("*****",this.room.name);
    }

    document.documentElement.classList.add("no-overflow");

    /*this.interval = setInterval(() => {
      this.checkLocation();
    }, 100);*/
  }
};
</script>

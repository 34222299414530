<template>
  <div class="header">
    <div class="header__container">
      <div class="header__logo" v-show="!modal.visible" @click="onLogoClick">
        <inline-svg :src="require('../../assets/svg/logo.svg')" />
      </div>

      <div class="header__backbutton" v-if="backlink.visible" @click="onBackToJourney">
        BACK TO JOURNEY
      </div>

      <div class="header__buttons" v-if="!modal.visible && eventState !== 'prelive'">
        <div ref="share_button" class="header__button header__button--share" @click="onShareClick">
          <inline-svg :src="require('../../assets/svg/icon_share.svg')" />
        </div>

        <div class="header__button" @click="$router.push('/user/profile')">
          <inline-svg :src="require('../../assets/svg/icon_heart.svg')" />
        </div>
      </div>

      <div class="header__modal-controls" v-if="modal.visible">
        <div class="header__modal-controls-back" @click="onBackClick">
          <inline-svg :src="require('../../assets/svg/icon_backarrow.svg')" />
        </div>
        <div class="header__modal-controls-cross" @click="onCloseClick">
          <inline-svg :src="require('../../assets/svg/icon_close.svg')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  components: {},
  name: "Header",
  data() {
    return {
      backlink: {
        visible: true,
        label: "zurück zu journey"
      },
      modal: {
        visible: false
      },
      shareable: false,
      shareable_intro: undefined
    };
  },
  methods: {
    onBackToJourney() {
      const isActive = this.$store.getters.journeyActive;
      const isStarted = this.$store.getters.journeyStarted;

      if (isStarted) {
        this.$store.commit("set_restart_journey", isActive);
        this.$store.commit("set_journey_active", true);
        this.$store.commit("set_journey_started", true);

        const chapterID = this.$store.getters.chapters[this.$store.getters.activeChapter];
        this.$router.push("/" + chapterID);
      } else {
        this.$store.commit("set_journey_active", false);
        this.$store.commit("set_journey_started", false);

        this.$router.push("/");
      }
    },
    onShareClick() {
      this.$store.commit("set_share_target", true);
    },
    onLogoutClick() {
      this.$store.dispatch("logout");
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }

      this.$store.commit("set_journey_active", false);
      this.$store.commit("set_journey_started", false);
    },
    onLogoClick() {
      if(this.eventState === "prelive") return;

      if (this.$route.query.share) {
        this.$router.push("/");
        this.$store.commit("set_shared", undefined);
        return;
      }

      this.$store.commit("set_active_chapter", undefined);
      this.$store.commit("set_journey_started", false);
      this.$store.commit("set_journey_active", false);


      if (this.$route.hash !== "#chapters") {
        this.$router.push("/#chapters");
      }
    },
    onCloseClick() {
      const active = this.$store.getters.chapters[this.$store.getters.activeChapter];
      
      if (this.$route.params.decor) {
        this.$router.push("/decors"+active);
      } else if (this.$route.params.trend) {
        this.$router.push("/trends"+active);
      } else if (this.$route.params.room) {
        this.$router.push("/rooms"+active);
      } else {
        this.onBackClick();
      }
    },
    onBackClick() {
      if (this.$store.getters.history.length > 1) {
        this.$router.back();
      } else {
        const currentPath = this.$store.getters.history[
          this.$store.getters.history.length - 1
        ].path.split("/")[1];
        if (currentPath === "user") {
          this.$router.push("/");
        } else {
          this.$router.push("/" + currentPath);
        }
      }
    },
    onConsultingClick() {
      //
      if (document.getElementById("ButtonHandler"))
        document.getElementById("ButtonHandler").click();
    },
    checkRoutes() {
      const isSubPath = this.$route.path.split("/").length === 3;
      if (this.$route.path === "/") {
        this.backlink.visible = false;
        this.modal.visible = false;
      } else if (isSubPath) {
        this.backlink.visible = false;
        this.modal.visible = true;
      } else if (this.$route.path === "/videos") {
        this.backlink.visible = false;
        this.modal.visible = true;
      } else {
        this.backlink.visible = true;
        this.modal.visible = false;
      }

      if (this.$route.query.interaction) {
        this.shareable = true;
      } else {
        this.shareable = false;
      }

      //if(!this.$store.getters.journeyStarted) this.backlink.visible = false;
    }
  },
  watch: {
    shareable() {
      this.$nextTick(() => {
        if (this.shareable) {
          if (this.shareable_intro === undefined) {
            this.shareable_intro = true;

            const toast = {
              label: "Share this interaction",
              body: "You can share this interaction with your friends & colleagues. Click on the icon in the upper right corner.",
              type: "info",
              life: "5"
            };

            this.$store.getters.toastManager.add(toast);
          }

          gsap.to(this.$refs.share_button, { duration: .2, autoAlpha: 1 });
          gsap.to(this.$refs.share_button, { ease: "Expo.easeOut", scale: 1 });
        } else {
          gsap.to(this.$refs.share_button, { duration: .2, autoAlpha: 0 });
          gsap.to(this.$refs.share_button, { duration: .2, ease: "Expo.easeOut", scale: .5 });
        }
      })
    },
    $route() {
      this.checkRoutes();
    }
  },
  computed: {
    eventState() {
      return this.$store.getters.eventState;
    },
    loggedIn() {
      return this.$store.getters.getLoggedIn;
    }
  },
  mounted() {
    this.checkRoutes();
  }
};
</script>

<template>
  <div
    class="float-cluster"
    :class="{ ['float-cluster--style' + pointStyle]: true }"
  >
    <FloatClusterItem
      :position="currentStyle.points[index]"
      v-for="(item, index) in teaserItems"
      :key="index"
      :content="item"
      @click.native="onItemClick(item.slug)"
    />

    <div
      class="float-cluster__item float-cluster__item--headline"
      :style="{
        left: currentStyle.headline.x + '%',
        top: currentStyle.headline.y + '%',
        width: currentStyle.headline.width + '%',
        height: currentStyle.headline.height + '%',
      }"
    >
      <h1 class="headline-1">{{ contentJson.headline }}</h1>
      <h2>{{ contentJson.subline }}</h2>
    </div>
  </div>
</template>

<script>
import FloatClusterItem from "./FloatClusterItem";

export default {
  name: "FloatCluster",
  components: { FloatClusterItem },
  props: {
    
    contentJson: {
      default: () => {
        return {
          headline: "",
          subline: ""
        };
      }
    },
    pointStyle: {
      default: 0
    } //different points
  },
  data() {
    return {
      style5Points:{
          points: [
            { x: 20, y: 35, width: 18, height: 18 },
            { x: 50, y: 28, width: 18, height: 18 },
            { x: 77, y: 48, width: 18, height: 18 },
            { x: 25, y: 75, width: 18, height: 18 },
            { x: 55, y: 78, width: 18, height: 18 },
            { x: 82.1, y: 65, width: 18, height: 18 }
          ],
          
          headline: {
            x: 56,
            y: 56,
            width: 20.8,
            height: 12.5
          }
        },
      styles: [
        {
          points: [
            { x: 15, y: 43, width: 18, height: 10 },
            { x: 55, y: 0, width: 18, height: 18 },
            { x: 77, y: 27, width: 18, height: 18 },
            { x: 36.6, y: 74.7, width: 18, height: 18 },
            { x: 60.1, y: 66.7, width: 18, height: 18 },
            { x: 82.1, y: 49.4, width: 18, height: 18 }
          ],
          headline: {
            x: 52,
            y: 48,
            width: 20.8,
            height: 12.5
          }
        },
        {
          points: [
            { x: 20, y: 35, width: 18, height: 18 },
            { x: 48, y: 28, width: 18, height: 18 },
            { x: 75, y: 27, width: 18, height: 18 },
            { x: 25, y: 75, width: 18, height: 18 },
            { x: 55, y: 78, width: 18, height: 18 },
            { x: 82.1, y: 65, width: 18, height: 18 }
          ],
          
          headline: {
            x: 56,
            y: 56,
            width: 20.8,
            height: 12.5
          }
        }
      ]
    };
  },
  computed:{
    currentStyle(){
      if(!this.teaserItems) return this.styles[this.pointStyle];
       return (this.teaserItems.length !== 5 ? this.styles[this.pointStyle] : this.style5Points);
    },
    teaserItems(){
      const items = this.contentJson[this.contentJson.slug];
      return items;
    }
  },
  methods: {
    onItemClick(slug){
      this.$emit('click',slug);
    },
  },
  beforeDestroy() {},
  mounted() {
   
  }
};
</script>

<template>
  <div class="meta-layer">
    <div class="meta-layer__container" v-if="content">
      <div class="meta-layer__bg"></div>
      <div class="meta-layer__content" ref="content">
        <div class="meta-layer__close" @click="onCloseClick">
          <inline-svg :src="require('../../assets/svg/icon_close.svg')" />
        </div>

        <h1 v-html="this.content.headline"></h1>
        <div v-html="this.content.body"></div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import Footer from "@/components/meta/Footer.vue";
import DataMixin from "@/mixins/DataMixin.js";

export default {
  name: "MetaLayer",
  mixins: [DataMixin],
  components: {
    Footer
  },
  data() {
    return {
      content: undefined
    };
  },
  methods: {
    fadeContents() {
      this.$nextTick(() => {
        if (this.$refs.content) {
          gsap.set(this.$el, { autoAlpha: 0 });
          gsap.to(this.$el, { duration: 0.25, autoAlpha: 1 });

          gsap.set(this.$refs.content, { y: 50, alpha: 0 });
          gsap.to(this.$refs.content, {
            delay: 0.25,
            y: 0,
            duration: 0.6,
            alpha: 1
          });
        }
      });
    },
    onCloseClick() {
      //TODO: GLOBAL MACHEN UND FIXEN
      this.$store.commit("set_meta_layer", undefined);
      this.content = undefined;

      /*
      const history = this.$store.getters.history.slice();
      const cleanedHistory = [];
      for (var i = 0; i < history.length; i++) {
        const item = history[i];
        if (
          item.path !== "/imprint" &&
          item.path !== "/terms-conditions" &&
          item.path !== "/privacy-policy"
        )
          cleanedHistory.push(item);
      }

      if (this.route.hash) {
        this.$router.push(this.$route.path);
      } else {
        if (this.route.path !== "/") {
          if (!cleanedHistory.length) {
            this.$router.push("/");
          } else {
            this.$router.push(cleanedHistory.pop().path);
          }
        }
      }*/
    }
  },
  computed: {
    route() {
      return this.$route;
    },
    active() {
      return this.$store.getters.getMetaLayerID;
    }
  },
  watch: {
    route() {
      if (this.contentJson) {
        const path = this.route.path.split("/")[1].replace("-", "_");
        if (this.contentJson.contents[path]) {
          this.$store.commit("set_meta_layer", path);
        } else {
          this.$store.commit("set_meta_layer", undefined);
        }
      }
    },
    contentJson() {
      if (this.active) {
        this.content = this.contentJson.contents[this.active];
        //console.log("****", this.active);
        //console.log("****", this.contentJson.contents);
        gsap.set("body", { css: { overflow: "hidden" } });
      } else {
        gsap.set("body", { css: { overflow: "auto" } });
      }
    },
    active() {
      this.content = this.contentJson.contents[this.active];
    },
    content() {
      this.$el.scrollTo(0, 0);
      this.fadeContents();
    }
  },
  beforeDestroy() {
    gsap.set("body", { css: { overflow: "auto" } });
  },
  mounted() {
    this.fetchData("meta");
  }
};
</script>

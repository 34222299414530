<template>
  <div class="decors-detail" v-if="contentJson && activeDecor">
    <div class="decors-detail__container">
      <h1>{{ activeDecor.name }}</h1>

      <div class="decors-detail__layout" ref="layout">
        <div class="decors-detail__images">
          <div class="decors-detail__images-wrapper" ref="detail_images">
            <div class="decors-detail__images-mask">
              <img :src="item.img" v-for="(item, index) in this.contentJson.sub_decors" :key="index"
                :class="{ active: active === index }" @click="openLightbox" />
            </div>

            <div class="decor-detail__image-controls" v-if="this.contentJson.sub_decors.length > 1">
              <div @click="onPrevClick" class="decor-detail__image-image-control">
                <inline-svg :src="require('../../assets/svg/icon_page-arrow.svg')" />
              </div>
              <div class="decor-detail__image-controls-pagination">
                <ul>
                  <li v-for="(item, index) in this.contentJson.sub_decors" :key="index"></li>
                </ul>
              </div>
              <div @click="onNextClick" class="
                  decor-detail__image-image-control
                  decor-detail__image-image-control--next
                ">
                <inline-svg :src="require('../../assets/svg/icon_page-arrow.svg')" />
              </div>
            </div>

            <div class="decors-detail__info">
              <div class="decors-detail__info-label">
                <h1>Decor</h1>
                <!--{{activeDecor}}-->
                <h2>{{ activeDecor.id }} {{ activeDecor.name }}</h2>
              </div>

              <div class="decors-detail__info-label" v-if="this.contentJson.sub_decors.length > 1">
                <h1>Color</h1>
                <h2 ref="color_headline">
                  {{ this.contentJson.sub_decors[active].sub_id }}
                </h2>
              </div>
              <div class="decors-detail__info-button-container">
                <Button class="button--small" @click.native="updateDecor" :class="{ 'button--dimmed': isAlreadyFav }">
                  <inline-svg :src="require('../../assets/svg/icon_heart.svg')" />
                  <template v-if="!isAlreadyFav">LIKE</template>
                  <template v-else>UNLIKE</template>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div class="decor-detail__colors">
          <ColorModule :colors="this.contentJson.colors" />
        </div>

        <div class="decor-detail__copy">
          <p>{{ this.contentJson.sub_decors[active].copy }}</p>
        </div>

        <div class="decor-detail__room-link" @click="onRoomClick" v-if="this.contentJson.room_link">
          <img :src="this.contentJson.room_img" />
          <span>
            <inline-svg :src="require('../../assets/svg/icon_request.svg')" />
            ROOMWALK
          </span>
        </div>
      </div>
      <DecorsSlider :items="allItems" :active="activeDecor" />
    </div>
  </div>
</template>

<script>
import ColorModule from "@/components/ColorModule";
import Button from "@/components/form/Button";
import DataMixin from "@/mixins/DataMixin.js";
import gsap from "gsap";
import DecorsSlider from "./DecorsSlider.vue";
export default {
  name: "DecorsDetail",
  mixins: [DataMixin],
  components: {
    Button,
    ColorModule,
    DecorsSlider
  },
  props: {
    decor: undefined,
    allItems: undefined
  },
  data() {
    return {
      active: 0,
      activeDecor: undefined,
      isAlreadyFav: undefined
    };
  },
  methods: {
    updateImages() {
      this.$nextTick(() => {
        const images = this.$refs.detail_images.getElementsByTagName("img");
        const active = this.$refs.detail_images.getElementsByClassName(
          "active"
        );

        const len = images.length;
        for (var i = 0; i < images.length; i++) {
          let xOff = 5 * (len - i);
          let yOff = -5 * (len - i);

          gsap.to(images[i], {
            x: xOff,
            y: yOff,
            autoAlpha: 0.5,
            duration: 0.7
          });
        }

        gsap.to(active, { x: 0, y: 0, autoAlpha: 1, duration: 0.7 });

        if (this.$refs.color_headline) {
          gsap.set(this.$refs.color_headline, { autoAlpha: 0 });
          gsap.to(this.$refs.color_headline, {
            x: 0,
            autoAlpha: 1,
            duration: 0.5
          });
        }
      });
    },
    openLightbox() {
      const active = this.contentJson.sub_decors[this.active];
      let image = active.img;
      let zoom;

      if (active.img_lightbox) image = active.img_lightbox;
      if (active.img_zoom) zoom = active.img_zoom;

      const content = {
        headline: this.activeDecor.name,
        image: image,
        zoom: zoom
      };

      this.$store.commit("set_lightbox_content", content);
    },
    checkButtonState() {
      if (!this.contentJson) return;

      if (this.favorites) {
        const currentFavID =
          this.activeDecor.id;

        this.isAlreadyFav = this.favorites.includes(currentFavID);
      } else {
        this.isAlreadyFav = false; //disabled
      }
    },
    updateDecor() {
      if (this.isAlreadyFav) {
        console.log("REMOVING!");
        this.removeDecor();
        this.isAlreadyFav = false;
      } else {
        console.log("ADDING!");
        this.saveDecor();
        this.isAlreadyFav = true;
      }
    },
    saveDecor() {
      let id = this.activeDecor.id;
      //const subid = this.contentJson.sub_decors[this.active].sub_id;

      //if (subid) id += "_" + subid;

      const payload = {
        action: "add",
        decore: id
      };

      this.$store.dispatch("update_favorites", payload);

      console.log("ADDING FAV");
    },

    removeDecor() {
      let id = this.activeDecor.id;
      //const subid = this.contentJson.sub_decors[this.active].sub_id;
      //if (subid) id += "_" + subid;

      const payload = {
        action: "remove",
        decore: id
      };

      this.$store.dispatch("update_favorites", payload);

      console.log("REMOVING FAV");
    },

    onRoomClick() {
      const link = this.contentJson.room_link.replace('_III','');
      this.$router.push(link);
    },
    onPrevClick() {
      let active = this.active - 1;
      if (active < 0) active = this.subItemLength - 1;
      this.active = active;
    },
    onNextClick() {
      let active = this.active + 1;
      if (active > this.subItemLength - 1) active = 0;
      this.active = active;
    }
  },
  computed: {
    favorites() {
      return this.$store.getters.favorites;
    },
    subItemLength() {
      return this.contentJson.sub_decors.length;
    },
    response() {
      return JSON.parse(JSON.stringify(this.$store.state.user.restResponse));
    }
  },
  watch: {
    favorites() {
    },
    $route() {
      gsap.set(this.$refs.layout, { autoAlpha: 0 });
      gsap.to(this.$refs.layout, { autoAlpha: 1, delay: 0.5 });
    },
    activeDecor() {
      this.checkButtonState();
    },
    response() {
      if (this.response.favorite_decores) {
        //this.favorites = this.response.favorite_decores;
        this.checkButtonState();
      } else {
        //this.favorites = [];
      }

      //
    },
    active() {
      this.checkButtonState();
      this.updateImages();
    },

    decor() {
      this.activeDecor = this.decor;
      this.fetchData("decors/" + this.decor.slug);
    },
    contentJson() {
      this.checkButtonState();
      this.updateImages();

      this.$nextTick(() => {
        gsap.from(this.$el, { autoAlpha: 0 });
      });
    }
  },
  mounted() {
    if (this.decor) {
      this.activeDecor = this.decor;
      this.fetchData("decors/" + this.decor.slug);
    }
  }
};
</script>

import gsap from "gsap";
import * as PIXI from "pixi.js";

export default class Orb {
  pixi;

  constructor(color, isBackground) {
    this.pixi = new PIXI.Container();
    this.pixi2 = new PIXI.Container();

    this.color = this.hex2RGB(color.replace("0x", "#"));
    this.isBackground = isBackground;

    this.updateGraphics();
  }

  drawOrb() {
    const canvas = document.createElement("canvas");
    canvas.width = 100;
    canvas.height = 100;

    const ctx = canvas.getContext("2d");

    var gradient = ctx.createRadialGradient(50, 50, 0, 50, 50, 50);
    // Add three color stops
    gradient.addColorStop(
      0,
      "rgba(" + `${this.color.r},${this.color.g}, ${this.color.b}` + ",1)"
    );
    gradient.addColorStop(
      1,
      "rgba(" + `${this.color.r},${this.color.g}, ${this.color.b}` + ",0)"
    );

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, 100, 100);

    const texture = PIXI.Texture.from(canvas);
    const sprite = new PIXI.Sprite(texture);
    this.pixi.addChild(sprite);
    this.fadeSprite(sprite, this.sprite, this.pixi);

    this.sprite = sprite;
    sprite.x = -50;
    sprite.y = -50;
  }

  fadeSprite(newSprite, oldSprite, container) {
    gsap.from(newSprite, { overwrite: true, delay: Math.random(), duration: 1, alpha: 0 });

    if (oldSprite) {
      gsap.to(oldSprite, { overwrite: true, delay: Math.random(), duration: 1, alpha: 0 }).then(() => {
        if (oldSprite) {
          container.removeChild(oldSprite);
          oldSprite.destroy();
        }
      });
    }
  }

  updateGraphics() {
    if (this.isBackground) {
      this.drawBackground();
    } else {
      this.drawOrb();
    }
  }

  hex2RGB(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null;
  }

  drawBackground() {

    const canvas2 = document.createElement("canvas");
    const ctx = canvas2.getContext("2d");
    canvas2.width = 100;
    canvas2.height = 100;

    const ctx2 = canvas2.getContext("2d");

    var gradient = ctx.createRadialGradient(50, 50, 0, 50, 50, 50);

    // Add three color stops
    gradient.addColorStop(
      0,
      "rgba(" + `${this.color.r},${this.color.g}, ${this.color.b}` + ",1)"
    );
    gradient.addColorStop(
      1,
      "rgba(" + `${this.color.r},${this.color.g}, ${this.color.b}` + ",0)"
    );


    //VIGNETTE
    gradient = ctx2.createRadialGradient(50, 50, 100, 50, 50, 50);
    // Add three color stops
    gradient.addColorStop(
      0,
      "rgba(" + `${this.color.r},${this.color.g}, ${this.color.b}` + ",1)"
    );

    gradient.addColorStop(
      1,
      "rgba(" + `${this.color.r},${this.color.g}, ${this.color.b}` + ",0)"
    );

    ctx2.fillStyle = gradient;
    ctx2.fillRect(0, 0, 100, 100);

    const texture2 = PIXI.Texture.from(canvas2);

    if (this.sprite2) {
      //this.pixi.removeChild(this.sprite2);
    }

    const sprite2 = new PIXI.Sprite(texture2);
    this.pixi2.addChild(sprite2);
    this.fadeSprite(sprite2, this.sprite2, this.pixi2);

    this.sprite2 = sprite2;
    sprite2.x = 0;
    sprite2.y = 0;
  }

  setColor(hex) {
    this.color = this.hex2RGB(hex.replace("0x", "#"));
    this.updateGraphics();
  }
}

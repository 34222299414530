<template>
  <div class="content-card">
    <div ref="card" class="content-card__container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "ContentCard",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    show() {
      
      gsap.set(this.$refs.card, { left: "-2.5%", alpha: 0,overwrite:true });

      gsap.to(this.$refs.card, { delay: 0.15, duration: 0.25, alpha: 1 });
      gsap.to(this.$refs.card, {
        delay: 0.15,
        left: 0,
        duration: 0.75,
        ease: "Expo.easeOut",
        clearProps: "all"
      });
    }
  },
  beforeDestroy() {},
  mounted() {
    this.show();
  }
};
</script>

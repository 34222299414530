<template>
  <div class="pixi-stage__interaction" v-if="activeInteraction">
    <PixiInteraction
      v-if="activeInteraction == 'kaleidoscope' && introHidden"
    ></PixiInteraction>

    <DataInteraction
      v-else-if="activeInteraction == 'lifetime-calculator' && introHidden"
    ></DataInteraction>

    <DecorInteraction
      v-else-if="activeInteraction == 'mood-mesher' && introHidden"
    ></DecorInteraction>

    <LightsInteraction
      v-else-if="activeInteraction == 'impressions-of-a-moment' && introHidden"
      @exit="hideInteraction"
    />

    <BucketlistInteraction
      v-else-if="activeInteraction == 'bucketlist' && introHidden"
      @exit="hideInteraction"
    />

    <MindgameInteraction
      @exit="hideInteraction"
      v-else-if="activeInteraction == 'mindgame' && introHidden"
      >{{ activeInteraction }}</MindgameInteraction
    >

    <MemoInteraction
      @exit="hideInteraction"
      v-else-if="activeInteraction == 'memo' && introHidden"
    />
    <FashionInteraction
      @exit="hideInteraction"
      :interactionData="interactionData"
      v-else-if="activeInteraction == 'fashion' && introHidden"
    />
    <InventionsInteraction
      @exit="hideInteraction"
      :interactionData="interactionData"
      v-else-if="activeInteraction == 'magazine' && introHidden"
    />
    <MeditationInteraction
      @exit="hideInteraction"
      v-else-if="activeInteraction == 'relax' && introHidden"
    />
    <InteractionIntro
      v-if="introContent"
      :content="introContent"
      @hiding="onIntroHiding"
      @skipping="onIntroSkipping"
    />

    <div class="pixi-stage__interaction-back" @click="hideInteraction">
      START YOUR JOURNEY
    </div>
  </div>
</template>

<script>
import axios from "axios";
const InteractionIntro = () =>
  import(
    /* webpackChunkName: "interaction-intro" */ "@/components/pixi/interactions/InteractionIntro.vue"
  );

const DataInteraction = () =>
  import(
    /* webpackChunkName: "data-interaction" */ "@/components/pixi/interactions/DataInteraction.vue"
  );
const PixiInteraction = () =>
  import(
    /* webpackChunkName: "pixi-interaction" */ "@/components/pixi/interactions/PixiInteraction.vue"
  );
const DecorInteraction = () =>
  import(
    /* webpackChunkName: "decor-interaction" */ "@/components/pixi/interactions/DecorInteraction.vue"
  );

const BucketlistInteraction = () =>
  import(
    /* webpackChunkName: "buckletlist-interaction" */ "@/components/pixi/interactions/BucketlistInteraction.vue"
  );

const LightsInteraction = () =>
  import(
    /* webpackChunkName: "lights-interaction" */ "@/components/pixi/interactions/LightsInteraction.vue"
  );

const MindgameInteraction = () =>
  import(
    /* webpackChunkName: "mindgame-interaction" */ "@/components/pixi/interactions/MindgameInteraction.vue"
  );

const MemoInteraction = () =>
  import(
    /* webpackChunkName: "memo-interaction" */ "@/components/pixi/interactions/MemoInteraction.vue"
  );

const FashionInteraction = () =>
  import(
    /* webpackChunkName: "fashion-interaction" */ "@/components/pixi/interactions/FashionInteraction.vue"
  );

const InventionsInteraction = () =>
  import(
    /* webpackChunkName: "inventions-interaction" */ "@/components/pixi/interactions/InventionsInteraction.vue"
  );

const MeditationInteraction = () =>
  import(
    /* webpackChunkName: "meditation-interaction" */ "@/components/pixi/interactions/MeditationInteraction.vue"
  );

export default {
  name: "InteractionShareLayer",
  mixins: [],
  components: {
    DataInteraction,
    DecorInteraction,
    PixiInteraction,
    BucketlistInteraction,
    LightsInteraction,
    MindgameInteraction,
    MemoInteraction,
    InteractionIntro,
    FashionInteraction,
    InventionsInteraction,
    MeditationInteraction
  },
  data() {
    return {
      introHidden: false,
      interactionInvalid: false,
      introContent: undefined,
      interactionData: undefined
    };
  },
  props: {
    activeInteraction: undefined
  },
  async mounted() {
    //JSONS PARSEN

    axios
      .all([
        axios.get("/json/chapter0.json"),
        axios.get("/json/chapter1.json"),
        axios.get("/json/chapter2.json")
      ])
      .then(
        axios.spread((chapter1, chapter2, chapter3) => {
          this.setIntroContent([chapter1, chapter2, chapter3]);
        })
      );
  },
  methods: {
    setIntroContent(contents) {
      contents.forEach(item => {
        const intro = item.data.items.find(
          el => el.id === this.activeInteraction
        );


        if (intro) {
          if(intro.interaction_data) this.interactionData = intro.interaction_data;
          
          const colors = {
            backgroundColor: item.data.backgroundColor,
            colors: item.data.colors
          };

          this.$store.commit("set_teased_background_color", colors);
          this.introContent = intro.intro;
        }
      });

      if (!this.introContent) {
        this.$router.push("/");
        this.$store.commit("set_shared", undefined);
      }
    },
    hideInteraction() {
      //HOME!
      this.$router.push("/");
      this.$store.commit("set_shared", undefined);
    },
    onIntroSkipping(){
      this.hideInteraction();
    },
    onIntroHiding() {
      this.introContent = null;
      this.introHidden = true;

      this.$gtag.event("Interaction started", {
        event_category:
          "Interaction: " +
          this.activeInteraction.charAt(0).toUpperCase() +
          this.activeInteraction.slice(1)
      });
    }
  },
  beforeDestroy() {}
};
</script>

<style scoped lang="scss"></style>

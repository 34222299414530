import { render, staticRenderFns } from "./HomeChapter.vue?vue&type=template&id=c582b7c4&"
import script from "./HomeChapter.vue?vue&type=script&lang=js&"
export * from "./HomeChapter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div class="room">
    <div id="container" class="room__component"></div>

    <div class="room__overlays">
      <h1>{{ roomName }}</h1>

      <RoomHotspot
        v-for="(item, index) in hotspots"
        :key="index"
        :content="item"
        :position="hotspotPositions[index]"
        @activate="activateHotspot"
      />
    </div>

    <div class="debug">
      {{ hotspotPositions }}
    </div>

    <div class="room__fader" ref="blender"></div>
  </div>
</template>

<script>
import gsap from "gsap";
import RoomHotspot from "./RoomHotspot";
import DataMixin from "@/mixins/DataMixin";
export default {
  name: "RoomComponent",
  components: { RoomHotspot },
  mixins: [DataMixin],
  data() {
    return {
      panoInstance: undefined,
      activeHotspot: undefined,
      hotspots: [],
      hotspotPositions: [],
      activeRoom: undefined,
      roomLabel: undefined
    };
  },

  props: {
    room: undefined,
    roomName: undefined,
    

  },
  methods: {
    activateHotspot(e) {
      e;
      //console.log(e);
      //activeComponent
    },
    onPanoInteraction(e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      //console.log("e", e);
    },
    getVisibility(item) {
      const bounds = this.$el.getBoundingClientRect();
      const rect = item.div.getBoundingClientRect();

      return (
        rect.x > 0 &&
        rect.y > 0 &&
        rect.x < bounds.width &&
        rect.y < bounds.height
      );
    },
    onPanoChange() {
      //this.panoInstance.stopAutorotate();

      const allHotspots = this.panoInstance.getPointHotspotIds();
      const positions = [];

      for (var i = 0; i < allHotspots.length; i++) {
        const element = allHotspots[i];
        const div = this.panoInstance.getHotspot(element).div;
        //const content = content;
        const data = {
          key: element.toLowerCase(),
          div: div,
          visible: this.getVisibility(this.panoInstance.getHotspot(element))
        };

        const rect = div.getBoundingClientRect();

        const pos = {
          x: rect.x,
          y: rect.y
        };

        positions.push(pos);

        if (!this.hotspots[i]) {
          //initial
          this.panoInstance.getHotspot(element).div.style.opacity = 0;
        }

        const key = element;
        if (this.contentJson.contents[key]) {
          data.key = key;
          data.label = this.contentJson.contents[key].label;
          data.target = this.contentJson.contents[key].target;
        } else {
          data.label = element;
        }

        this.hotspots[i] = data;
      }

      this.hotspotPositions = positions;
    },
    onPanoLoaded() {
      this.hotspots = [];
      this.hotspotPositions = [];
      this.onPanoChange();

      gsap.delayedCall(0.1, () => {
        this.onPanoChange();

        /*const allHotspots = this.panoInstance.getPointHotspotIds();
        this.focusHotspot(allHotspots[3]);*/
      });

      gsap.to(this.$refs.blender, {
        overwrite:true,
        duration: 1,
        autoAlpha: 0,
        delay:.1,
        onComplete: () => {}
      });
    },
    focusHotspot(id) {
      const focusHotspot = this.panoInstance.getHotspot(id);
      //this.panoInstance.moveTo(focusHotspot.pan, focusHotspot.tilt, 70, 5000);
      this.panoInstance.setPanTiltFov(focusHotspot.pan, focusHotspot.tilt,50);

      gsap.delayedCall(0.1, () => {
        this.onPanoChange();
      });
    },
    onPanoNode() {
      //console.log("NODE CHANGED");
      gsap.set(this.$refs.blender, { autoAlpha: 1 });
    },
    onPanoLoading(e) {
      e;
      //console.log("LOADING", e.percentLoaded);
    },
    initPano2VR() {
      let pano = new window.pano2vrPlayer("container");

      this.panoInstance = pano;
      this.panoInstance.on("imagesready", this.onPanoLoaded);
      this.panoInstance.on("downloadprogress", this.onPanoLoading);
      this.panoInstance.on("positionchanged", this.onPanoChange);
      this.panoInstance.on("changenode", this.onPanoNode);

      pano.setTransition({
        type: "crossdissolve",
        before: "zoomin",
        after: "zoomout",
        transitiontime: 2,
        softedge: true
      });

      this.panoInstance.readConfigUrlAsync(this.contentJson.pano_xml);

      console.log(this.contentJson,"****");
    }
  },
  computed: {
    stageScale() {
      return this.$screen.lastChange;
    }
  },
  watch: {
    stageScale() {
      gsap.delayedCall(0.1, () => {
        this.onPanoChange();
      });
    },

    contentJson() {
      this.roomLabel = this.contentJson.label;

      if (this.panoInstance) {
        this.hotspots = [];
        this.hotspotPositions = [];
        //console.log("LOADING NEXT PANO:" + this.contentJson.pano_xml);
        this.panoInstance.openNext(this.contentJson.pano_xml);
        //this.panoInstance.updatePanorama();
        //this.panoInstance.on("imagesready", this.onPanoLoaded);
        //this.panoInstance.on("positionchanged", this.onPanoChange);

        return;
      }

      this.initPano2VR();
    },
    room() {
      this.activeRoom = this.room;
      this.fetchData("rooms/" + this.room);
    }
  },
  beforeDestroy() {
    document.documentElement.classList.remove("no-overflow");

    if (this.panoInstance) {
      this.panoInstance.off("positionchanged", this.onPanoChange);
      this.panoInstance.off("imagesready", this.onPanoLoaded);
      this.panoInstance.off("changenode", this.onPanoNode);
      this.panoInstance.off("downloadprogress", this.onPanoLoading);
    }
    this.panoInstance = null;
  },
  mounted() {
    if (this.room) this.fetchData("rooms/" + this.room);
    document.documentElement.classList.add("no-overflow");
  }
};
</script>

<template>
  <div class="homechapter">
    <div class="homechapter__wrapper">
      <div class="homechapter__container homechapter__container--chapter" v-if="!introVisible" ref="chapters">
        <div class="homechapter__item homechapter__item--1" @mouseleave="unFocusItem()" @mouseenter="focusItem(0)"
          @click="onChapterClick(0)" ref="item_0" :style="{
            'margin-top': 2 * floatAnimationValues[0].y + '%',
            'margin-left': 2 * floatAnimationValues[0].x + '%'
          }">
          <!--<Button @click.native="onChapterClick(0)">Time Utopia</Button>-->
          <h2>Time Utopia</h2>
          <img src="/assets/img/chapter-teaser/chaptercard_book01@2x.jpg" alt="" />
          <div class="homechapter__item-bg"></div>
          <div class="homechapter__item-overlay">
            <img src="/assets/img/chapter-teaser/chapterbackground_book01@2x.png" alt="" />
          </div>
        </div>

        <div class="homechapter__item homechapter__item--2" @mouseleave="unFocusItem()" @mouseenter="focusItem(1)"
          @click="onChapterClick(1)" ref="item_1" :style="{
            'margin-top': 2 * floatAnimationValues[1].y + '%',
            'margin-left': 2 * floatAnimationValues[1].x + '%'
          }">
          <!--<Button @click.native="onChapterClick(0)">Time Utopia</Button>-->
          <h2>Perception<br>of Time</h2>
          <img src="/assets/img/chapter-teaser/chaptercard_book02@2x.jpg" alt="" />
          <div class="homechapter__item-bg"></div>
          <div class="homechapter__item-overlay">
            <img src="/assets/img/chapter-teaser/chapterbackground_book02@2x.png" alt="" />
          </div>
        </div>

        <div class="homechapter__item  homechapter__item--3" @mouseleave="unFocusItem()" @mouseenter="focusItem(2)"
          @click="onChapterClick(2)" ref="item_2" :style="{
            'margin-top': 2 * floatAnimationValues[2].y + '%',
            'margin-left': 2 * floatAnimationValues[2].x + '%'
          }">
          <div class="homechapter__item-bg">
            <img src="/assets/img/chapter-teaser/visual_levelbg.png" alt="" />
          </div>

          <h2>Zeitgeist</h2>
          <img src="/assets/img/chapter-teaser/visual_level0.jpg" alt="" />
          <div class="homechapter__item-overlay">
            <img src="/assets/img/chapter-teaser/visual_level1.png" alt="" />
          </div>
        </div>
      </div>

      <div class="homechapter__container homechapter__container--endvideo" v-if="!introVisible" ref="video_teaser">
        <div class="homechapter__item  homechapter__item--4" @mouseleave="unFocusItem()" @mouseenter="focusItem(3)"
          @click="onChapterClick(3)" ref="item_3" :style="{
            'margin-top': 2 * floatAnimationValues[3].y + '%',
            'margin-left': 2 * floatAnimationValues[3].x + '%'
          }">
          <div class="homechapter__item-bg">
            <img src="/assets/img/Review Reel thumbnail 01 Bild.png" alt="" />
            <h2>Watch UCHRONIA<br>Review Reel</h2>
          </div>

          <div class="homechapter__item-overlay">
            <img src="/assets/img/Review Reel thumbnail 01 globus.png" alt="" />
            <img src="/assets/img/Review Reel thumbnail 01 letters.png" alt="" />
          </div>

          <div class="homechapter__item-play">
            <img src="/assets/img/Review Reel thumbnail 01 play button.png" alt="" />
          </div>
        </div>

      </div>

      <div class="homechapter__container" v-if="introVisible">
        <div class="homechapter__intro" ref="intro">
          <Button @click.native="onSkipIntroClick" ref="skip">SKIP INTRO</Button>

          <div class="homechapter__intro-animation">
            <div class="homechapter__intro-animation-container" ref="crawl_container">
              <div class="crawl" ref="crawl">
                <div class="crawl__content" ref="crawl_content">
                  <div class="title">
                    <h2>Welcome to UCHRONIA</h2>
                  </div>

                  <p>
                    What if we could embrace time, and by doing so, we could
                    also embrace ourselves? We would live in ways so we could
                    build a perfect relationship with time. We would have
                    UCHRONIA. Time Utopia.
                  </p>
                  <p>
                    UCHRONIA, a world of temporal freedom, where lives are not
                    dictated by clocks and alarms, but gently influenced by
                    nature instead. UCHRONIA allows us to be in sync with our
                    environment, our bodies and our minds, so we can thrive on
                    our own terms.
                  </p>
                  <p>
                    Of course, any kind of perfect relationship seems utopic but
                    that is precisely what UCHRONIA is. Even though it might
                    still be far away from reality now, if we can imagine each
                    individual having their unique way of following their inner
                    rhythm, it may be the first step to build a different
                    reality around us.
                  </p>
                  <p>
                    A different way of connecting with time, with ourselves,
                    with our environment and the world.
                  </p>
                  <p>
                    Time to reflect, time to wonder, time to have the time of
                    our lives.
                  </p>
                  <p>Time for UCHRONIA.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "../form/Button.vue";
import gsap from "gsap";
export default {
  name: "HomeChapter",
  mixins: [],
  components: { Button },
  data() {
    return {
      items: [],
      introVisible: true,
      introSkipped: false,
      nextChapter: undefined,
      floatAnimationValues: [
        { x: 0, y: 0 },
        { x: 0, y: 0 },
        { x: 0, y: 0 },
        { x: 0, y: 0 }
      ],
      scroll: 0,
      maxScroll: 0
    };
  },
  props: {},

  methods: {
    calcMaxScroll() {
      if (!this.introVisible) return;
      this.maxScroll =
        this.$refs.crawl_container.scrollHeight -
        this.$refs.crawl_container.clientHeight;
    },
    focusItem(id) {
      if (window.innerWidth <= 768) return;

      let offset1 = 0;
      let offset2 = 0;
      let offset3 = 0;

      if (id === 0) {
        offset2 = 50;
        offset3 = 50;
      } else if (id === 1) {
        offset1 = -50;
        offset3 = 50;
      } else if (id === 2) {
        offset1 = -50;
        offset2 = -50;
      }

      gsap.to(this.$refs.item_0, {
        duration: 1,
        overwrite: true,
        scale: 0.7,
        autoAlpha: 1,
        x: offset1,
        zIndex:1,
        ease: "Expo.easeOut"
      });

      gsap.to(this.$refs.item_1, {
        duration: 1,
        overwrite: true,
        scale: 0.7,
        autoAlpha: 1,
        x: offset2,
        zIndex:1,
        ease: "Expo.easeOut"
      });

      
        gsap.to(this.$refs.item_2, {
          duration: 1,
          overwrite: true,
          scale: 0.7,
          autoAlpha: 1,
          x: offset3,
          zIndex:1,
          ease: "Expo.easeOut"
        });

      
        gsap.to(this.$refs.item_3, {
          duration: 1,
          overwrite: true,
          scale: 0.7,
          autoAlpha: 1,
          zIndex:1,
          ease: "Expo.easeOut"
        });

      const overlays = this.$el.getElementsByClassName(
        "homechapter__item-overlay"
      );

      const bg = this.$el.getElementsByClassName(
        "homechapter__item-bg"
      );

      gsap.to(overlays, {
        duration: 1,
        scale: 1,
        overwrite: true,
        ease: "Expo.easeOut"
      });



      gsap.to(bg, {
        duration: 1,
        scale: 1,
        overwrite: true,
        ease: "Expo.easeOut"
      });

      gsap.to(this.$refs["item_" + id], {
        ease: "Expo.easeOut",
        duration: 2,
        overwrite: true,
        autoAlpha: 1,
        x: 0,
        scale: 1.2,
        zIndex:2
      });



      if (id === 3) {
        gsap.to(overlays[id], {
          ease: "Expo.easeOut",
          duration: 3,
          overwrite: true,
          scale: 1.2
        });
      } else {
        gsap.to(overlays[id], {
          ease: "Expo.easeOut",
          duration: 3,
          overwrite: true,
          scale: 1.1
        });

      }

      if (bg[id]) {

        gsap.to(bg[id], {
          ease: "Expo.easeOut",
          duration: 3,
          overwrite: true,
          scale: 1.1
        });
      }

      let colors = {
        backgroundColor: "#ffd543",
        colors: ["#ec771a", "#ffd646", "#ec771a", "#d5e2ce"]
      };

      if (id === 1) {
        colors = {
          backgroundColor: "#f5a649",
          colors: ["#f1913b", "#f1913b", "#a1c5dd", "#dbaac7"]
        };
      }

      if (id === 2) {
        colors = {
          backgroundColor: "#f0858c",
          colors: ["#ef6672", "#2a9aa3", "#ccc0c9", "#7f8392"]
        };
      }

      if (id === 3) {
        colors = {
          backgroundColor: "#f0858c",
          colors: ["#ef6672", "#2a9aa3", "#a1c5dd", "#dbaac7"]
        };
      }


      this.$store.commit("set_teased_background_color", colors);
    },
    unFocusItem() {
      const overlays = this.$el.getElementsByClassName(
        "homechapter__item-overlay"
      );
      gsap.to(overlays, {
        duration: 1,
        scale: 1,
        ease: "Expo.easeOut",
        overwrite: true
      });

      const bgs = this.$el.getElementsByClassName(
        "homechapter__item-bg"
      );
      gsap.to(bgs, {
        duration: 1,
        scale: 1,
        ease: "Expo.easeOut",
        overwrite: true
      });

      //DELAYED.

      gsap.to(this.$refs.item_0, {
        duration: 3,
        scale: 1,
        ease: "Expo.easeOut",
        overwrite: true
      });
      gsap.to(this.$refs.item_1, {
        duration: 3,
        scale: 1,
        ease: "Expo.easeOut",
        overwrite: true
      });

      
        gsap.to(this.$refs.item_2, {
          duration: 3,
          scale: 1,
          ease: "Expo.easeOut",
          overwrite: true
        });


        gsap.to(this.$refs.item_3, {
          duration: 3,
          scale: 1,
          ease: "Expo.easeOut",
          overwrite: true
        });

      this.startFloat();
      this.$store.commit("set_teased_background_color", undefined);
    },

    startFloat() {
      let items = this.floatAnimationValues;

      gsap.to(this.$refs.item_0, { autoAlpha: 1 });
      gsap.to(this.$refs.item_1, { autoAlpha: 1 });
      if (this.$refs.item_2) gsap.to(this.$refs.item_2, { autoAlpha: 1 });
      if (this.$refs.item_3) gsap.to(this.$refs.item_3, { autoAlpha: 1 });

      items.forEach(item => {
        this.startFloatAnimation(item);
      });
    },
    stopFloatAnimation() {
      const items = this.floatAnimationValues;

      items.forEach(item => {
        gsap.killTweensOf(item);
      });
    },
    startFloatAnimation(item) {
      const time = 3 + Math.random() * 2;
      const targetY = -0.5 + Math.random() * 1;
      const targetX = -1 + Math.random() * 2;

      if (this.$route.hash !== "#chapters") return;
      gsap
        .to(item, {
          ease: "Sine.easeOut",
          duration: time,
          y: targetY,
          x: targetX,
          overwrite: true
        })
        .then(() => {
          this.startFloatAnimation(item);
        });
    },
    onSkipIntroClick() {
      this.$store.commit("set_intro_skipped", true);
      this.$store.commit("set_hash", "chapters");
      //this.$router.push({ hash: "chapters" });

      /*this.introSkipped = true;
      this.$store.commit("set_active_chapter", this.nextChapter);*/
      gsap.to(this.$refs.intro, { autoAlpha: 0, duration: 0.5 }).then(() => {
        gsap.killTweensOf(this);
        //gsap.to(this, { ease: "none", duration: 70, scroll: 1 });
        this.introVisible = false;
      });
    },
    onChapterClick(index) {
      if (index === 3) {
        this.$store.commit("set_lightbox_content", {
          headline: "UCHRONIA Review Reel",
          vimeo: "814923854",
        });
        return;
      }
      this.nextChapter = index;

      this.stopFloatAnimation();

      gsap.to(this.$refs.video_teaser, { autoAlpha: 0 });
      gsap.to(this.$refs.chapters, { autoAlpha: 0 }).then(() => {
        //this.$store.commit("set_active_chapter", this.nextChapter);
        const chapterNames = this.$store.getters.chapters;
        this.$store.commit("set_hash", chapterNames[index]);
      });
    },
    showIntro() { }
  },

  watch: {
    scroll() {
      this.$refs.crawl_container.scrollTop = this.scroll * this.maxScroll;
      if (this.scroll >= 0.99) {
        this.onSkipIntroClick();
      }
    },
    stageSize() {
      this.calcMaxScroll();
    },
    activeChapter() {
      if (this.activeChapter !== undefined) {
        gsap.killTweensOf(this.floatAnimationValues);
      }
    },
    introVisible() {
      this.$nextTick(() => {
        if (this.introVisible) {

          gsap.set(this.$refs.skip.$el, { y: 30, autoAlpha: 0 });
          gsap.to(this.$refs.skip.$el, { delay: 0.5, y: 0, autoAlpha: 1 });
          gsap.to(this.$refs.intro, { autoAlpha: 1 });
          gsap.killTweensOf(this.floatAnimationValues);

          //gsap.to(this.$el.getElementsByClassName('crawl'), {duration:20, marginTop: "-4000px" });
        } else {
          gsap.to(this.$refs.chapters, { autoAlpha: 1 });
          gsap.to(this.$refs.video_teaser, { autoAlpha: 1 });
          this.startFloat();
        }
      });
    }
  },
  computed: {
    stageSize() {
      return this.$screen.width;
    },

    activeChapter() {
      return this.$store.getters.activeChapter;
    }
  },

  mounted() {
    if (this.$store.getters.introSkipped) {
      this.introVisible = false;
    }

    this.$nextTick(() => {

      if (!this.introVisible) {
        this.$store.commit("set_hash", "chapters");
        this.startFloat();
        gsap.to(this.$refs.chapters, { delay: 0.5, autoAlpha: 1 });
        gsap.to(this.$refs.video_teaser, { delay: 0.5, autoAlpha: 1 });
      } else {
        this.$store.commit("set_hash", "intro");
        this.calcMaxScroll();
        gsap.to(this, { ease: "none", duration: 70, scroll: 1 });

        gsap.set(this.$refs.skip.$el, { autoAlpha: 0 });
        gsap.to(this.$refs.skip.$el, { delay: 1.7, y: 0, autoAlpha: 1 });
        gsap.to(this.$refs.intro, { delay: 0.5, autoAlpha: 1 });
      }
    });
  }
};
</script>

<template>
  <div class="home">
    <HomeTeaser :content="contentJson" :chaptersVisible="chaptersVisible" v-if="!journeyStarted && !share" />
    <!--SHARE INTERACTION IF SHARE IS SET-->
    <InteractionShareLayer :activeInteraction="share" v-if="share" />
  </div>
</template>

<script>
import HomeTeaser from "../components/home/HomeTeaser.vue";
import DataMixin from "@/mixins/DataMixin.js";
import InteractionShareLayer from "../components/pixi/interactions/InteractionShareLayer.vue";

export default {
  name: "Home",
  mixins: [DataMixin],
  components: {
    HomeTeaser,
    InteractionShareLayer
  },
  data() {
    return {
      hubspot: true
    };
  },
  computed: {
    share() {
      return this.$store.getters.shared;
    },
    loggedIn() {
      return this.$store.getters.getLoggedIn;
    },
    journeyStarted() {
      return this.$store.getters.journeyStarted;
    },
    activeChapter() {
      return this.$store.getters.activeChapter;
    },
    chaptersVisible() {
      return this.$store.getters.chaptersVisible;
    }
  },

  watch: {
    loggedIn() {
      if (!this.loggedIn) {
        this.$store.commit("set_chapters_visible", false);
        this.$store.commit("set_active_chapter", undefined);
        this.$store.commit("set_journey_active", false);
        this.$store.commit("set_journey_started", false);
      }
    },
    chaptersVisible() { },
    activeChapter() {
      if (this.activeChapter === undefined) {
        this.$store.commit("set_journey_active", false);
        this.$store.commit("set_journey_started", false);
      }
    }
  },
  methods: {
    onClose() {
      this.hubspot = false;
      const form = document.getElementsByClassName('hbspt-form')[0];
      form.parentNode.removeChild(form);

      const toast = {
        label: "Success!",
        body: "Thanks for subscribing!",
        type: "success",
        life: "5"
      };

      this.$store.getters.toastManager.add(toast);
    }
  },
  mounted() {
    this.fetchData("home");
    if (this.journeyStarted) this.$store.commit("set_journey_active", true);
  }
};
</script>

<template>
  <div id="app" :style="{ height: appHeight }" :class="{ ['route-' + currentRoute]: true }">
    <template v-if="fontsLoaded && configLoaded && clustersLoaded">
      <router-view />
      <PixiStage />
      <Footer />
      <Header />
      <SubNavigation />
      <ToastMessenger />
      <MetaLayer v-if="metaLayerOpenend" />
      <Lightbox />
      <CookieConsent />
      <SharePopup />
      <Griddle />
    </template>
  </div>
</template>

<script>
import WebFont from "webfontloader";
import PixiStage from "@/components/pixi/PixiStage.vue";
import Header from "@/components/meta/Header.vue";
import Footer from "@/components/meta/Footer.vue";
import MetaLayer from "@/components/meta/MetaLayer.vue";
import ToastMessenger from "@/components/toast/ToastMessenger.vue";
import SubNavigation from "@/components/SubNavigation.vue";
import Lightbox from "@/components/lightbox/Lightbox.vue";
import CookieConsent from "./components/CookieConsent.vue";
import axios from "axios";
import SharePopup from "./components/SharePopup.vue";

export default {
  metaInfo: {
    title: "Welcome to UCHRONIA.",
    titleTemplate: "",
    htmlAttrs: {
      lang: "en"
    }
  },
  name: "App",
  components: {
    PixiStage,
    Header,
    Footer,
    SubNavigation,
    MetaLayer,
    ToastMessenger,
    Lightbox,
    CookieConsent,
    SharePopup
  },
  data() {
    return {
      fontsLoaded: false,
      configLoaded: false,
      clustersLoaded: false,
      appHeight: 0,
      currentRoute: undefined
    };
  },
  watch: {
    $route(to) {
      window.scrollTo(0, 0);
      this.$store.commit("set_current_route", to);
      this.currentRoute = this.$route.path.split("/")[1];

      if (this.$route.path.split("/")[1]) {
        //unterseite (decors etc)
        this.$store.commit("set_journey_active", false);

      } else if (to.hash) {
        if (to.hash === "#chapters") {
          this.$store.commit("set_active_chapter", undefined);
          this.$store.commit("set_journey_active", false);
          this.$store.commit("set_journey_started", false);

          this.$store.commit("set_chapters_visible", true);
        }

        const options = this.$store.getters.chapters;
        const index = options.indexOf(to.hash);

        if (index >= 0) {
          this.$store.commit("set_active_chapter", index);
        }
      } else {
        this.$store.commit("set_chapters_visible", false);
        this.$store.commit("set_active_chapter", undefined);
        this.$store.commit("set_journey_active", false);
        this.$store.commit("set_journey_started", false);
      }

      this.checkScreenHeight();
    },
    screenHeight() {
      this.checkScreenHeight();
    }
  },

  methods: {
    fetchEventState(){
      const rHash = Math.floor(
      Math.random() * 1000000 + Math.random() * 10000000
    );

    axios.get("/json/config.json?cb=" + rHash).then(res => {
      this.$store.commit("set_event_streams", res.data.videos);
      this.$store.commit("set_event_state", res.data.state);
      this.configLoaded = true;
      //console.log("*****",res.data.state);

      if(res.data.state === "prelive") this.updateEventState();
    });
    },
    updateEventState(){
      setTimeout(this.fetchEventState,10000);      
    },
    checkScreenHeight() {
      const forceHeight = []; //"rooms"
      const basePath = this.$route.path.split("/")[1];

      if (forceHeight.includes(basePath)) {
        this.appHeight = Math.round(window.innerHeight) + "px";
      } else {
        this.appHeight = undefined;
      }
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters.getLoggedIn;
    },
    screenHeight() {
      return this.$screen.height;
    },
    metaLayerOpenend() {
      return this.$store.getters.getMetaLayerID;
    },
    lightboxOpenend() {
      return this.$store.getters.lightboxContent;
    }
  },
  mounted() {
    let favorites = localStorage.getItem("uchronia_favorites");
    if (!favorites) {
      favorites = [];
    } else {
      favorites = JSON.parse(favorites);
    }

    this.$store.commit("set_favorites", favorites);

    const share = this.$route.query.share;
    if (share) this.$store.commit("set_shared", share);

    const config = {
      google: {
        families: ["Open Sans", "Nunito Sans"]
      },
      active: () => {
        const hasTouch =
          "ontouchstart" in window ||
          navigator.maxTouchPoints > 0 ||
          navigator.msMaxTouchPoints > 0;

        this.$store.commit("set_has_touch", hasTouch);
        this.checkScreenHeight();
        this.fontsLoaded = true;
      }
    };

    WebFont.load(config);

    this.currentRoute = this.$route.path.split("/")[1];

    this.$store.commit("set_current_route", this.$route);
    this.$store.dispatch("initialize");

    this.fetchEventState();

    /* fetch clusters */
    //https://staging-cms.digitalimpress.biz/wp-json/videoapi/v1/clusters
    axios
      .get("https://cms.digitalimpress.biz/wp-json/videoapi/v1/clusters")
      .then(res => {
        //res.data.clusters
        const chapters = [];

        //TODO: Make dynamic!

        chapters[0] = {
          rooms: [],
          decors: []
        };

        chapters[1] = {
          rooms: [],
          decors: []
        };

        chapters[2] = {
          rooms: [],
          decors: []
        };

        res.data.clusters.rooms_1.forEach(cluster => {
          chapters[0].rooms.push({
            name: cluster.post_name,
            title: cluster.post_title
          });
        });

        res.data.clusters.decors_1.forEach(cluster => {
          chapters[0].decors.push({
            name: cluster.post_name,
            title: cluster.post_title
          });
        });

        res.data.clusters.rooms_2.forEach(cluster => {
          chapters[1].rooms.push({
            name: cluster.post_name,
            title: cluster.post_title
          });
        });

        res.data.clusters.decors_2.forEach(cluster => {
          chapters[1].decors.push({
            name: cluster.post_name,
            title: cluster.post_title
          });
        });

        res.data.clusters.rooms_3.forEach(cluster => {
          chapters[2].rooms.push({
            name: cluster.post_name,
            title: cluster.post_title
          });
        });

        res.data.clusters.decors_3.forEach(cluster => {
          chapters[2].decors.push({
            name: cluster.post_name,
            title: cluster.post_title
          });
        });

        //NEW
        chapters[2].trends = [];

        res.data.clusters.trends_3.forEach(cluster => {
          chapters[2].trends.push({
            name: cluster.post_name,
            title: cluster.post_title
          });
        });

        this.$store.commit("set_filtered_chapter_contents", chapters);

        this.clustersLoaded = true;
      })
      .catch(err => console.log(err));
  }
};
</script>

<style lang="scss">
@import "scss/main.scss";

.test {
  position: absolute;
  width: 100px;
  height: 70%;
  z-index: 9000;

  top: 0;
  left: 0;
}
</style>

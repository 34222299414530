import gsap from "gsap";

export default {
  data() {
    return {};
  },
  created() {
    this.$nextTick(() => {
      gsap.set(this.$el, { autoAlpha: 0 });
    });
  },
  watch: {
    contentJson() {
      gsap.to(this.$el, { autoAlpha: 1 });
    },
  },
  methods: {},
};

<template>
  <div class="sub-navigation" v-if="!isHidden">
    <div class="sub-navigation__container">
      <div
        :class="{ 'sub-navigation__item--active': currentRoute === '/videos' }"
        class="sub-navigation__item sub-navigation__item--videos"
        @click="onClick('/videos')"
      >
        <div class="sub-navigation__item-circle">
          <inline-svg :src="require('../assets/svg/icon_hotspot.svg')" />
        </div>
        <span>Videos</span>
      </div>

      <div
        :class="{ 'sub-navigation__item--active': currentRoute === '/trends' }"
        class="sub-navigation__item sub-navigation__item--trends"
        @click="onClick('/trends')"
      >
        <div class="sub-navigation__item-circle">
          <inline-svg :src="require('../assets/svg/icon_hotspot.svg')" />
        </div>
        <span>Trends</span>
      </div>
      <div
        :class="{ 'sub-navigation__item--active': currentRoute === '/decors' }"
        class="sub-navigation__item sub-navigation__item--decors"
        @click="onClick('/decors')"
      >
        <div class="sub-navigation__item-circle">
          <inline-svg :src="require('../assets/svg/icon_hotspot.svg')" />
        </div>
        <span>Decors</span>
      </div>
      <div
        :class="{ 'sub-navigation__item--active': currentRoute === '/rooms' }"
        class="sub-navigation__item sub-navigation__item--rooms"
        @click="onClick('/rooms')"
      >
        <div class="sub-navigation__item-circle">
          <inline-svg :src="require('../assets/svg/icon_hotspot.svg')" />
        </div>
        <span>Rooms</span>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "SubNavigation",
  data() {
    return {
      isHidden: true
    };
  },
  methods: {
    startFloatAnimation(element) {
      let targetX = -20+Math.random()*40;
      let targetY = -20+Math.random()*40;

      if(window.innerWidth < 500) {
        targetX = -10+Math.random()*20;
       targetY = -10+Math.random()*20;
      }

      gsap.to(element, { duration:2+Math.random()*2, ease:"Sine.easeInOut", y: targetY, x:targetX }).then(()=>{this.startFloatAnimation(element)});
    },
    stopFloatAnimation(element) {
      gsap.to(element, { duration:.5,autoAlpha: 0,overwrite:true}).then(()=>{
        this.isHidden = true;
      });
    },
    startFloat() {
      this.$nextTick(() => {
        const items = Array.from(
          this.$el.getElementsByClassName("sub-navigation__item")
        );

        items.forEach(element => {
          gsap.to(element, { duration:.5,autoAlpha: 1});
          this.startFloatAnimation(element);
        });
      });
    },
    stopFloat() {
      this.$nextTick(() => {
        const items = Array.from(
          this.$el.getElementsByClassName("sub-navigation__item")
        );

        items.forEach(element => {
          this.stopFloatAnimation(element);
        });
      });

      
    },
    onClick(target) {
      const active = this.$store.getters.chapters[this.$store.getters.activeChapter];
      this.$router.push(target+active);
    }
  },
  watch: {
    isVisible() {
      if (this.isVisible) {
        this.isHidden = false;
        this.startFloat();
      } else {
        this.stopFloat();
      }
    }
  },
  computed: {
    currentRoute() {
      return this.$store.getters.getCurrentRoute.path;
    },
    isVisible() {
      return (
        /*(this.$store.getters.getCurrentRoute.path.indexOf("user") === -1 &&
          this.$store.getters.getCurrentRoute.path.split("/").length !== 3 &&
          this.$store.getters.getCurrentRoute.path !== "/welcome" &&
          this.$store.getters.getCurrentRoute.path !== "/") ||*/
        this.$store.getters.journeyActive
      );
    }
  },
  mounted() {
    
  }
};
</script>

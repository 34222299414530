<template>
  <div class="scroll-hint" v-show="visible" v-if="!removed">
    <h1>START TO SCROLL</h1>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 70">
      <path
        d="M36,39.79a10.88,10.88,0,0,1-7,10.14,1,1,0,0,1-1.29-.57,1,1,0,0,1,.58-1.29,8.92,8.92,0,0,0,5.74-8V29.92a8.92,8.92,0,0,0-5.74-8,1,1,0,0,1-.58-1.29A1,1,0,0,1,29,20.07a10.88,10.88,0,0,1,7,10.14h0Zm-20,0a8.9,8.9,0,0,0,5.74,8.28,1,1,0,0,1,.58,1.29,1,1,0,0,1-1.29.57,10.88,10.88,0,0,1-7-9.83V29.9a10.88,10.88,0,0,1,7-9.83,1,1,0,0,1,1.29.57,1,1,0,0,1-.58,1.29A8.9,8.9,0,0,0,16,30.21h0Z"
        fill="#fff" fill-rule="evenodd" ref="outline" />
      <path d="M25,31a4,4,0,1,1-4,4A4,4,0,0,1,25,31Zm0,2a2,2,0,1,0,2,2A2,2,0,0,0,25,33Z" fill="#fff" fill-rule="evenodd"
        ref="trigger" />

      <path
        d="M29.71,53.88a.84.84,0,0,1,0,1.27l-4,3.59a1.09,1.09,0,0,1-.33.19,1.07,1.07,0,0,1-.76,0l-.17-.08a.91.91,0,0,1-.16-.11l-4-3.59a.84.84,0,0,1,0-1.27,1.07,1.07,0,0,1,1.42,0l2.29,2v-12a1,1,0,0,1,2,0v12l2.29-2A1.07,1.07,0,0,1,29.71,53.88Z"
        fill="#fff" fill-rule="evenodd" ref="arrow_bottom" />
      <path
        d="M29.71,16.12a.84.84,0,0,0,0-1.27l-4-3.59a1.09,1.09,0,0,0-.33-.19,1.07,1.07,0,0,0-.76,0l-.17.08a.91.91,0,0,0-.16.11l-4,3.59a.84.84,0,0,0,0,1.27,1.07,1.07,0,0,0,1.42,0L24,14.07v12a1,1,0,0,0,2,0v-12l2.29,2.05A1.07,1.07,0,0,0,29.71,16.12Z"
        fill="#fff" fill-rule="evenodd" ref="arrow_top" />
    </svg>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "Scrollhint",
  data() {
    return {
      removed: false
    };
  },
  props: {
    hidden: {
      default: true
    },
    visible: {
      default: false
    }
  },
  methods: {},
  watch: {
    hidden() {
      if (this.hidden) {
        gsap.to(this.$el, { autoAlpha: 0 }).then(() => {
          this.timeline.pause();
          this.removed = true;
        });
      } else {
        this.removed = false;
      }
    },
    visible() {
      if (this.removed) return;
      if (this.visible) {
        this.$nextTick(() => {
          gsap.to(this.$el, { overwrite: true, autoAlpha: 1, delay: 1 }).then(() => {
            this.timeline.play();
          });
        });
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.timeline = gsap.timeline({
        repeat: -1,
        repeatDelay: 1,
        paused: false
      });

      this.timeline.to(
        this.$refs.arrow_bottom,
        { y: 2, duration: 0.7, ease: "Expo.easeOut" },
        0
      );

      this.timeline.to(
        this.$refs.arrow_top,
        { y: 2, duration: 0.7, ease: "Expo.easeOut" },
        0
      );

      this.timeline.fromTo(
        this.$refs.trigger,
        { y: 0 },
        { y: 4, duration: 1, ease: "Expo.easeOut" },
        0
      );

      this.timeline.to(
        this.$refs.arrow_top,
        { y: -2, duration: 0.7, ease: "Expo.easeOut" },
        1
      );

      this.timeline.to(
        this.$refs.trigger,
        { y: -4, duration: 1, ease: "Expo.easeOut" },
        1
      );

      this.timeline.to(
        this.$refs.arrow_bottom,
        { y: -2, duration: 0.7, ease: "Expo.easeOut" },
        1
      );

      this.timeline.to(this.$refs.outline, { alpha: 1 }, 0);

      const len = 2.5;
      len;

      this.timeline.to(
        this.$refs.arrow_bottom,
        { y: 0, duration: 0.5, ease: "Sine.easeInOut" },
        len
      );

      this.timeline.to(
        this.$refs.arrow_top,
        { y: 0, duration: 0.5, ease: "Sine.easeInOut" },
        len
      );

      this.timeline.to(
        this.$refs.trigger,
        { y: 0, duration: 0.5, ease: "Sine.easeInOut" },
        len
      );

      /*
      this.timeline.to(
        this.$refs.arrow_top,
        { y: 0, duration: 0.7, ease: "Expo.easeOut" },
        1
      );

      this.timeline.to(
        this.$refs.arrow_bottom,
        { y: -2, duration: 0.7, ease: "Expo.easeOut" },
        1
      );

      this.timeline.to(
        this.$refs.trigger,
        { y: 0, duration: 0.7, ease: "Expo.easeOut" },
        len
      );*/
    });
  }
};
</script>


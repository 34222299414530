<template>
  <div
    class="room__hotspot"
    @mouseleave="onPointerLeave"
    v-if="position"
    :class="{ 'room__hotspot--active': this.active }"
    :style="{ left: position.x + 'px', top: position.y + 'px' }"
  >
    <div class="room__hotpot-content" ref="wrapper">
      <div class="room__hotpot-content-spike"></div>
      <div
        class="room__hotspot-label"
        :class="{ 'inactive': !content.target }"
        @click="onLinkClick"
      >
        <inline-svg
          :src="require('../../assets/svg/icon_page-arrow.svg')"
          v-if="content.target"
        />
        <span>{{ this.content.label }}</span>
      </div>
    </div>
    <div class="room__hotspot-icon" ref="icon">
      <inline-svg
        :src="require('../../assets/svg/icon_hotspot.svg')"
        v-if="
          content.key.toLowerCase() !== 'exit' &&
          content.key.toLowerCase() !== 'enter'
        "
        @mouseenter="onPointerEnter"
      />

      <inline-svg
        v-else-if="content.key.toLowerCase() === 'exit'"
        :src="require('../../assets/svg/icon_hotspot_exit-01.svg')"
        @mouseenter="onPointerEnter"
        @click="onLinkClick"
      />

      <inline-svg
        v-else-if="content.key.toLowerCase() === 'enter'"
        :src="require('../../assets/svg/icon_hotspot_enter-01.svg')"
        @click="onLinkClick"
        @mouseenter="onPointerEnter"
      />
    </div>

    <div class="room__hotspot-mousebouncer"></div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "RoomHotspot",
  data() {
    return {
      active: false
    };
  },
  props: {
    position: undefined,
    content: { position: { x: 0, y: 0 } }
  },
  computed: {},
  watch: {
    active() {
      if (this.active) {
        gsap.to(this.$refs.wrapper, { duration: 0.6, y: -80 });
        gsap.to(this.$refs.wrapper, { duration: 0.25, autoAlpha: 1 });
      } else {
        gsap.to(this.$refs.wrapper, {
          duration: 0.4,
          y: -90,
          autoAlpha: 0,
          overwrite: true
        });
      }
    }
  },
  methods: {
    onClick() {
      this.$emit("activate");
    },
    activate() {
      this.active = true;
    },
    deactivate() {
      this.active = false;
    },
    onPointerEnter() {
      this.active = true;
    },
    onPointerLeave() {
      this.active = false;
    },
    onLinkClick() {
      if (this.content.target) this.$router.push(this.content.target);
    }
  },
  mounted() {
    gsap.from(this.$refs.icon, {
      delay:1,
      overwrite:true,
      duration: 1,
      ease: "Expo.easeOut",
      scale: 0,
      transformOrigin: "center center"
    });
  }
};
</script>

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from '@/router'

Vue.use(Vuex);

const Base = {
  state: {
    chapters:["#time_utopia", "#perception_of_time","#zeitgeist"],
    has_touch: false,
    current_route: undefined,
    data_jsons: [],
    journey_started: false,
    journey_active: false,
    meta_layer_id: undefined,
    history: [],
    toast_manager: undefined,
    paralax_offset: undefined,
    lightbox_content: undefined,
    set_cookie_consent_accepted:undefined,
    restart_journey: undefined,
    active_chapter: undefined,
    chapters_visible: undefined,
    teased_background_color: undefined,
    intro_skipped: false,
    filtered_chapter_contents:undefined,
    event_state:undefined,
    event_streams:undefined,
    shared: undefined,
    favorites:undefined,
    share_target:undefined

  },
  getters: {
    getCachedData: (state) => (slug) => {
      return state.data_jsons[slug];
    },
    hasTouch: (state) => state.has_touch,
    getCurrentRoute: (state) => state.current_route,
    journeyActive: (state) => state.journey_active,
    journeyStarted: (state) => state.journey_started,
    getMetaLayerID: (state) => state.meta_layer_id,
    history: (state) => state.history,
    toastManager: (state) => state.toast_manager,
    paralaxOffset: (state) => state.paralax_offset,
    lightboxContent: (state) => state.lightbox_content,
    restartJourney:(state) => state.restart_journey,
    activeChapter:(state) => state.active_chapter,
    chaptersVisible:(state) => state.chapters_visible,
    teasedBackgroundColor:(state) => state.teased_background_color,
    introSkipped:(state) => state.intro_skipped,
    filteredChapterContents:(state) => state.filtered_chapter_contents,
    eventState:(state) => state.event_state,
    eventStreams:(state) => state.event_streams,
    shared:(state) => state.shared,
    share_target:(state) => state.share_target,
    favorites:(state) => state.favorites,
    chapters:(state) => state.chapters
  },
  actions: {
    update_favorites(state,value) {
      let favorites = this.getters.favorites;

      if(value.action === "remove") {
        favorites = this.getters.favorites.filter(function(item) {
          return item !== value.decore
      })

      console.log("REMOVING!",favorites);
      localStorage.setItem('uchronia_favorites',JSON.stringify(favorites))

      }else{
        console.log("FAVORITES: ", favorites);
        favorites.push(value.decore);
        localStorage.setItem('uchronia_favorites',JSON.stringify(favorites))
      }
      console.log(state,value);
      
      this.commit('set_favorites',favorites);
    }
  },
  mutations: {
    set_favorites(state,favorites){
     state.favorites = favorites;
    },
    set_intro_skipped(state, value) {
      state.intro_skipped = value;
    },
    set_teased_background_color(state, value) {
      state.teased_background_color = value;
    },
    set_chapters_visible(state, value) {
      state.chapters_visible = value;
    },
    set_active_chapter(state, value) {
      state.active_chapter = value;
    },
    set_restart_journey(state, value) {
      state.restart_journey = value;
    },
    
    set_paralax_offset(state, value) {
      state.paralax_offset = value;
    },
    set_filtered_chapter_contents(state, value) {
      state.filtered_chapter_contents = value;
    },
    set_shared(state,shared){
      state.shared = shared;
    },
    set_share_target(state,share_target){
      state.share_target = share_target;
    },
    set_hash(state,hash){
      if(router.currentRoute.hash !== "#"+hash) {
        router.push({hash:hash});        
      }
    },
    set_journey_active(state, value) {
      state.journey_active = value;

      /*console.log("");
      console.log("JOURNEY ACTIVE");
      console.log(value);
      console.log("");*/

      if(value) {
        document.documentElement.classList.add("journey-active");
      }else{
        document.documentElement.classList.remove("journey-active");
      }
    },
    set_journey_started(state, value) {
      state.journey_started = value;

      if(value) {
        document.documentElement.classList.add("journey-started");
      }else{
        document.documentElement.classList.remove("journey-started");

      }
    },
    set_current_route(state, current_route) {
      state.current_route = current_route;
      state.journey_active = false;
      state.history.push(current_route);

      //this.dispatch("tag",current_route);
    },
    set_data_json(state, data) {
      state.data_jsons[data.slug] = data.data;
    },
    set_has_touch(state, has_touch) {
      state.has_touch = has_touch;
      if (has_touch == true) document.body.classList.add("has-touch");
    },
    set_meta_layer(state, layer_id) {
      state.meta_layer_id = layer_id;
    },
    set_toast_manager(state, toast_manager) {
      state.toast_manager = toast_manager;
    },
    set_lightbox_content(state, lightbox_content) {
      state.lightbox_content = lightbox_content;
    },
    set_cookie_consent_accepted(state, cookie_consent_accepted) {
      state.cookie_consent_accepted = cookie_consent_accepted;

      const storage = window.localStorage;
      storage.setItem("uchronia_cc", cookie_consent_accepted);
    },
    set_event_state(state, value) {
      state.event_state = value;
    },
    set_event_streams(state, value) {
      state.event_streams = value;
    }
  },
};

const User = {
  state: {
    settings: null,
    environment: null,

    restBaseUrl: null,
    restToken: null,
    restHeaders: null,
    restResponse: "",

    jsonBaseUrl: null,

    loggedIn: true,
    loggedInUser: null,
    loggedInUserRole: null,
  },
  getters: {
    getJsonBaseUrl: (state) => state.jsonBaseUrl,
    getLoggedIn: (state) => state.loggedIn,
    getUser: (state) => state.loggedInUser,
    getUserRole: (state) => state.loggedInUserRole,
  },
  mutations: {
    setupData(state) {
      let settings = process.env.VUE_APP_WP_ENV;
      //fallback
      if (!settings){
        settings =
          '{"environments":[{"name":"local","hosts":["uchronia-app.localhost"],"base":"http://uchronia-cms.localhost/wp-json","token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC91Y2hyb25pYS1jbXMubG9jYWxob3N0IiwiaWF0IjoxNjE3ODk1ODkzLCJuYmYiOjE2MTc4OTU4OTMsImV4cCI6MTY4MDk2Nzg5MywiZGF0YSI6eyJ1c2VyIjp7ImlkIjoiNCJ9fX0.AUBF0tlshqYUtzXIe-a2ZuRmiIXnCx7sjDp4_jXfuVM","jsonbase":"https://staging-cms.digitalimpress.biz/wp-content/themes/uchronia/assets/jsons/getData.php?file="},{"name":"staging","hosts":["localhost:8080","localhost:8081","localhost:8082","staging-cms.digitalimpress.biz","staging.digitalimpress.biz"],"base":"https://staging-cms.digitalimpress.biz/wp-json","token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvc3RhZ2luZy1jbXMuZGlnaXRhbGltcHJlc3MuYml6IiwiaWF0IjoxNjE3OTY2NzI0LCJuYmYiOjE2MTc5NjY3MjQsImV4cCI6MTY4MTAzODcyNCwiZGF0YSI6eyJ1c2VyIjp7ImlkIjoiNCJ9fX0.YuVnWNKiA1ZgUrx2F6hbHSztOyaFu9rm0nNE0B2t5Sw","jsonbase":"https://staging-cms.digitalimpress.biz/wp-content/themes/uchronia/assets/jsons/getData.php?file="},{"name":"production","hosts":["test-app.digitalimpress.biz","digitalimpress.biz"],"base":"https://cms.digitalimpress.biz/wp-json","token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvY21zLmRpZ2l0YWxpbXByZXNzLmJpeiIsImlhdCI6MTYxNzg5NjE5MywibmJmIjoxNjE3ODk2MTkzLCJleHAiOjE2ODA5NjgxOTMsImRhdGEiOnsidXNlciI6eyJpZCI6IjQifX19.HSiizfTA5_H1W6REGfJtUzH-3EAwoyc4Kg7Tyd5s_58","jsonbase":"https://cms.digitalimpress.biz/wp-content/themes/uchronia/assets/jsons/getData.php?file="}]}';
          console.log("NO SETTINGS");
        }
      state.settings = JSON.parse(settings);
    },
    locateEnvironment(state) {
      const host = location.host;

      state.settings.environments.forEach((environment) => {
        let name = environment.name;
        let hosts = environment.hosts;

        if (hosts.includes(host)) {
          state.environment = name;
        }
      });
    },
    setJsonBase(state) {
      state.settings.environments.forEach((environment) => {
        if (environment.name === state.environment) {
          state.jsonBaseUrl = environment.jsonbase;
        }
      });
    },
    setRestBase(state) {
      state.settings.environments.forEach((environment) => {
        if (environment.name === state.environment) {
          state.restBaseUrl = environment.base;
        }
      });
    },
    setRestToken(state) {
      state.settings.environments.forEach((environment) => {
        if (environment.name === state.environment) {
          state.restToken = environment.token;
        }
      });
    },
    setRestHeaders(state) {
      state.restHeaders = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + state.restToken,
      };
    },
    setLoggedIn(state, params) {
      state.loggedIn = params.status;
      window.loggedIn = state.loggedIn;
    },
    setUser(state, params) {
      state.loggedInUser = params;
      window.sessionStorage.setItem("udiu", JSON.stringify(params));

      if(params!=null){
        state.loggedInUserRole = params.role; 
      }
    },
    setRestResponse(state, params) {
      state.restResponse = params.data;
    },
  },
  actions: {
    /*tag(context,tag){
      
      //console.log(context, tag);
      
      

      //https://legacydocs.hubspot.com/docs/methods/tracking_code_api/javascript_events_api
      

       //TRACKING?
       let _hsq = window._hsq = window._hsq || [];
       
       console.log("tracking "+to.path);
       _hsq.push(['setPath', to])
       _hsq.push(['trackPageView']);
    },*/
    initialize(context) {
      context.commit("setupData");
      context.commit("locateEnvironment");
      context.commit("setJsonBase");
      context.commit("setRestBase");
      context.commit("setRestToken");
      context.commit("setRestHeaders");

      //check and set user data
      let udiu = JSON.parse(window.sessionStorage.getItem("udiu"));

      if (udiu) {
        context.commit("setUser", udiu);
        context.commit("setLoggedIn", { status: true });
      } else {
        context.commit("setUser", null);
        context.commit("setLoggedIn", { status: true });
      }

      // console.log("==============");
      // console.log("STORE INITIALIZE");
      // console.log("restToken: ", context.state.restToken);
      // console.log("environment: ", context.state.environment);
      // console.log("restBaseUrl: ", context.state.restBaseUrl);
      // console.log("jsonBaseUrl: ", context.state.jsonBaseUrl);
      // console.log("==============");
    },
    login(context, user) {
      context.commit("setLoggedIn", { status: true });
      context.commit("setUser", user);
    },
    logout(context) {
      window.localStorage.removeItem("udiu");
      context.commit("setLoggedIn", { status: false });
      context.commit("setUser", null);
    },
    request(context, data) {
      let route = "";
      let method = "";

      switch (data.key) {
        case "register":
          route = "/jwt-auth/v1/register-new-candidate";
          method = "post";
          break;
        case "reset":
          route = "/jwt-auth/v1/reset-customer-password";
          method = "post";
          break;
        case "forgot":
          route = "/jwt-auth/v1/forgot-customer-password";
          method = "post";
          break;
        case "login":
          route = "/jwt-auth/v1/login-customer";
          method = "post";
          break;
        case "get_fav":
          route = "/jwt-auth/v1/customer-decore-favorites";
          method = "get";
          break;
        case "update_fav":
          route = "/jwt-auth/v1/update-customer-decore-favorites";
          method = "post";
          break;
        case "update":
          route = "/jwt-auth/v1/update-customer-profile";
          method = "post";
          break;
        case "customers":
          route = "/jwt-auth/v1/customers";
          method = "get";
          break;
      }

      const base = context.state.restBaseUrl;
      const headers = context.state.restHeaders;
      // const url = base + route;

      // console.log("==============");
      // console.log("STORE: REQUEST");
      // console.log("key: ", data.key);
      // console.log("params: ", data.params);
      // console.log("method: ", method);
      // console.log("base: ", base);
      // console.log("route: ", route);
      // console.log("url: ", url);
      // console.log("headers: ", headers);
      // console.log("==============");

      console.log(base);
      
      const request = axios.create({
        baseURL: base,
        headers: headers,
        params: data.params,
      });

      if (method == "get") {
        request
          .get(route)
          .then((result) => {
            // console.log(result.data);
            // console.log(JSON.stringify(result.data, undefined, 2));

            context.commit("setRestResponse", { data: result.data });
          })
          .catch((error) => {
            // console.log(error);
            // console.log(
            //   JSON.stringify("Error: " + error.message, undefined, 2)
            // );

            context.commit("setRestResponse", { data: error });
          });
      }

      if (method == "post") {
        request
          .post(route)
          .then((result) => {
            // console.log(result.data);
            // console.log(JSON.stringify(result.data, undefined, 2));

            context.commit("setRestResponse", { data: result.data });

            //identify and handle login
            if (result.data.code == "login_done") {
              context.commit("setUser", result.data.user);
              context.commit("setLoggedIn", { status: true });
            }
            //identify and handle update
            if (result.data.code == "profile_updated") {
              context.commit("setUser", result.data.user);
            }
          })
          .catch((error) => {
            // console.log(error);
            // console.log(
            //   JSON.stringify("Error: " + error.message, undefined, 2)
            // );

            context.commit("setRestResponse", { data: error });
          });
      }
    },
  },
};

export default new Vuex.Store({
  modules: {
    user: User,
    base: Base,
  },
});

<template>
  <div
    class="toast-messages"
    :class="{ 'toast-messages--visible': toasts.length !== 0 }"
  >
    <div class="toast-messages__container" v-if="toasts.length > 0">
      <ToastMessage
        v-for="item in toasts"
        :key="item.created_at"
        :content="item"
        @remove="removeToast(item)"
      />
    </div>
  </div>
</template>

<script>
import ToastMessage from "./ToastMessage";
export default {
  name: "ToastMessenger",
  components: {
    ToastMessage
  },
  data() {
    return {
      toasts: [
        /*{
          created_at: "1",
          label: "TEST!12122121",
          lifespan: 1,
          type: "error"
        },
        {
          created_at: "222",
          label: "TEST!12122121",
          lifespan: 2,
          type: "warning"
        },
        {
          created_at: "221s2",
          label: "TEST!12122121",
          lifespan: 2,
          "body":"Consectetur veniam dolor voluptate adipisicing dolor sint pariatur. Eu sunt tempor fugiat ut nisi eiusmod proident. Ipsum qui laboris ut sint ipsum aliqua dolor dolor dolore eu qui excepteur. Cupidatat enim in eiusmod quis do elit incididunt cillum cupidatat aute laboris reprehenderit aute. Eiusmod commodo amet eiusmod incididunt officia qui ipsum veniam non quis amet ex.",
          type: "success"
        }*/
      ]
    };
  },
  methods: {
    add(toast) {
      //const types = ["error", "warning", "ok"];

      //if (Math.random() > 0.2) toast.body = undefined;

      //toast.type = types[Math.floor(Math.random() * (types.length - 1))];
      if (!toast.type) toast.type = "ok";
      toast.created_at = new Date().getTime() + Math.random();
      this.toasts.push(toast);

      if (this.toasts.length > 5) {
        this.removeOldest();
      }

      if (toast.life) {
        setTimeout(() => {
          this.removeToast(toast);
        }, toast.life * 1000);
      }
    },
    removeToast(toast) {
      const arr = this.toasts.filter(function(item) {
        return item.created_at !== toast.created_at;
      });

      this.toasts = arr;
    },
    removeOldest() {
      this.toasts.shift();
    }
  },
  watch: {
    toasts() {}
  },

  created() {
    this.$store.commit("set_toast_manager", this);
  }
};
</script>

<template>
  <div class="lightbox">
    <div class="lightbox__container" v-if="content">
      <div class="lightbox__bg"></div>
      <div class="lightbox__close" @click="onCloseClick">
        <inline-svg :src="require('../../assets/svg/icon_close.svg')" />
      </div>
      <div class="lightbox__content" ref="content">
        <div class="lightbox__content-wrapper">
          <h1>{{ content.headline }}</h1>
          <h1 v-if="content.subline">{{ content.subline }}</h1>
          <div class="lightbox__iframe-wrapper" v-if="content.vimeo">
            <iframe
              :src="
                'https://player.vimeo.com/video/' +
                content.vimeo +
                '?h=2b4eadaddc&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
              "
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              title="impress"
            ></iframe>
          </div>

          <div
            class="lightbox__iframe-wrapper"
            v-if="content.home && userLoggedIn"
          >
            <!---vimeo not logged in -->
            <iframe
              :src="
                'https://player.vimeo.com/video/' +
                content.vimeo +
                '?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
              "
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              title="impress Teststream"
            ></iframe>
          </div>

          <div
            class="lightbox__iframe-wrapper"
            v-if="content.home && !userLoggedIn"
          >
            <!---vimeo not logged in -->
            <iframe
              :src="
                'https://player.vimeo.com/video/' +
                content.vimeo +
                '?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
              "
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              title="impress Teststream"
            ></iframe>
          </div>

          <div
            class="lightbox__image-wrapper"
            v-if="content.image"
            ref="image_wrapper"
            @mousemove="onMouseMove"
            @touchstart="onTouchStart"
            @touchmove="onTouchMove"
          >
            <!--zoom feature-->
            <div class="lightbox__image-wrapper-images" @click="toggleZoomMode">
              <img :src="content.image" />
              <img
                v-if="zoomMode"
                class="lightbox__image-wrapper-zoomimg"
                :src="content.zoom"
                ref="zoom_image"
              />
              <span v-if="content.zoom && !zoomMode">click to zoom</span>
              <span v-if="content.zoom && hasTouch && zoomMode"
                >drag to move</span
              >
              <span v-if="content.zoom && !hasTouch && zoomMode"
                >move mouse</span
              >
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import Footer from "../meta/Footer.vue";

export default {
  name: "Lightbox",
  mixins: [],
  components: { Footer },
  data() {
    return {
      debugObj: undefined,
      zoomMode: false,
      content: undefined,
      startTouch: undefined,
      imagePos: { x: 0, y: 0 },
      dragOffset: undefined,
      currentPos: { x: 0, y: 0 }
    };
  },
  methods: {
    moveZoomImage(x, y, animated) {
      if (x && y) {
        this.currentPos = { x: x, y: y };
      } else {
        x = this.currentPos.x;
        y = this.currentPos.y;
      }

      const wrapperBounds = this.$refs.image_wrapper.getBoundingClientRect();
      const zoomBounds = this.$refs.zoom_image.getBoundingClientRect();
      const maxOffsetX = zoomBounds.width - wrapperBounds.width;

      if (zoomBounds.width < wrapperBounds.width) {
        x = -maxOffsetX * 0.5;
      }

      let time = 0;
      if (animated) time = 1;

      gsap.to(this.$refs.zoom_image, {
        duration: time,
        x: x,
        y: y
      });
    },
    onTouchMove(e) {
      if (!this.hasTouch || !this.zoomMode || !this.startTouch) return;
      e.preventDefault();

      const currentTouch = e.touches[0];

      let targetX = this.startTouch.x - currentTouch.clientX;
      let targetY = this.startTouch.y - currentTouch.clientY;

      const wrapperBounds = this.$refs.image_wrapper.getBoundingClientRect();
      const zoomBounds = this.$refs.zoom_image.getBoundingClientRect();

      const maxOffsetY = zoomBounds.height - wrapperBounds.height;
      const maxOffsetX = zoomBounds.width - wrapperBounds.width;

      if (targetX < 0) targetX = 0;
      if (targetY < 0) targetY = 0;

      if (targetY > maxOffsetY) targetY = maxOffsetY;
      if (targetX > maxOffsetX) targetX = maxOffsetX;

      this.dragOffset.x = targetX;
      this.dragOffset.y = targetY;

      this.moveZoomImage(-targetX, -targetY, false);
    },
    initTouchOffset() {
      if (!this.hasTouch || !this.zoomMode) return;
      if (!this.dragOffset) {
        const wrapperBounds = this.$refs.image_wrapper.getBoundingClientRect();

        const maxOffsetY = wrapperBounds.height * 2 - wrapperBounds.height;
        const maxOffsetX = wrapperBounds.width * 2 - wrapperBounds.width;

        this.dragOffset = {
          x: maxOffsetX * 0.5,
          y: maxOffsetY * 0.5
        };

        this.$nextTick(() => {
          gsap.to(this.$refs.zoom_image, {
            x: -this.dragOffset.x,
            y: -this.dragOffset.y,
            duration: 0
          });
        });
      }
    },
    onTouchStart(e) {
      if (!this.hasTouch || !this.zoomMode) return;

      this.startTouch = {
        x: this.dragOffset.x + e.touches[0].clientX,
        y: this.dragOffset.y + e.touches[0].clientY
      };

      console.log(this.startTouch);
    },
    onMouseMove(e) {
      if (this.hasTouch) return;

      if (this.zoomMode) {
        const wrapperBounds = this.$refs.image_wrapper.getBoundingClientRect();

        let percentX;
        let percentY;

        const mouseX = e.clientX - wrapperBounds.x;
        const mouseY = e.clientY - wrapperBounds.y;

        percentX = mouseX / wrapperBounds.width;
        percentY = mouseY / wrapperBounds.height;

        if (percentY > 1) percentY = 1;
        if (percentY < 0) percentY = 0;

        if (percentX > 1) percentX = 1;
        if (percentX < 0) percentX = 0;

        const zoomBounds = this.$refs.zoom_image.getBoundingClientRect();

        const maxOffsetX = zoomBounds.width - wrapperBounds.width;
        const maxOffsetY = zoomBounds.height - wrapperBounds.height;

        this.debugObj = {};
        this.debugObj.percentX = percentX;
        this.debugObj.percentY = percentY;
        this.debugObj.width = maxOffsetX;

        let targetX = -maxOffsetX * percentX;

        if (zoomBounds.width < wrapperBounds.width) {
          targetX = -maxOffsetX * 0.5;
        }

        this.moveZoomImage(targetX, -maxOffsetY * percentY, true);
      }
    },
    toggleZoomMode() {
      if (this.content.zoom) {
        this.dragOffset = undefined;
        this.zoomMode = !this.zoomMode;

        if (this.zoomMode) {
          this.initTouchOffset();
          this.$nextTick(() => {
            this.moveZoomImage(0, 0, false);
          });
        } else {
          gsap.set(this.$refs.zoom_image, { clearProps: "all" });
        }
      }
    },
    fadeContents() {
      this.$nextTick(() => {
        if (this.$refs.content) {
          gsap.set(this.$el, { autoAlpha: 0 });
          gsap.to(this.$el, { duration: 0.25, autoAlpha: 1 });

          gsap.set(this.$refs.content, { y: 50, alpha: 0 });
          gsap.to(this.$refs.content, {
            delay: 0.25,
            y: 0,
            duration: 0.6,
            alpha: 1,
            clearProps: "all"
          });
        }
      });
    },
    onCloseClick() {
      gsap.to(this.$el, { autoAlpha: 0, duration: 0.6 });
      this.$store.commit("set_lightbox_content", undefined);
    }
  },

  computed: {
    stageScale() {
      return this.$screen.lastChange;
    },
    hasTouch() {
      return this.$store.getters.hasTouch;
    },
    active() {
      return this.$store.getters.lightboxContent;
    },
    userLoggedIn() {
      return this.$store.getters.getLoggedIn;
    }
  },
  watch: {
    stageScale() {
      if (this.$refs.zoom_image) {
        this.moveZoomImage(1, 1, false);
      }
    },
    zoomMode() {
      if (this.zoomMode) {
        this.$nextTick(() => {
          this.initTouchOffset();
          this.moveZoomImage(undefined, undefined, false);
        });
      }
    },
    active() {
      this.content = this.active;

      if (!this.active) {
        gsap.set("body", { css: { overflow: "auto" } });
      } else {
        gsap.set("body", { css: { overflow: "hidden" } });
      }
    },
    content() {
      if (this.content) {
        this.currentPos = { x: 0, y: 0 };
        //this.zoomMode = this.content.zoom !== undefined;
        this.$el.scrollTo(0, 0);
        this.fadeContents();

        let cat = 'Video: ';
        if(!this.content.vimeo) cat = "Image: "


        this.$gtag.event("Lightbox opened", {
          event_category: cat + this.content.headline
        });
      } else {
        this.zoomMode = false;
      }
    }
  },
  beforeDestroy() {},
  mounted() {}
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Rooms from "../views/Rooms.vue";
import User from "../views/User.vue";
import Decors from "../views/Decors.vue";
import Trends from "../views/Trends.vue";
import Videos from "../views/Videos.vue";
import Meta from "../views/Meta.vue";

Vue.use(VueRouter);



const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  { path: "/videos", component: Videos},
  { path: "/imprint", component: Meta},
  { path: "/privacy-policy", component: Meta},
  { path: "/terms-conditions", component: Meta},
  {
    path: "/rooms/:room?",
    name: "Rooms",
    component: Rooms,
    props: true,
  },
  { path: "/decors/:decor?", component: Decors, props: true },
  { path: "/trends/:trend?", component: Trends, props: true },
  { path: "/user/:subroute?", component: User, props: true},
  { path: '*', redirect: '/' }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes,
});

export default router;

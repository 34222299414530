<template>
  <div class="view user" :class="{ ['user--' + currentSubRoute]: currentSubRoute }">
    <div class="container" v-if="contentJson">
      <ContentCard ref="contentcard">
        <template v-if="currentSubRoute === 'profile'">
          <h1>Hey there!</h1>
          <h2>Welcome to your personal UCHRONIA!</h2>

          <p>
            You can save vour favorite decors for
            later by clicking on this button in the
            <router-link to="/decors">decor section.</router-link>
          </p>
          <Button class="button--small">
            <inline-svg :src="require('@/assets/svg/icon_heart.svg')" />
            LIKE
          </Button>
          <p>We wish you an inspiring time here in<br />UCHRONIA!</p>
        </template>

        <template v-if="currentSubRoute === 'profile'">
          <!--<button @click="getUsers" style="width: 200px">Get Users</button>-->
          <UserFavorites class="js-user-module" />
        </template>
      </ContentCard>

    </div>
  </div>
</template>
<script>
import gsap from "gsap";
import DataMixin from "@/mixins/DataMixin.js";
import ContentCard from "@/components/content/ContentCard";
import Button from "@/components/form/Button";
import UserFavorites from "@/components/user/UserFavorites";

export default {
  name: "User",
  components: {
    /*DisplayStates,*/
    ContentCard,
    UserFavorites,
    Button
  },
  mixins: [DataMixin],
  data() {
    return {
      screenHeight: 2000,
      activeTab: 2,
      allowedRoutes: [
        "request",
        "reset-password",
        "login",
        "forgot-password",
        "favorite",
        "customers"
      ],
      currentSubRoute: undefined,
      restOutput: "restOutput \r\n",
      users: [],
      activeUser: undefined
    };
  },
  props: {
    subroute: undefined
  },
  methods: {
    onUserSalesActivate(active) {
      this.activeUser = active;
    },

    onTabActivation(active) {
      this.activeTab = active;
      this.$refs.contentcard.show();

      this.onResize(this.activeTab);

      gsap.delayedCall(0.5, () => {
        this.onResize(this.activeTab);
      });

      //this.activeTab =
    },
    onResize(tab) {
      const modules = this.$el.getElementsByClassName("js-user-module");
      var arr = Array.from(modules);
      let height = window.innerHeight;
      let testHeight = height;

      arr.forEach(el => {
        testHeight = el.getBoundingClientRect().height + 180;
        if (testHeight > height) height = testHeight;
      });

      if (tab) {
        height = window.innerHeight;

        testHeight = arr[tab].getBoundingClientRect().height + 180;
        if (testHeight > height) height = testHeight;
      }

      if (this.$el) {

        if (window.innerWidth > 770) {
          gsap.set(this.$el, { height: height + "px" });
        } else {
          gsap.set(this.$el, { height: "" });
        }
      }
    },
    checkRoute() {
      this.currentSubRoute = this.subroute;
      if (this.allowedRoutes.includes(this.subroute)) {
        //dingdong
      } else {
        //not allowed...
      }

      this.$nextTick(this.onResize);
    },
    updateRestOutput: function (output) {
      this.restOutput += "\r\n" + output;
    },
    getUsers() {
      if ((this.isEditor || this.salesManager) && this.$store.state.user.loggedInUser) {
        const data = { key: "customers", params: {} };
        this.$store.dispatch("request", data);
      }
    }
  },
  watch: {
    activeTab() {
      if (this.activeTab === 2) this.getUsers();
      this.$nextTick(() => {
        //this.onResize(this.activeTab);
      });
    },
    contentJson() {
      this.$nextTick(this.onResize);

      this.$nextTick(() => {
        gsap.set('.js-user-module', { x: 30 });
        gsap.to('.js-user-module', { delay: .2, duration: 1, ease: "Expo.easeOut", x: 0 });
      })
    },
    screenWidth() {
      this.onResize();
    },
    subroute() {
      this.checkRoute();
    },
    response(newResponse) {
      newResponse;
      console.log("==============");
      console.log("USER VIEW: watch request response");
      console.log("code: ", newResponse.code);
      console.log("message: ", newResponse.message);
      console.log("response: ", newResponse);
      console.log("==============");

      if (newResponse.code == "customers_data") {
        newResponse.data.forEach(item => {
          item;
          //console.log(item.favorite_decores);
        });

        //this.users = newResponse.data.filter(user => user.favorite_decores !== "");
        this.users = newResponse.data;
      }
    },
    loggedIn(newloggedIn) {
      newloggedIn;
      //console.log("==============");
      //console.log("USER VIEW: watch login");
      //console.log("newloggedIn: ", newloggedIn);
      //console.log("==============");
    },
    loggedInUser(newloggedInUser) {
      newloggedInUser;
      //console.log("==============");
      //console.log("USER VIEW: watch user");
      //console.log("user: ", newloggedInUser);
      //console.log("==============");
    }
  },
  computed: {
    screenWidth() {
      //ungeil wie nur was...
      return this.$screen.width;
    },
    tabContent() {
      return this.$store.getters
        .getCachedData("user")
        .contents.filter(content => content.slug === "favorites")[0].tabs;
    },
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },
    loggedInUser() {
      return JSON.parse(JSON.stringify(this.$store.state.user.loggedInUser));
    },
    response() {
      return JSON.parse(JSON.stringify(this.$store.state.user.restResponse));
    },
    salesManager() {
      return this.$store.state.user.loggedInUserRole == "sales-manager";
    },
    isEditor() {
      return this.$store.state.user.loggedInUserRole == "editor";
    },
    customerContent() {
      return this.$store.getters
        .getCachedData("user")
        .contents.filter(content => content.slug === "customers");
    }
  },
  mounted() {
    this.getUsers();
    this.fetchData("user");
    this.checkRoute();


  }
};
</script>

var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.position)?_c('div',{directives:[{name:"view",rawName:"v-view",value:(_vm.viewHandler),expression:"viewHandler"}],staticClass:"float-cluster__item",class:( _obj = {
    'float-cluster__item--active': _vm.isActive
  }, _obj['float-cluster__item--' + _vm.content.teaser_style] = true, _obj ),style:({
    left: _vm.position.x + '%',
    top: _vm.position.y + '%',
    width: _vm.position.width + '%',
    height: _vm.teaserHeight
  })},[_vm._l((_vm.content.teaser_images),function(item,index){
  var _obj;
return _c('div',{key:index,staticClass:"float-cluster__item-container",class:( _obj = {}, _obj['float-cluster__item-container--' + item.type] = true, _obj ),on:{"mouseenter":_vm.onMouseOver,"mouseleave":_vm.onMouseOut}},[_c('img',{attrs:{"src":item.file},on:{"load":_vm.onImageLoaded}})])}),_c('div',{staticClass:"float-cluster__item-text"},[_c('h1',[_vm._v(_vm._s(_vm.content.name))]),_c('h2',[_vm._v(_vm._s(_vm.content.subline))])])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
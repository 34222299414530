import gsap from "gsap";
import * as PIXI from "pixi.js";
import ColorPalette from "./../helper/ColorPalette.js";
import Orb from "./../objects/Orb.js";

export default class GradientBackground {
  pixi = new PIXI.Container();
  bounds = { width: 1000, height: 800 };
  //
  constructor() {
    this.initScene();
  }
  initScene() {
    const colorPalette = new ColorPalette();

    const rectangle = new PIXI.Graphics();
    rectangle.beginFill(0xffffff, 1);
    rectangle.drawRect(0, 0, this.bounds.width, this.bounds.height);
    rectangle.endFill();

    this.background = rectangle;

    this.pixi.addChild(rectangle);

    /*let colorMatrix = new PIXI.filters.ColorMatrixFilter();
    this.pixi.filters = [colorMatrix];
    colorMatrix.contrast(0.2);*/

    const orb0 = new Orb('#ffffff', true);

    this.orbCenter = orb0;

    const orb1 = new Orb(colorPalette.getColor(0));
    this.pixi.addChild(orb1.pixi);

    const orb2 = new Orb(colorPalette.getColor(1));
    this.pixi.addChild(orb2.pixi);

    const orb3 = new Orb(colorPalette.getColor(2));
    this.pixi.addChild(orb3.pixi);

    const orb4 = new Orb(colorPalette.getColor(3));
    this.pixi.addChild(orb4.pixi);

    this.pixi.addChild(orb0.pixi);
    this.pixi.addChild(orb0.pixi2);

    this.vignette = orb0.pixi2;

    orb0.pixi.alpha = .25;
    orb0.pixi2.alpha = .25;

    const orbs = [orb1, orb2, orb3, orb4];

    this._orb1 = orb1;
    this._orb2 = orb2;
    this._orb3 = orb3;
    this._orb4 = orb4;

    this.orbCenter.pixi.x = this.bounds.width * .5;
    this.orbCenter.pixi.y = this.bounds.height * .5;

    orbs.forEach(orb => {
      orb.pixi.x = Math.random() * this.bounds.width;
      orb.pixi.y = Math.random() * this.bounds.height;
      orb.pixi.alpha = 1;//Math.random();
      orb.pixi.width = 1000;
      orb.pixi.height = 1000;
      this.randomizeOrb(orb);
    });

    gsap.set(this.background,{pixi:{tint:'#ffd543'}});
  }

  setColors(colors){
    if(colors) {
      this._orb1.setColor(colors.colors[0]);
      this._orb2.setColor(colors.colors[1]);
      this._orb3.setColor(colors.colors[2]);
      this._orb4.setColor(colors.colors[3]);
      
      gsap.to(this.background,{pixi:{tint:colors.backgroundColor}});
    }else{      
      const colorChoices = ["#ed6b15","#7fcadf","#278fb2","#e56ea8"];

      gsap.to(this.background,{pixi:{tint:'#ffd543'}});

      this._orb1.setColor(colorChoices[0]);
      this._orb2.setColor(colorChoices[1]);
      this._orb3.setColor(colorChoices[2]);
      this._orb4.setColor(colorChoices[3]);      
    }
  }

  randomizeOrb(orb) {
    const targetWidth = 500 + Math.random() * 300;
    const duration = 6 + Math.random() * 3;
    const alpha = 1;//.8 + Math.random() * .2;

    gsap
      .to(orb.pixi, {
        duration: duration,
        width: targetWidth,
        height: targetWidth,
        x: 100 + Math.random() * (this.bounds.width - 50),
        y: 100 + Math.random() * (this.bounds.height - 50),
        alpha: alpha,
        ease: "Sine.easeInOut",
      })
      .then(() => {
        this.randomizeOrb(orb);
      });
  }

  setBounds(_w, _h) {
    this.bounds = { width: _w / 2, height: _h / 2 };
    const scale = _w / this.bounds.width;

    this.background.width = this.bounds.width;
    this.background.height = this.bounds.height;

    this.orbCenter.pixi.x = this.bounds.width * .5;
    this.orbCenter.pixi.y = this.bounds.height * .5;

    this.orbCenter.pixi.width = this.bounds.width * .8;
    this.orbCenter.pixi.height = this.bounds.width * .8;

    this.orbCenter.pixi2.width = _w;
    this.orbCenter.pixi2.height = _h;

    this.pixi.scale.set(scale);
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],staticClass:"decors-navigation",class:{
    'decors-navigation--debug': _vm.debugMode,
    'decors-navigation--mobile': _vm.mobileMode,
  },on:{"scroll":_vm.onMobileScroll}},[_c('div',{staticClass:"decors-navigation__headline"},[_c('h1',{staticClass:"headline-1"},[_vm._v(_vm._s(_vm.headline))]),_c('h2',[_vm._v(_vm._s(_vm.subline))])]),_c('div',{ref:"container",staticClass:"decors-navigation__item-container",style:({
      transform:
        'translateX(' +
        _vm.containerPosition.x +
        'px) translateY(' +
        _vm.containerPosition.y +
        'px)',
    })},[(_vm.debugMode)?_c('div',{staticClass:"decors-navigation__debug"},[_c('Button',{on:{"click":_vm.logPositions}},[_vm._v("log positions")])],1):_vm._e(),_vm._l((_vm.circles),function(item,index){return _c('div',{key:index,staticClass:"decors-navigation__item",style:(_vm.getCircleStyle(item)),on:{"mouseover":function($event){return _vm.scaleCircle(item, 2)},"mouseout":function($event){return _vm.scaleCircle(item, 1)},"click":function($event){return _vm.onCircleClick(index)}}},[_c('div',{staticClass:"decors-navigation__item-inner"},[_c('img',{attrs:{"src":_vm.getImageUrl(index),"alt":""},on:{"load":function($event){return _vm.onImageLoaded(index, item)}}}),_c('div',{staticClass:"decors-navigation__item-text"},[_c('h1',[_vm._v(_vm._s(_vm.getHeadline(index)))]),_c('h2',[_vm._v(_vm._s(_vm.getSubline(index)))])])])])})],2),_c('div',{ref:"center",staticClass:"decors-navigation__center"})])}
var staticRenderFns = []

export { render, staticRenderFns }
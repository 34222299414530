import Canvg from "canvg";
import * as PIXI from "pixi.js";
export default {
  data() {
    return {};
  },
  created: function() {},
  methods: {
    downloadCSV(data) {
      let csv = "Decor                                                            ,Color                                                            \n";
      data.items.forEach((row) => {
        csv += row.join(",");
        csv += "\n";
      });

      csv += "\n";
      csv += "Name: "+data.name+", "+"\n"+"Company: "+data.company+", "+"\n"+"E-Mail: "+data.mail+", "    

      const filename = data.name.replace(" ","_").toLowerCase()+"_"+data.company.replace(" ","_").toLowerCase()
      
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";
      hiddenElement.download = filename+"_favorites.csv";
      hiddenElement.click();
    },
    createPngFromPixi(pixi, filename, container) {
      const renderer = pixi.renderer;

      let display = pixi.stage;
      if (container) display = container;

      renderer.plugins.extract.canvas(display).toBlob(function(b) {
        var a = document.createElement("a");
        document.body.append(a);
        a.download = filename;
        a.href = URL.createObjectURL(b);
        a.click();
        a.remove();
      }, "image/png");
    },
    createPngTextureFromPixi(pixi, filename, container, full, custom) {
      let rect = new PIXI.Rectangle(
        0,
        0,
        window.innerWidth,
        window.innerHeight
      );

      if (full)
        rect = new PIXI.Rectangle(0, 0, container.width, container.height);
      if (custom) rect = new PIXI.Rectangle(0, 0, custom.w, custom.h);

      var texture = pixi.renderer.generateTexture(
        container,
        PIXI.SCALE_MODES.LINEAR,
        1,
        rect
      );

      const renderer = pixi.renderer;
      renderer.plugins.extract.canvas(texture).toBlob(function(b) {
        var a = document.createElement("a");
        document.body.append(a);
        a.download = filename;
        a.href = URL.createObjectURL(b);
        a.click();
        a.remove();
      }, "image/png");
    },
    async createPngFromSvg(svg, filename,skipStyles,w,h) {
      const outer = document.createElement("div");

      outer.appendChild(svg.cloneNode(true));

      if(!skipStyles) {
        let strongText = outer.getElementsByClassName("strong");
        for (var i = 0; i < strongText.length; i++) {
          strongText[i].style["font-weight"] = "bold";
        }
        
        if(outer.getElementsByTagName("rect")[0]) outer.getElementsByTagName("rect")[0].style.display = "block";
      }

      const inner = outer.innerHTML;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      
      if(w) {
        ctx.canvas.width = w;
        ctx.canvas.height = h;
      }else{
        ctx.canvas.width = 900;
        ctx.canvas.height = 1024;
      }
      
      const canvg = Canvg.fromString(ctx, inner);
      await canvg.render();

      const canvasData = canvas.toDataURL("image/png");

      const a = document.createElement("a");
      document.body.append(a);
      a.download = filename;
      a.href = canvasData;
      a.click();
      a.remove();
    }
  }
};

<template>
  <div class="color-module">
    

    <div class="color-module__container">
      <!--colors-->
      <div class="color-module__container-headline" v-if="headline">
        {{headline}}
      </div>  
      
      <div
        class="color-module__item"
        v-for="(item, index) in this.colors"
        :key="index"
      >
        <div class="color-module__item-tooltip">
          {{ item.name }}
        </div>
        <div
          class="color-module__item-circle"
          :style="{ backgroundColor: item.hex }"
        ></div>
      </div>

      <!--patterns-->
      <div
        class="color-module__item color-module__item--pattern"
        v-for="(pattern, index2) in this.patterns"
        :key="'p' + index2"
      >
        <div class="color-module__item-tooltip">
          {{ pattern.name }}
        </div>

        <div
          class="color-module__item-circle"
          :style="{ backgroundImage: 'url(' + pattern.img + ')' }"
          @click="navigateToPattern(pattern.slug)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorModule",
  methods: {
    navigateToPattern(slug) {
      this.$router.push("/decors/" + slug);
    }
  },
  props: {
    patterns: undefined,
    headline: undefined,
    colors: {
      default() {
        return [];
      }
    }
  }
};
</script>

<template>
  <div></div>
</template>

<script>
export default {
  name: "Meta",
  mixins: [],
  components: {},
  computed: {},
  beforeDestroy() {},
  mounted() {
    const path = this.$route.path
      .split("-")
      .join("_")
      .split("/")[1];
    this.$store.commit("set_meta_layer", path);
  }
};
</script>

import * as PIXI from "pixi.js";
//import BlobGroup from "./../objects/BlobGroup"

export default class ZoomObject {
  constructor(element) {
    this.time = 0;
    this.pixi = new PIXI.Container();

    let spriteFront;

    //console.log("zoom object:",element.id);

    if (element.id === "mandala") {
      this.mandalaBG = new PIXI.Sprite(
        new PIXI.Texture.from(
          "/assets/textures/vector/Mandala_Backgorund-01.svg",
          undefined,
          undefined,
          1.0
        )
      );
      this.mandalaFG = new PIXI.Sprite(
        new PIXI.Texture.from(
          "/assets/textures/vector/Mandala_Foregorund-01.svg",
          undefined,
          undefined,
          1.0
        )
      );

      this.pixi.addChild(this.mandalaBG);
      this.pixi.addChild(this.mandalaFG);

      this.mandalaBG.anchor.set(0.5);
      this.mandalaFG.anchor.set(0.5);

      this.mandalaBG.scale.set(0.75);
      this.mandalaFG.scale.set(0.75);

      this.mandalaBG.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
      this.mandalaFG.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
    } else if (element.id === "mood-mesher") {
      spriteFront = PIXI.Sprite.from(
        "/assets/textures/hotspots/Circle_Mountain_Front@2x.png"
      );

    } else if (element.id === "lifetime-calculator") {
      spriteFront = PIXI.Sprite.from(
        "/assets/textures/hotspots/Circle_Calculator_Front@2x.png"
      );

    } else if (element.id === "kaleidoscope") {
      spriteFront = PIXI.Sprite.from(
        "/assets/textures/hotspots/Circle_Caleidoscope_Front@2x.png"
      );

    }  else if (element.id === "mindgame") {
      spriteFront = PIXI.Sprite.from(
        "/assets/textures/hotspots/Hotspot03@2x.png"
      );

    }   else if (element.id === "bucketlist") {
      spriteFront = PIXI.Sprite.from(
        "/assets/textures/hotspots/Hotspot04@2x.png"
      );

    }  else if (element.id === "globe") {
      spriteFront = PIXI.Sprite.from(
        "/assets/textures/hotspots/Hotspot02@2x.png"
      );

    }  else if (element.id === "impressions-of-a-moment") {
      spriteFront = PIXI.Sprite.from(
        "/assets/textures/hotspots/Hotspot01@2x.png"
      );

    }  else if (element.id === "next") {
      spriteFront = PIXI.Sprite.from(
        "/assets/textures/hotspots/teaser_book02@2x.png"
      );
    } else if (element.id === "next-03") {
      spriteFront = PIXI.Sprite.from(
        "/assets/textures/third-book/hotspots/Bookteaser@2x.png"
      );

      spriteFront.scale.set(1.5,1.5);
    } else if (element.id === "qrcode") {
      spriteFront = PIXI.Sprite.from(
        "/assets/textures/qrcode/Hotspot05@2x.png"
      );
    } else if (element.id === "magazine") {
      spriteFront = PIXI.Sprite.from(
        "/assets/textures/third-book/hotspots/Hotspot02@2x.png"
      );
    }  else if (element.id === "memo") {
      spriteFront = PIXI.Sprite.from(
        "/assets/textures/third-book/hotspots/Hotspot03@2x.png"
      );
    } else if (element.id === "fashion") {
      spriteFront = PIXI.Sprite.from(
        "/assets/textures/third-book/hotspots/Hotspot04@2x.png"
      );
    } else if (element.id === "relax") {
      spriteFront = PIXI.Sprite.from(
        "/assets/textures/third-book/hotspots/Hotspot01@2x.png"
      );
    }
    
    if(spriteFront) {
      spriteFront.anchor.set(0.5);
      this.pixi.addChild(spriteFront);

      this.pixi.buttonMode = true;
      this.pixi.defaultCursor = "pointer";

      

      /*const graphics = new PIXI.Graphics();
      graphics.beginFill(0xffffff);
      graphics.drawCircle(0, 0, 150);
      graphics.endFill();*/

      //if (!element.hidden) this.pixi.addChild(graphics);
    }
  }

  destroy(){
    this.pixi.destroy();
    this.pixi = null;    
  }

  render(alpha, scale) {
    //console.log(this.pixi.interactive);
    this.pixi.alpha = alpha;

    this.time += 0.01;

    if (this.blur) {
      const maxBlur = 5;
      const blur = Math.round(maxBlur - maxBlur * scale);
      this.blur.blurX = blur;
      this.blur.blurY = blur;
    }
    if (this.mandalaFG) {
      this.mandalaFG.rotation += 0.002;
      this.mandalaBG.rotation -= 0.001;

      const scale1 = 0.7 + Math.sin(this.time) * 0.05;
      const scale2 = 0.8 + Math.cos(this.time) * 0.1;
      //this.mandalaBG.
      this.mandalaBG.scale.set(scale1);
      this.mandalaFG.scale.set(scale2);
    }
  }
}

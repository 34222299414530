export default class AssetHelper {
    constructor() {
        //const folders = ["_yellow","_white","_orange"];
        //const folders = ["_white"];
        const folders = ["pballs"];
        this.assets = [];

        folders.forEach(element=>{
            const pBalls = 11;
            
            /*const numParticles = 10;
            const numPowder = 10;
            let numBall = 0;

            if(element === '_white') numBall = 4;

            for(let i = 1;i<=numParticles;i++){
                const path = "assets/textures/"+element+"/Particle"+this.getString(i)+".png";
                this.assets.push(path);
            }

            for(let f = 1;f<=numPowder;f++){
                const path = "assets/textures/"+element+"/Powder"+this.getString(f)+".png";
                this.assets.push(path);
            }

            for(let g = 1;g<=numBall;g++){
                const path = "assets/textures/"+element+"/Particleball"+this.getString(g)+".png";
                this.assets.push(path);
            }*/

            for(let h = 1;h<=pBalls;h++){
                const path = "/assets/textures/"+element+"/Particleball"+this.getString(h)+".png";
                this.assets.push(path);
            }

            
        });
    } 

    getString(i) {
        if(i < 10) return "0"+i;
        if(i >= 10) return String(i);
    }

    getRandomAsset(){
        const randomIndex = Math.floor(Math.random()*this.assets.length);
        return this.assets[randomIndex];
    }
}
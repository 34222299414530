<template>
  <div class="toast-messages__item" :class="{ hiding: isHiding }">
    <div class="toast-messages__item-icon-type">
      <inline-svg v-if="content.type === 'warning'" class="circle-warning"
        :src="require('../../assets/svg/icon_toast_warning.svg')" />
      <inline-svg v-else-if="content.type === 'error'" class="circle-error"
        :src="require('../../assets/svg/icon_toast_error.svg')" />
      <inline-svg v-else class="circle-ok" :src="require('../../assets/svg/icon_toast_ok.svg')" />
    </div>
    <div class="toast-messages__item-icon-label">
      <h1>{{ content.label }}</h1>
      <p v-html="content.body" v-if="content.body"></p>
    </div>
    <div class="toast-messages__item-icon-close" @click="removeToast(content)">
      <inline-svg :src="require('../../assets/svg/icon_close.svg')" />
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "ToastMessage",
  props: {
    content: undefined
  },
  data() {
    return {
      isHiding: false
    };
  },
  methods: {
    removeToast() {
      this.isHiding = true;
      gsap.to(this.$el, {
        autoAlpha: 0,
        duration: 0.1,
        height: 0,
        onComplete: this.emitRemove
      });
    },
    emitRemove() {
      this.$emit("remove");
    }
  },

  mounted() {
    this.$nextTick(() => {
      gsap.set(this.$el, { autoAlpha: 0, x: 20 });
      gsap.to(this.$el, { duration: .5, autoAlpha: 1, x: 0 });
    });
  }
};
</script>

<template>
  <div class="button" :class="{'button--disabled': disabled }" >
    <div class="button__label">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "Button",
  components: { },
  props: {
    disabled:Boolean
  },
  mounted(){}
};
</script>

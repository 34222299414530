<template>
  <div class="cookie-consent" v-if="!hidden">
    <div class="cookie-consent__container" ref="layer">
      <div class="cookie-consent__content">
        <div class="cookie-consent__typo">
          <h1>Cookie confirmation</h1>
          <p>
            In order to design our website optimally for you and to be able to
            improve it continuously, we use cookies. By continuing to use the
            website, you agree to the use of cookies. You can find more
            information about cookies in our <a href='#privacy'>privacy policy</a>.
          </p>
        </div>
        <Button class="button--small" @click.native="acceptCookieConsent"
          >ACCEPT COOKIES</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import Button from "./form/Button";

export default {
  name: "CookieConsent",
  components: { Button },
  data() {
    return {
      hidden: false
    };
  },
  methods: {
    acceptCookieConsent() {
      this.$store.commit("set_cookie_consent_accepted", true);
      gsap
        .to(this.$refs.layer, { duration: 0.2, y: "0", clearProps: "all" })
        .then(() => {
          this.hidden = true;
        });
    }
  },
  watch: {
    $route() {
      const hash = this.$route.hash;
      if (hash === "#terms") {
        this.$store.commit("set_meta_layer", "terms_conditions");
      } else if (hash === "#privacy") {
        this.$store.commit("set_meta_layer", "privacy_policy");
      } else {
        //geh weg;
        //this.$store.commit("set_meta_layer", undefined);
      }
    }
  },
  mounted() {
    
    //this.$store.commit("set_cookie_consent_accepted", false);

    this.$nextTick(() => {
      const accepted = window.localStorage.getItem("uchronia_cc");
      gsap.set(this.$refs.layer, { y: 0, overwrite: true });

      if (accepted != "true") {
        gsap.to(this.$refs.layer, {
          duration: 0.8,
          ease: "Expo.easeOut",
          delay: 1,
          y: "-100%",
          clearProps: "all"
        });
      } else {
        this.hidden = true;
      }
    });
  }
};
</script>

var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"float-cluster",class:( _obj = {}, _obj['float-cluster--style' + _vm.pointStyle] = true, _obj )},[_vm._l((_vm.teaserItems),function(item,index){return _c('FloatClusterItem',{key:index,attrs:{"position":_vm.currentStyle.points[index],"content":item},nativeOn:{"click":function($event){return _vm.onItemClick(item.slug)}}})}),_c('div',{staticClass:"float-cluster__item float-cluster__item--headline",style:({
      left: _vm.currentStyle.headline.x + '%',
      top: _vm.currentStyle.headline.y + '%',
      width: _vm.currentStyle.headline.width + '%',
      height: _vm.currentStyle.headline.height + '%',
    })},[_c('h1',{staticClass:"headline-1"},[_vm._v(_vm._s(_vm.contentJson.headline))]),_c('h2',[_vm._v(_vm._s(_vm.contentJson.subline))])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }
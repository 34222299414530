<template>
  <div class="share-popup" ref="bg" @click="onBgClick">
    <div class="share-popup__container" ref="popup">
      <h2>Share this interaction</h2>
      <div class="share-popup__close" @click="onClose">
        <inline-svg :src="require('@/assets/svg/icon_close.svg')" />
      </div>

      <div class="share-popup__networks">
        <ShareNetwork network="facebook" :url="share.url" :title="share.title" :description="share.description"
          :media="share.media">
          <inline-svg :src="require('@/assets/svg/icon_facebook.svg')" />
        </ShareNetwork>

        <ShareNetwork network="twitter" :url="share.url" :title="share.title" :description="share.description"
          :media="share.media">
          <inline-svg :src="require('@/assets/svg/icon_twitter.svg')" />
        </ShareNetwork>
        <ShareNetwork network="pinterest" :url="share.url" :title="share.title" :description="share.description"
          :media="share.media">
          <inline-svg :src="require('@/assets/svg/icon_pinterest.svg')" />
        </ShareNetwork>

        <ShareNetwork network="linkedin" :url="share.url" :title="share.title" :description="share.description"
          :media="share.media">
          <inline-svg :src="require('@/assets/svg/icon_linkedin.svg')" />
        </ShareNetwork>

        <ShareNetwork network="xing" :url="share.url" :title="share.title" :description="share.description"
          :media="share.media">
          <inline-svg :src="require('@/assets/svg/icon_xing.svg')" />
        </ShareNetwork>

        <ShareNetwork network="email" :url="share.url" :title="share.title" :description="share.description"
          :media="share.media">
          <inline-svg :src="require('@/assets/svg/icon_mail.svg')" />
        </ShareNetwork>
      </div>
      <div class="share-popup__clipboard" @click="copyToClipboard">
        <div class="share-popup__clipboard-url">{{ share.url }}</div>

        <span>COPY TO CLIPBOARD</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import gsap from "gsap";

export default {
  name: "SharePopup",
  watch: {
    shareTarget() {
      if (this.shareTarget) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },

    isVisible() {
      if (this.isVisible) {
        this.$nextTick(this.show);
      } else {
        this.$nextTick(this.hide);
      }
    }
  },
  computed: {
    url() {
      return window.location.origin;
    },
    shareTarget() {
      return this.$store.getters.share_target;
    }
  },
  methods: {
    onBgClick(e) {
      if (e.target == this.$refs.bg) {
        this.onClose();
      }
    },
    show() {
      this.shareID = this.$route.query.interaction;

      console.log(this.url);

      this.share.url =
        this.url + "/share/?id=" +
        this.shareID +
        "&r" +
        Math.floor(Math.random() * 1024);
      this.share.title = this.shareables[this.shareID].title;
      this.share.description = this.shareables[this.shareID].description;
      this.share.media = this.shareables[this.shareID].image;

      gsap.to(this.$refs.bg, { overwrite: true, autoAlpha: 1, duration: 0.1 });
      gsap.to(this.$refs.popup, {
        duration: 0.5,
        overwrite: true,
        autoAlpha: 1,
        y: 0
      });
    },
    hide() {
      gsap.to(this.$refs.bg, { overwrite: true, autoAlpha: 0, delay: 0.2 });
      gsap.to(this.$refs.popup, {
        overwrite: true,
        autoAlpha: 0,
        duration: 0.2
      });
    },
    onClose() {
      this.$store.commit("set_share_target", false);
    },
    copyToClipboard() {
      const toast = {
        label: "Success!",
        body: "Link copied to your Clipboard",
        type: "success",
        life: "5"
      };

      this.$store.getters.toastManager.add(toast);

      navigator.clipboard.writeText(this.share.url);
    }
  },

  data() {
    return {

      isVisible: false,
      shareables: undefined,
      shareID: undefined,
      share: {
        url:
          this.url + "/share/?id=mindgame&cb" +
          Math.random(),
        title: "Title",
        description: "desciption",
        media: "undefined"
      }
    };
  },
  props: {

  },
  mounted() {
    /*const emailTo = 'example@example.com'
const emailCC = 'cc@example.com'
const emailSub = 'subject'
const emailBody = 'body'
window.open(`mailto:${emailTo}?cc=${emailCC}&subject=${emailSub}&body=${emailBody}`, '_self');*/
    //window.open('mailto:'+emailTo+'?cc='+emailCC+'&subject='+emailSub+'&body='+emailBody, '_self');

    this.$nextTick(() => {
      gsap.set(this.$refs.bg, { autoAlpha: 0 });
      gsap.set(this.$refs.popup, {
        autoAlpha: 0
      });
    });
    axios.get("/share/shareables.json").then(res => {
      this.shareables = res.data;
    });
  }
};
</script>

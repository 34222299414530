<template>
  <div class="home-teaser" :class="{ 'home-teaser--loggedin': userLoggedIn }">
    <HomeChapter v-if="chaptersVisible" />
    <div class="home-teaser__container" v-if="!chaptersVisible && showHome">
      <ContentCard>
        <h1>Welcome to our time utopia.</h1>
        <h2>Time to recap UCHRONIA!</h2>

        <p>
          It's been a while since we started our journey through UCHRONIA. Well, and now
          our journey is coming to an end. But every end is also a new beginning. So, just
          experience our journey all over again or check out our UCHRONIA Review Reel for
          inspiration from memories and new ideas.
        </p>

        <Button @click.native="startJourney">START JOURNEY</Button>
        <div class="home-teaser__aside" ref="aside">
          <div
            ref="image_teaser"
            class="home-teaser__image-teaser"
            @click="onVideoTeaserClick"
          >
            <img
              src="assets/img/HP-Teaser_Review-Film_v2.png"
              alt="Watch UCHORNIA Review Reel"
            />
          </div>
        </div>
      </ContentCard>
    </div>
  </div>
</template>

<script>
import ContentCard from "@/components/content/ContentCard";
import Button from "@/components/form/Button";
import gsap from "gsap";
import DownloadHelperMixin from "@/mixins/DownloadHelperMixin";
import HomeChapter from "./HomeChapter.vue";

export default {
  name: "HomeTeaser",
  mixins: [DownloadHelperMixin],
  components: { ContentCard, Button, HomeChapter },
  props: {
    content: undefined,
  },
  data() {
    return {
      userIsLoggedIn: true,
      showTeaser: false,
      showHome: false,
    };
  },
  computed: {
    eventState() {
      /* prelive, live, afterlive, normal */
      return this.$store.getters.eventState;
    },
    eventStreams() {
      return this.$store.getters.eventStreams;
    },
    chaptersVisible() {
      return this.$store.getters.chaptersVisible;
    },
    activeChapter() {
      return this.$store.getters.activeChapter;
    },
    userLoggedIn() {
      return this.$store.getters.getLoggedIn;
    },
  },
  watch: {
    chaptersVisible() {
      if (!this.chaptersVisible && this.showHome) {
        this.$nextTick(this.fadeContents);
      }
    },
    userLoggedIn() {
      //if(this.userLoggedIn) this.chaptersVisible = true;
    },
    activeChapter() {
      if (this.activeChapter !== undefined) {
        setTimeout(() => {
          this.onJourneyStart();
        }, 100);
      }
    },
  },
  methods: {
    fadeContents() {
      this.$store.commit("set_teased_background_color", undefined);

      gsap.set(this.$refs.aside, { x: "10%" });
      gsap.to(this.$refs.aside, {
        delay: 0.15,
        ease: "Expo.easeOut",
        x: 0,
        duration: 0.6,
      });
    },
    startJourney() {
      this.$store.commit("set_chapters_visible", true);
    },
    onDownloadClick() {
      this.downloadCSV();
    },
    onVideoTeaserClick() {
      this.$store.commit("set_lightbox_content", {
        headline: "UCHRONIA Review Reel",
        vimeo: "814923854",
      });
    },
    openLiveVideo() {
      this.$store.commit("set_lightbox_content", {
        headline: this.eventStreams.live.headline,
        vimeo: this.eventStreams.live.id,
      });
      /*
      if (this.userLoggedIn) {
        this.$store.commit("set_lightbox_content", {
          headline: "Live video: „UCHRONIA continues: Perception of Time“",
          vimeo: "623438426"
        });
      } else {
        this.$store.commit("set_lightbox_content", {
          headline: "Teaser Video „UCHRONIA“",
          vimeo: "539708163"
        });
      }*/
    },
    onVideoClick() {
      this.$store.commit("set_lightbox_content", {
        headline: "Re-live video: „UCHRONIA continues: Perception of Time“",
        vimeo: "539708163",
      });
    },

    onJourneyStart() {
      this.$store.commit("set_journey_active", true);
      this.$store.commit("set_journey_started", true);

      this.$gtag.event("Journey started", {
        event_category: "Journey",
      });
    },
  },
  beforeDestroy() {},
  async mounted() {
    let hash = this.$router.currentRoute.hash;
    if (hash.indexOf("?") !== -1) {
      hash = hash.split("?")[0];
    }

    const chapterNames = this.$store.getters.chapters;

    if (hash === "#intro") {
      this.$store.commit("set_chapters_visible", true);
      this.$store.commit("set_intro_skipped", false);
      return;
    } else if (hash === "#chapters") {
      this.$store.commit("set_chapters_visible", true);
      this.$store.commit("set_intro_skipped", true);
      return;
    } else if (chapterNames.indexOf(hash) !== -1) {
      const index = chapterNames.indexOf(hash);
      this.$store.commit("set_active_chapter", index);

      this.$store.commit("set_chapters_visible", false);
      this.$store.commit("set_intro_skipped", true);
      return;
    }

    this.showHome = true;

    await this.$nextTick;

    if (!this.$store.getters.journeyStarted && !this.chaptersVisible) {
      this.fadeContents();

      if (this.$refs.video_teaser) {
        gsap.set(this.$refs.video_teaser, { x: "10%" });
        gsap.to(this.$refs.video_teaser, {
          delay: 0.15,
          ease: "Expo.easeOut",
          x: 0,
          duration: 0.6,
        });
      }

      gsap.set(this.$refs.image_teaser, { x: "10%" });
      gsap.to(this.$refs.image_teaser, {
        delay: 0.25,
        ease: "Expo.easeOut",
        x: 0,
        duration: 0.6,
      });

      if (this.$refs.video_teaser) {
        gsap.set(this.$refs.video_teaser, { x: "10%" });
        gsap.to(this.$refs.video_teaser, {
          delay: 0.15,
          ease: "Expo.easeOut",
          x: 0,
          duration: 0.6,
        });
      }

      if (this.$router.currentRoute.hash) this.$store.commit("set_hash", "");
    } else {
      if (!this.chaptersVisible) this.onJourneyStart();
    }
  },
};
</script>

<template>
  <div class="user-favorites">
    <div class="user__form" v-if="content">
      <h1>{{ favorites.headline }}</h1>

      <div class="user-favorites__scroll-wrapper">
        <div class="user-favorites__hint" v-if="!favorites.length">
          No favorites. Check out your <router-link :to="'/decors'">
          our decors. </router-link>
        </div>
        <div
          class="user-favorites__item"
          v-for="(item, index) in favorites"
          :key="index"
        >
          <template v-if="getFavoriteContent(item)">
            
            <img :src="getFavoriteContent(item).img" alt="" />
            <div class="user-favorites__item-infos">
              <h1>Decorname</h1>
              <h2 @click="onItemClick(item)">
                {{ getFavoriteContent(item).name }}
              </h2>
              <ul>
                <li>Decornumber {{ item.split("_")[0] }}</li>
                <li v-if="hasSubColor(item)">Color {{ hasSubColor(item) }}</li>
              </ul>
            </div>
            <div class="user-favorites__item-delete" @click="deleteItem(item)">
              <inline-svg :src="require('../../assets/svg/icon_delete.svg')" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "@/mixins/DataMixin.js";

export default {
  name: "UserFavorites",
  mixins: [DataMixin],
  data() {
    return {
      //items: []
    };
  },
  computed: {
    response() {
      return JSON.parse(JSON.stringify(this.$store.state.user.restResponse));
    },

    favorites() {
      return this.$store.getters.favorites
    }
  },
  watch: {
    response(newResponse) {
      if (newResponse != null) {
        if (newResponse.code == "get_favorites" || newResponse.code == "favorites_updated") {
          //this.items = newResponse.favorite_decores;
        } else {
          //this.items = [];
        }
      }
    }
  },
  methods: {
    getUserFavorites() {
      const payload = {
        login: this.$store.state.user.loggedInUser.login
      };

      const data = { key: "get_fav", params: payload };
      this.$store.dispatch("request", data);
    },
    onItemClick(item) {
      const decorSlug = "/decors/" + this.getFavoriteContent(item).slug;
      this.$router.push(decorSlug);
    },
    deleteItem(item) {
      const payload = {
        action: "remove",
        decore: item
      };

      this.$store.dispatch("update_favorites", payload);
    },
    hasSubColor(id) {
      return id.split("_")[1];
    },
    getFavoriteContent(id) {
      
      const subID = id.split("_")[1];
      
      id = id.split("_")[0];
      
      console.log(this.$store.getters
        .getCachedData("decors"),"***")

      const obj = this.$store.getters
        .getCachedData("decors")
        .decors.filter(content => content.id === id)[0];
        
        if(subID) {
          console.log("JUIII",obj);
          
        }else{
          console.log("NO");
        }


        return obj;
    }
  },
  mounted() {
    this.fetchData("decors");
  }
};
</script>

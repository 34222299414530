<template>
  <div class="view videos">
    <div class="videos__wrapper">

      <div class="videos__container" v-if="videoCategories">
        <h1>Videos</h1>

        <template v-for="(category, index) in videoCategories">
          <VideoSlider :index="index + 1" :content="category" :key="index" />
        </template>

      </div>
    </div>

    <div class="view__background"></div>
  </div>
</template>
<script>
import AppearMixin from "@/mixins/AppearMixin.js";
import DataMixin from "@/mixins/DataMixin.js";
import VideoSlider from '../components/videos/VideoSlider.vue';


export default {
  name: "Videos",
  mixins: [DataMixin, AppearMixin],
  components: { VideoSlider },
  data() {
    return {
      videoCategories: undefined
    };
  },
  props: {

  },
  methods: {
    createCategories() {
      const categories = {};
      const allVideos = this.contentJson.videos;

      allVideos.forEach(video => {
        if (!categories[video.category.slug]) {
          categories[video.category.slug] = {
            headline: video.category.name,
            videos: []
          };
        }

        categories[video.category.slug].videos.push(video);
      })

      this.videoCategories = categories;
    },
  },
  watch: {
    contentJson() {
      this.createCategories();
    }
  },
  computed: {},
  mounted() {
    this.fetchData("videos", true);
    if (this.contentJson) {
      this.createCategories();
    }
  }
};
</script>

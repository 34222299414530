<template>
  <div class="footer">
    <div class="footer__container">
      <ul>
        <!--<li><strong>impress</strong></li>
        <li>2021. All rights reserved.</li>-->
        <img src="/assets/img/impress_claim.png" alt="impress_claim.png">
      </ul>

      <ul>
        <li class="link" @click="onMetaClick('imprint')">Imprint</li>
        <li class="link" @click="onMetaClick('privacy_policy')">Privacy</li>
        <li class="link" @click="onMetaClick('terms_conditions')">
          Terms & Conditions
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    onMetaClick(id) {
      //console.log(id);
      if (this.$route.path !== "/" + id) {
        this.$store.commit("set_meta_layer", id);
        //this.$router.push("/" + id);
      }
    }
  }
};
</script>

<template>
  <div class="float-img">
    <img ref="img" :src="src" @load="onImageLoad" v-view="viewHandler" />
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "FloatImage",
  props: {
    src: undefined,
    offset: {
      default: 0
    }
  },
  data() {
    return {
      yOffset: 0,
      rect: undefined
    };
  },
  methods: {
    onImageLoad() {},
    viewHandler(e) {
      this.rect = { rect: e.target.rect };

      if (e.type === "enter") {
        //
      } else if (e.type === "exit") {
        //
      } else if (e.type === "progress") {
        const firstTop = e.target.rect.top + window.scrollY;
        const winScrollTop = window.scrollY;
        let shiftDistance =
          e.target.rect.height * 0.5 - (firstTop - winScrollTop) * 0.1;

        shiftDistance *= this.offset / 20;
        gsap.to(this.$refs.img, { duration: 1, y: shiftDistance });
      }
    }
  }
};
</script>

<style lang="scss">
.float-img {
  position: relative;
  align-self: flex-start;
  line-height: 0;

  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }

  &.no-shadow {
      img {
        box-shadow: none !important;
      }
    }
}
</style>


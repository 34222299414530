<template>
  <div class="view rooms">
    <template v-if="room">
      <RoomComponentPano
        v-if="room && chapter === 0"
        :room="room"
        :roomName="roomName"
      />
      <RoomComponent
        v-else-if="room && chapter >= 1"
        :room="room"
        :roomName="roomName"
      />
    </template>

    <FloatCluster
      @click="openRoom"
      :contentJson="filteredContentJson"
      v-if="!room"
      pointStyle="1"
    />
    <div class="view__background" v-if="room"></div>
  </div>
</template>
<script>
import AppearMixin from "@/mixins/AppearMixin.js";
import DataMixin from "@/mixins/DataMixin.js";
import RoomComponent from "@/components/rooms/RoomComponent";
import RoomComponentPano from "@/components/rooms/RoomComponentPano";
import FloatCluster from "@/components/floatcluster/FloatCluster.vue";

export default {
  name: "Rooms",
  mixins: [DataMixin, AppearMixin],
  components: { RoomComponentPano, RoomComponent, FloatCluster },
  data() {
    return {
      chapter: undefined,
      items: [],
      roomName: undefined,
      roomContent: undefined,
      filteredContentJson: undefined
    };
  },
  props: {
    room: undefined //validator
  },
  methods: {
    openRoom(slug) {
      this.$router.push({ path: "/rooms/" + slug });
    },
    checkSubPage() {
      if (this.room) {
        const isValid = true; //this.validateSubpage(this.room);

        //console.log("validating room: "+ this.room + " = "+isValid)

        if (!isValid) {
          this.$router.push({ path: "/rooms" });
          return;
        }

        const roomContent = this.$store.getters
          .getCachedData("rooms")
          .rooms.filter(room => room.slug === this.room)[0];

        if (roomContent) {
          this.roomName = roomContent.name;
          this.roomContent = roomContent;
        } else {
          this.roomName = undefined;
          this.roomContent = undefined;
        }
      } else {
        this.roomName = undefined;
        this.roomContent = undefined;
      }
    }
  },
  watch: {
    chapter() {
      console.log("CHAPTER: " + this.chapter);
    },
    contentJson() {},
    room() {
      const roomContent = this.$store.getters
        .getCachedData("rooms")
        .rooms.filter(room => room.slug === this.room)[0];

      if (roomContent) {
        this.roomName = roomContent.name;
      } else {
        this.roomName = undefined;
      }
    },
    content() {
      this.checkSubPage();

      //filter!
      const items = [];
      let chapter = this.$store.getters.activeChapter;

      if(location.hash) {
        const active = this.$store.getters.chapters.find(el => el === location.hash);
        if(active) {
          const c = this.$store.getters.chapters.indexOf(active);
          chapter = c;
        }
      }

      if(chapter === undefined) chapter = 0;        

      if (this.room) {
        if (this.room.includes("2.0") || this.room.includes("_II")) {
          chapter = 1;
        } else if (this.room.includes("3.0") || this.room.includes("_III")) {
          chapter = 2;
        } else if (
          this.room.includes("ecotropolis") ||
          this.room.includes("reimagined_rural") ||
          this.room.includes("modern_romantics") ||
          this.room.includes("hybrid_realities") ||
          this.room.includes("reframing_rituals")
        ) {
          chapter = 2;
        } else {
          chapter = 0;
        }
      }else{
        this.setColors(chapter);
        //location.hash = this.$store.getters.chapters[chapter];
      }

      this.chapter = chapter;

      this.$store.getters.filteredChapterContents[chapter].rooms.forEach(
        room => {
          const filtered = this.content.filter(item => item.name == room.title);
          if (filtered[0]) {
            items.push(filtered[0]);
          } else {
            console.log("NOT FOUND: ", room.title);
            //items.push("ecotropolis");
          }
        }
      );

      this.items = items;

      const raw = this.contentJson;
      raw.rooms = items;

      this.filteredContentJson = raw;

      if (this.room) {
        this.checkSubPage();
      }
    }
  },
  computed: {},
  mounted() {
    this.fetchData("rooms", false);
  }
};
</script>

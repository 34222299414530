import gsap from "gsap";
import * as PIXI from "pixi.js";
//import TwistFilter from "@pixi/filter-twist"
import { ShockwaveFilter } from "@pixi/filter-shockwave";
import { ZoomBlurFilter } from "@pixi/filter-zoom-blur";
export default class AnimalInteraction {
  constructor(data) {
    this.pixi = new PIXI.Container();
    this.sprites = [];
    this.data = data;
    this.currentLifeTime = 100;
    this.hidden = true;
    this.scale = 1;
    this.baseWidth = 700*.6;
    this.currentWidth = -1;
    this.scale = 1;

    data.forEach((element) => {
      const sprite = PIXI.Sprite.from("/assets/textures/" + element.url);
      sprite.anchor.set(0.5);
      sprite.scale.set(1);
      sprite.alpha = 0;

      this.sprites.push(sprite);
    });

    const style1 = new PIXI.TextStyle({
      fontFamily: "Nunito Sans",
      fill: "#ffffff",
      fontSize: 64,
      fontWeight: "bold",
      dropShadow: true,
      dropShadowBlur: 10,
      dropShadowDistance: 0,
      dropShadowAlpha: 0.5,
      padding: 25,
    });
    const style2 = new PIXI.TextStyle({
      fontFamily: "Nunito Sans",
      fill: "#ffffff",
      fontSize: 48,
      dropShadow: true,
      dropShadowBlur: 10,
      dropShadowDistance: 0,
      dropShadowAlpha: 0.5,
      padding: 5,
    });

    const text1 = new PIXI.Text("XXXXXXXXX");
    const text2 = new PIXI.Text("XXXXXXXXX");

    //this.setTextStyles(1);
    text1.style = style1;
    text2.style = style2;

    text2.anchor.set(1, 0);
    text1.anchor.set(1, 0);

    this.animalName = text1;
    this.animalLifetime = text2;

    this.shock = new ShockwaveFilter({ x: 350, y: 350 }, { amplitude: 5 });
    this.zoomBlur = new ZoomBlurFilter({ center: [350, 350], strength: 1 });
    //this.pixi.filters = [this.shock];
    this.pixi.filters = [this.zoomBlur];
  }

  setTextStyles(scale) {
    const style1 = new PIXI.TextStyle({
      fontFamily: "Nunito Sans",
      fill: "#ffffff",
      fontSize: 96 * scale,
      fontWeight: "bold",
      dropShadow: true,
      dropShadowBlur: 10 * scale,
      dropShadowDistance: 0,
      dropShadowAlpha: 0.5,
      padding: 25 * scale,
    });
    const style2 = new PIXI.TextStyle({
      fontFamily: "Nunito Sans",
      fill: "#ffffff",
      fontSize: 64 * scale,
      dropShadow: true,
      dropShadowBlur: 10 * scale,
      dropShadowDistance: 0,
      dropShadowAlpha: 0.5,
      padding: 5 * scale,
    });

    this.animalName.style = style1;
    this.animalLifetime.style = style2;
  }

  onHidden() {
    this.sprites.forEach((sprite) => {
      this.pixi.removeChild(sprite);
    });

    this.pixi.removeChild(this.animalName);
    this.pixi.removeChild(this.animalLifetime);

    this.currentLifeTime = 100;
    this.activeIndex = undefined;
  }

  hide() {
    this.hidden = true;
    gsap.to(this.pixi, {
      duration: 0.5,
      alpha: 0,
      onComplete: () => {
        this.onHidden();
      },
    });
  }

  show() {
    this.hidden = false;

    this.sprites.forEach((sprite) => {
      this.pixi.addChild(sprite);
    });

    this.pixi.addChild(this.animalName);
    this.pixi.addChild(this.animalLifetime);

    gsap.to(this.pixi, { duration: 0.5, alpha: 1 });

    this.shock.time = 0;
    gsap.to(this.shock, { duration: 0, time: 0, overwrite: true });
    gsap.to(this.shock, { ease: "Sine.easeOut", duration: 2, time: 1 });

    gsap.to(this.zoomBlur, {
      ease: "Sine.easeOut",
      duration: 0,
      strength: 0.5,
      overwrite: true,
    });
    gsap.to(this.zoomBlur, { ease: "Expo.easeOut", duration: 2, strength: 0 });
  }

  changeIndex(active) {
    if (this.activeIndex !== undefined)
      this.oldSprite = this.sprites[this.activeIndex];
    this.activeIndex = active;

    this.animalName.text = this.data[active].name;
    gsap.to(this.animalName, {
      duration: 0.0,
      alpha: 0,
    });

    gsap.to(this.animalName, {
      duration: 0.5,
      alpha: 1,
    });

    this.currentUnit = this.data[active].lifetime.split(" ")[1];
    gsap.to(this, {
      duration: 1,
      currentLifeTime: this.data[active].lifetime.split(" ")[0],
      onUpdate: () => {
        this.setLifespanText();
      },
    });

    this.pixi.addChild(this.sprites[active]);
    this.pixi.addChild(this.animalName);
    this.pixi.addChild(this.animalLifetime);

    gsap.to(this.sprites, { overwrite: true, duration: 0.25, alpha: 0 });
    gsap.from(this.sprites[active].scale, {
      ease: "Expo.easeOut",
      duration: 2,
      x: 0.5,
      y: 0.5,
    });
    gsap.to(this.sprites[active], { duration: 0.5, alpha: 1 });

    //gsap.to(this.zoomBlur, { ease:"Sine.easeOut",duration: 0, strength: .5 ,overwrite:true});
    //gsap.to(this.zoomBlur, { ease:"Expo.easeOut",duration: 2, strength: 0 });
  }

  setLifespanText() {
    this.animalLifetime.text =
      Math.floor(this.currentLifeTime) + " " + this.currentUnit;
  }

  progress(detail) {
    if (this.hidden) {
      this.show();
    }

    const percent = detail.progress;
    const maxSlides = this.data.length - 1;
    const active = Math.round(percent * maxSlides);

    if (active !== this.activeIndex) {
      this.changeIndex(active);
    }

    this.render();
  }

  setMouse(pos) {
    this.t1Target = {
      x: 250 * this.scale + 100 * this.scale * pos.xPercent,
      y: -150 * this.scale + 100 * this.scale * pos.yPercent,
    };
    this.t2Target = {
      x: 250 * this.scale + 50 * this.scale * pos.xPercent,
      y: this.t1Target.y + 120 * this.scale,
    };

    this.currentMousePos = pos;
  }

  render() {
    this.animalName.x -= (this.animalName.x - this.t1Target.x) * 0.1;
    this.animalName.y -= (this.animalName.y - this.t1Target.y) * 0.1;

    this.animalLifetime.x -= (this.animalLifetime.x - this.t2Target.x) * 0.1;
    this.animalLifetime.y -= (this.animalLifetime.y - this.t2Target.y) * 0.1;

    if (this.currentWidth !== window.innerWidth) {
      //RESIZE!
      this.currentWidth = window.innerWidth;

      let scale = (window.innerWidth - 100) / this.baseWidth;
      if (scale > 1) scale = 1;

      this.scale = scale;

      if (window.innerWidth <= this.baseWidth) {
        //console.log("SCALING");
      } else {
        //BIG
      }

      this.sprites.forEach((sprite) => {
        sprite.scale.set(scale*.8);
      });

      this.setTextStyles(scale);
      if (this.currentMousePos) this.setMouse(this.currentMousePos);
    }
  }
}


import axios from "axios";

export default {
  data() {
    return {
      contentJson: undefined,
      content: undefined,
      id: undefined,
      onGetJsonError: undefined,
      usingFallbackUrl: Boolean,
      fallbackUrl: '/json/',
      baseUrl: '',
      data: undefined
    };
  },
  created: function () { },
  methods: {
    setColors(id){
      let colors = {
        backgroundColor: "#ffd543",
        colors: ["#ec771a", "#ffd646", "#ec771a", "#d5e2ce"]
      };

      if (id === 1) {
        colors = {
          backgroundColor: "#f5a649",
          colors: ["#f1913b", "#f1913b", "#a1c5dd", "#dbaac7"]
        };
      }

      if (id === 2) {
        colors = {
          backgroundColor: "#f0858c",
          colors: ["#ef6672", "#2a9aa3", "#ccc0c9", "#7f8392"]
        };
      }
      
      this.$store.commit("set_teased_background_color", colors);
    },
    onJsonLoaded(data) {
      this.content = data[this.id];
      this.contentJson = data;
      this.$store.commit("set_data_json", {
        slug: this.contentJson.slug,
        data: data,
      });

      //console.log(data);
    },
    validateSubpage(slug) {
      const found = this.content.find((element) => element.slug === slug);
      return found;
    },
    fetchData(data, newApi) {
      let baseUrl = this.$store.state.user.jsonBaseUrl;
      if (newApi) {
        if (window.location.host.includes("localhost")) {
          //baseUrl = "http://uchronia-cms-1:8888/wp-json/videoapi/v1/";
          baseUrl = "//staging-cms.digitalimpress.biz/wp-json/videoapi/v1/";
        } else if (window.location.host.includes("staging.digitalimpress.biz")) {
          baseUrl = "//staging-cms.digitalimpress.biz/wp-json/videoapi/v1/";
        } else {
          baseUrl = "//cms.digitalimpress.biz/wp-json/videoapi/v1/";
        }

        baseUrl = "https://cms.digitalimpress.biz/wp-json/videoapi/v1/";


      }

      //try to get files from cms url
      this.data = data;
      this.usingFallbackUrl = false;
      this.getJson(this.data, baseUrl, '');
    },
    getJson(data, baseUrl, fileSuffix) {
      if (baseUrl === 'https://staging-cms.digitalimpress.biz/wp-content/themes/uchronia/assets/jsons/getData.php?file=') {
        baseUrl = "https://cms.digitalimpress.biz/wp-content/themes/uchronia/assets/jsons/getData.php?file=";
      }


      //document.title = data;
      // console.log("loading:" +data);
      if (data.split("/")[1] && !this.$store.getters.getCachedData(data.split("/")[0])) {
        //load before
        //console.log("loading first:" +data);
        axios
          .get(baseUrl + data.split("/")[0] + fileSuffix)
          .then((res) => {
            this.$store.commit("set_data_json", {
              slug: res.data.slug,
              data: res.data.data,
            });

            axios
              .get(baseUrl + data + fileSuffix)
              .then((res) => this.onJsonLoaded(res.data))
              .catch((err) => { console.log(err); this.onGetJsonError = err.response; });

          })
          .catch((err) => { console.log(err.response); this.onGetJsonError = err.response; });
      } else {
        //if (this.contentJson) return this.contentJson; //ABGLEICH MIT STORE?

        if (this.$store.getters.getCachedData(data)) {
          this.contentJson = this.$store.getters.getCachedData(data);
          this.content = this.contentJson[this.id];
        }

        this.id = data;
        //PROMISE
        axios
          .get(baseUrl + data + fileSuffix)
          .then((res) => this.onJsonLoaded(res.data))
          .catch((err) => { console.log(err); this.onGetJsonError = err.response; });
      }
    },
  },
  watch: {
    onGetJsonError(response) {
      if (response.status == 404 && !this.usingFallbackUrl) {
        //console.log('response.status: ', response.status);
        //console.log('response.statusText: ', response.statusText);

        //console.log('loading from fallback');

        //get files from fallback url
        this.usingFallbackUrl = true;
        this.getJson(this.data, this.fallbackUrl, '.json');
      }
    }
  },
};
